import { NGXLogger } from 'ngx-logger';
import { DataviewerControllerService } from './../shared/services/dataviewer-controller.service';
import { Injectable, ElementRef, Renderer2 } from '@angular/core';
import * as THREE from 'three';
import * as _ from 'lodash';
import { WebglService } from './webgl.service';

@Injectable({
  providedIn: 'root'
})
export class MapService {
  public camera: THREE.PerspectiveCamera;
  public raycaster: THREE.Raycaster;
  public mouse: THREE.Vector2;
  public canvasWidth = 0;
  public canvasHeight = 0;

  public init(mapcanvasElementRef: ElementRef, minimapcanvasElementRef: ElementRef, ngRenderer: Renderer2) {
    // tslint:disable-next-line: max-line-length
    this.camera = new THREE.PerspectiveCamera( 45, mapcanvasElementRef.nativeElement.clientWidth / mapcanvasElementRef.nativeElement.clientHeight, 0.1, 20000 );
    this.logger.debug(this.camera);
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    // this.camera.position.set(0 , 0, 1500);
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
    this.setZoom(100, mapcanvasElementRef);
  }

  public setZoom(zoom, mapcanvasElementRef: ElementRef) {
    try {
      const zoomfactor = zoom / 100;
      const vFOV = this.camera.fov  * Math.PI / 180;        // convert vertical fov to radians
      const height = 2 * Math.tan( vFOV / 2 ) * this.camera.position.z; // visible height

      const aspect = mapcanvasElementRef.nativeElement.clientWidth / mapcanvasElementRef.nativeElement.clientHeight;
      const width = height * aspect;
      // define cameraposition for 100 percent
      const zoomwidth = Math.ceil(mapcanvasElementRef.nativeElement.clientWidth * (1 / zoomfactor));
      const zoomheight = Math.ceil(mapcanvasElementRef.nativeElement.clientHeight * (1 / zoomfactor));
      this.camera.position.z = zoomheight / (2 * Math.tan( vFOV / 2 ));
    } catch (error) {
      this.logger.debug(error);
    }
  }

  constructor(private logger: NGXLogger) { }
}
