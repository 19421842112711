import { AccounttypeFactory } from './../interfaces/accounttype-factory';
import { AccounttypeRaw } from './../interfaces/accounttype-raw';
import { Accounttype } from './../interfaces/accounttype';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { retry, map, catchError } from 'rxjs/operators';
import { ErrorhandlerService } from './errorhandler.service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AccounttypeStoreService {
  public accounttypes: Accounttype[];
  public lastrefresh = 0;

  constructor(private http: HttpClient, private logger: NGXLogger) { }

  getAll(): Observable<Accounttype[]> {
    this.lastrefresh = Date.now();
    this.logger.debug('refreshing accounttypes all');
    return this.http.get<AccounttypeRaw[]>(
      `${environment.apiurl}/account-types`
      ).pipe(
        retry(3),
        map(accounttypesRaw => accounttypesRaw.map(at => AccounttypeFactory.fromRaw(at))
        ),
        map(accounttypes => this.accounttypes = accounttypes),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  getOwn(accounttypeId: string): Observable<Accounttype[]> {
    this.logger.debug('refreshing accounttype own');
    return this.http.get<AccounttypeRaw>(
      `${environment.apiurl}/account-types/${accounttypeId}`
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }
}
