import { ActivitylogStoreService } from './../services/activitylog-store.service';
import { AccountService } from './../services/account.service';
import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  cookieValue: string;

  // tslint:disable-next-line: max-line-length
  constructor(
    private logger: NGXLogger,
    private authService: AuthService,
    private router: Router,
    private cookieService: CookieService,
    private accountService: AccountService,
    private activitylogStoreService: ActivitylogStoreService,
    private as: AccountService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.logger.debug('AuthGuard#canActivate called: ' + route.data.role);
    // tslint:disable-next-line:prefer-const
    let url: string = state.url;
    this.logger.debug(state.url);

    return this.checkLogin(url, route);
  }
  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(route, state);
  }

  checkLogin(url: string, route: ActivatedRouteSnapshot): boolean {
    this.logger.debug(url, this.authService.isLoggedIn);
    if (this.authService.isLoggedIn) {
      const expireDate = new Date();
      const expiresSeconds = 3600 * 24 * 14;
      expireDate.setDate(expireDate.getTime() + (1000 * expiresSeconds));
      this.cookieService.set(environment.sessioncookiename, this.accountService.account.authtoken, expireDate, null, null, null, null);
      // this.logger.debug(this.accountService.account.authtoken);
      // this.cookieService.set(environment.sessioncookiename, this.accountService.account.authtoken);
      this.cookieValue = this.cookieService.get(environment.sessioncookiename);
      // this.logger.debug('cookieValue: ', this.cookieValue);
      this.activitylogStoreService.logActivity(this.as.account, url, 'page visited')
      .subscribe(
        (logresult: any) => {
          this.logger.debug(logresult);
        },
        (logerror) => {
          this.logger.debug(logerror);
        }
      );
      if ((route.data.role === 'admin') && (this.as.account.accounttype.name !== 'Administrator')) {
        this.router.navigate(['/']);
      }
      return true;
    } else {
      this.cookieValue = this.cookieService.get(environment.sessioncookiename);
      this.logger.debug('cookieValue: ', this.cookieValue);
      if (this.cookieValue.length > 10) {
        this.as.getAccount(this.cookieValue)
        .subscribe(
          (res: any) => {
            this.logger.debug('continueSession', res);
            this.authService.isLoggedIn = true;
            // Navigate to the dahsboard page
            this.as.account.authtoken = this.cookieValue;
            // this.as.account = AccountFactory.fromRaw(res.user);
            this.authService.initAccountdata();
            this.activitylogStoreService.logActivity(this.as.account, url, 'page visited')
            .subscribe(
              (logresult: any) => {
                this.logger.debug(logresult);
              },
              (logerror) => {
                this.logger.debug(logerror);
              }
            );
            if ((route.data.role === 'admin') && (this.as.account.accounttype.name !== 'Administrator')) {
              this.router.navigate(['/']);
            }
            // this.router.navigate(['/']);
          },
          (err) => {
            this.logger.error(err);
            this.authService.isLoggedIn = false;
            this.as.account = undefined;
            this.cookieService.delete(environment.sessioncookiename);
            // Navigate to the login page
            this.router.navigate(['/login']);
          }
        );
        return true;
      } else {
        // Store the attempted URL for redirecting
        this.authService.redirectUrl = url;
        this.cookieService.delete(environment.sessioncookiename);
        // Navigate to the login page with extras
        this.router.navigate(['/login']);
        return true;
      }
    }
  }
}
