import { ValidationService } from 'src/app/shared/services/validation.service';
import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wol-modal-layersettings',
  templateUrl: './modal-layersettings.component.html',
  styleUrls: ['./modal-layersettings.component.scss']
})
export class ModalLayersettingsComponent implements OnInit {

  @Input() title: string;
  @Input() type: string;
  @Input() layername: string;
  @Input() layercolor: string;
  public settingsform: FormGroup;
  public toggleColorpicker = false;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, public logger: NGXLogger) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    if (this.settingsform) { return; }

    this.settingsform = this.fb.group({
      layername: [this.layername, ValidationService.layernameValidator]
    }, {updateOn: 'change'});
  }
}
