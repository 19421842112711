<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (tap)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <form [formGroup]="settingsform" style="max-width:500px;">
      <div class="form-group">
        <label for="formGroupLayernameInput">Name</label>
        <input formControlName="layername" type="text" class="form-control" id="formGroupLayernameInput">
        <wol-form-messages [control]="settingsform.get('layername')" controlName="layername"></wol-form-messages>
      </div>
      <div class="form-group">
        <span #ignoredInput style="
          padding-right: 18px;
          border: 1px solid #ccc;
          padding-top: 3px;
          padding-bottom: 4px;
          margin-right: 5px;
          margin-left: 2px;
          display: inline;"
          [style.background]="layercolor"
          [(colorPicker)]="layercolor"
          [cpPosition]="'top-left'"
          [cpIgnoredElements]="[ignoredButton, ignoredInput]"
          [cpOutputFormat]="'hex'"
          [cpAlphaChannel]="'disabled'"
          [(cpToggle)]="toggleColorpicker"
          (colorPickerChange)="logger.debug('muh')">
        </span>
        <button #ignoredButton type="button" (tap)="toggleColorpicker=!toggleColorpicker" class="btn-default btn-xs">color</button>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (tap)="activeModal.dismiss('cancel click')">cancel</button>
  <button type="submit" class="btn btn-primary" [disabled]="!settingsform.valid" ngbAutofocus (tap)="activeModal.close({layername: settingsform.controls.layername.value, layercolor: layercolor})">save</button>
</div>
