import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Component({
  selector: 'wol-form-messages',
  templateUrl: './form-messages.component.html',
  styleUrls: ['./form-messages.component.scss']
})
export class FormMessagesComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() controlName: string;

  private allMessages = {
    title: {
      required: 'Title is required.'
    },
    headline: {
      required: 'A headline is required.'
    },
    datestring: {
      required: 'A datestring is required.'
    },
    teaser: {
      required: 'A teaser is required.'
    },
    text: {
      required: 'A text is required.'
    },
    type: {
      required: 'Please select type.'
    },
    category: {
      required: 'Please select category.'
    },
    name: {
      required: 'Name is required'
    },
    firstname: {
      required: 'First name is required'
    },
    lastname: {
      required: 'Last name is required'
    },
    email: {
      invalidEmailAddress: 'Email is empty or incorrect'
    },
    phone: {
      required: 'Phone is required'
    },
    subject: {
      required: 'Subject is required'
    },
    message: {
      required: 'Message is required'
    },
    username: {
      invalidUsername: 'Username is empty or incorrect. Most special characters are not allowed.'
    },
    layername: {
      invalidLayername: 'Layername is empty or incorrect. Most special characters are not allowed.'
    },
    analysisname: {
      invalidAnalysisname: 'Name is empty or incorrect. Most special characters are not allowed.'
    },
    algorithmname: {
      invalidAlgorithmname: 'Name is empty or incorrect. Most special characters are not allowed.'
    },
    version: {
      invalidVersion: 'Version is empty or incorrect. Most special characters are not allowed.'
    },
    learningalgorithmtype: {
      invalidLearningalgorithmtype: 'Please select type'
    },
    filename: {
      invalidFilename: 'Name is empty or incorrect. Most special characters are not allowed.'
    },
    foldername: {
      invalidFoldername: 'Name is empty or incorrect. Most special characters are not allowed.'
    },
    datasetname: {
      invalidDatasetname: 'Name is empty or incorrect. Most special characters are not allowed.'
    },
    keywords: {
      invalidKeywords: 'One or more keywords are incorrect. Most special characters are not allowed.'
    },
    stringparameter: {
      invalidStringparameter: 'Parameter is empty or incorrect. No special characters allowed.'
    },
    charparameter: {
      invalidCharparameter: 'Exactly one character necessary. No special characters allowed.'
    },
    company: {
      required: 'Company / Institution is required'
    },
    address: {
      required: 'Address is required'
    },
    zipcode: {
      required: 'Zip code is required'
    },
    state: {
      required: 'State is required'
    },
    Country: {
      required: 'Country is required'
    },
    termsandconditions: {
      required: 'You need to accept our terms and conditions'
    },
    analysistask: {
      required: 'Description of your analysis task is required'
    },
    password: {
      invalidPassword: 'Password length must be between 6-20 characters and must include at least one number.'
    },
    passwordconfirm: {
      invalidPasswordMatch: 'Passwords do not match!'
    },
    parameterName: {
      invalidParameterName: 'Parametername is empty or incorrect. No special characters allowed.'
    },
    parameterDescription: {
      invalidParameterDescription: 'Parameterdescription is empty or incorrect. No special characters allowed.'
    },
    integerparameterDefaultvalue: {
      invalidIntegerparameterDefaultvalue: 'DefaultValue is empty or incorrect. Only integer allowed.'
    },
    integerparameterMaximumvalue: {
      invalidIntegerparameterMaximumvalue: 'MaximumValue is empty or incorrect. Only integer allowed.'
    },
    integerparameterMinimumvalue: {
      invalidIntegerparameterMinimumvalue: 'MinimumValue is empty or incorrect. Only integer allowed.'
    },
    integerparameterPrecision: {
      invalidIntegerparameterPrecision: 'Precision is empty or incorrect. Only integer allowed.'
    },
    floatparameterDecimalplaces: {
      invalidFloatparameterDecimalplaces: 'MaximumValue is empty or incorrect. Only integer allowed.'
    },
    floatparameterDefaultvalue: {
      invalidFloatparameterDefaultvalue: 'DefaultValue is empty or incorrect. Only float allowed.'
    },
    floatparameterMaximumvalue: {
      invalidFloatparameterMaximumvalue: 'MaximumValue is empty or incorrect. Only float allowed.'
    },
    floatparameterMinimumvalue: {
      invalidFloatparameterMinimumvalue: 'MinimumValue is empty or incorrect. Only float allowed.'
    },
    floatparameterPrecision: {
      invalidFloatparameterPrecision: 'Precision is empty or incorrect. Only float allowed.'
    },
    stringparameterMaximumlength: {
      invalidStringparameterMaximumlength: 'MaximumLength is empty or incorrect. Only integer allowed.'
    },
    stringparameterMinimumlength: {
      invalidStringparameterMinimumlength: 'MinimumLength is empty or incorrect. Only integer allowed.'
    },
    stringparameterDefaultvalue: {
      invalidStringparameterDefaultvalue: 'DefaultValue is empty or incorrect. No special characters allowed'
    },
    characterparameterDefaultvalue: {
      invalidCharacterparameterDefaultvalue: 'Character is empty or incorrect. No special characters allowed'
    },
    selectionparameterDefaultvalue: {
      invalidSelectionparameterDefaultvalue: 'Selection is empty or incorrect. No special characters allowed'
    },
    selectionparameterNumberofoptions: {
      invalidSelectionparameterNumberofoptions: 'Numberofoptions is empty or incorrect. Only integer allowed'
    },
    selectionparameterOptions: {
      invalidSelectionparameterOptions: 'Options are empty or incorrect. No special characters allowed'
    }
  };

  constructor(private logger: NGXLogger) { }

  ngOnInit() {
  }

  errorForControl(): string[] {
    const messages = this.allMessages[this.controlName];
    if (
      !this.control ||
      !this.control.errors ||
      !messages ||
      !this.control.dirty
    ) { return null; }

    return Object.keys(this.control.errors)
    .map(err => messages[err]);
  }

}
