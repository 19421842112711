<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (tap)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [innerHTML]="text"></div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (tap)="activeModal.dismiss('cancel click')">cancel</button>
  <button type="button" class="btn btn-primary" ngbAutofocus (tap)="activeModal.close('confirmed')">{{type}}</button>
</div>

