import { StatsviewerControllerService } from './../../shared/services/statsviewer-controller.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public svc: StatsviewerControllerService) { }

  ngOnInit() {
  }

}
