import { ToastmessageStoreService } from './toastmessage-store.service';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, retry } from 'rxjs/operators';
import { ErrorhandlerService } from './errorhandler.service';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  public decimalToHex(d: any, padding: number) {
    let hex = Number(d).toString(16);
    padding = typeof (padding) === 'undefined' || padding === null ? padding = 2 : padding;

    while (hex.length < padding) {
        hex = '0' + hex;
    }
    return hex;
  }

  public isTypeValid(input, inputtype, usage?) {
    this.logger.debug(input);
    // tslint:disable-next-line: max-line-length
    const mimeTypes = {datasets: [], images: []};
    const fileExtensions =  {datasets: [], images: []};
    mimeTypes.datasets = ['image/png', 'image/jpeg', 'image/gif', 'image/tiff'];
    fileExtensions.datasets = ['.png', '.jpg', '.jpeg', '.gif', '.tif', '.tiff', '.cue', '.dat'];
    mimeTypes.images = ['image/png', 'image/jpeg', 'image/gif', 'image/tiff', 'video/mpeg', 'video/avi', 'video/mp4', 'video/x-msvideo'];
    fileExtensions.images = ['.png', '.jpg', '.jpeg', '.gif', '.tif', '.tiff', '.dib', '.cue', '.dat'];

    let validMimeTypes = [];
    let validFileExtensions =  [];
    if (usage === 'dataset') {
      validMimeTypes = mimeTypes.datasets;
      validFileExtensions = fileExtensions.datasets;
    } else {
      validMimeTypes = mimeTypes.images;
      validFileExtensions = fileExtensions.images;
    }

    if (inputtype === 'filename') {
      const filextension = input.name.substr(input.name.lastIndexOf('.')).toLowerCase();
      if (validFileExtensions.indexOf(filextension) > -1) {
        return true;
      } else {
        // alert("Invalid file type.  File must be one of following types " + validMimeTypes);
        return false;
      }
    }

    if (inputtype === 'filetype') {
      if (validMimeTypes.indexOf(input.type) > -1) {
        return true;
      } else {
        // alert("Invalid file type.  File must be one of following types " + validMimeTypes);
        // if no mimetype is set, set file extension as mimetype
        if ((input.type === '') || (input.type === 'file')) {
          const filextension = input.name.substr(input.name.lastIndexOf('.')).toLowerCase();
          if (validFileExtensions.indexOf(filextension) > -1) {
            return true;
          } else {
            // alert("Invalid file type.  File must be one of following types " + validMimeTypes);
            this.toastService.show('Filetype error!', 'Invalid filetype');
            return false;
          }
        }
      }
    }
  }

  public checkFilesize(size) {
    const maxsize = 300;
    if ((size / 1024 / 1024) < maxsize) {
      return true;
    } else {
      this.toastService.show('Filesize error!', 'File exceeds limit of ' + maxsize + ' MB');
      return false;
    }
  }

  public extensionIsVideo(filename) {
    // tslint:disable-next-line: max-line-length
    if ((filename.substr(-4) === '.mp4') || (filename.substr(-4) === '.avi') || (filename.substr(-4) === '.mov') || (filename.substr(-4) === '.mpg') || (filename.substr(-5) === '.mpeg')) {
      return true;
    } else {
      return false;
    }
  }

  allowedFiletypes(): Observable<any> {
    this.logger.debug('get allowed filetypes');
    return this.http.post<any>(
      `${environment.apiurl}/algorithms/allowedfiletypesConfig`,
      {}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  constructor(private toastService: ToastmessageStoreService, private http: HttpClient, private logger: NGXLogger) { }
}
