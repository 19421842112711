<div id="statsviewer" *ngIf="svc.visible | async">
  <div id="statsviewer_content" class="container">
    <wol-header></wol-header>
    <div class="row" style="width:100%; height:100%; margin:0px; position:relative; overflow: auto;" id="maincanvas" #maincanvas>

      <table class="table" style="width: initial;">
        <tbody>
        <tr>
          <td style="padding-right:15px; border-top:0px;">
            <wol-thumbnail [maxsize]="'150'" path="ufs_256px" filename="{{svc.job.input.substr(1)}}"></wol-thumbnail>
          </td>
          <td style="vertical-align: middle;padding-right:15px; border-top:0px;">
            <fa-icon [icon]="['far', 'angle-right']"></fa-icon>
          </td>
          <td style="padding-right:15px; border-top:0px;">
            <wol-thumbnail [maxsize]="'150'" path="ufs_256px" filename="{{svc.job.output.substr(1)}}"></wol-thumbnail>
          </td>
          <td style="padding-right:15px; border-top:0px;">
            <div style="padding:10px; padding-left:20px; margin-bottom:0px;background-color: transparent;border:0px;">
              <wol-jobs-statusbatch [status]="svc.job.status"></wol-jobs-statusbatch><br>
              <span style="font-weight: bold; margin: 0px; font-size: 0.9em; margin-left: 7px;">Input: {{svc.job.input.substr(svc.job.input.lastIndexOf("/") + 1)}}</span><br>
              <span style="font-weight: bold; margin: 0px; display: inline-block; margin-bottom: 7px; font-size: 0.9em; margin-left: 7px;">Output: {{svc.job.output.substr(svc.job.output.lastIndexOf("/") + 1)}}</span><br>
              <span style="margin: 0px; margin-bottom: 0px; font-size: 0.9em; margin-left: 7px;" *ngIf="(svc.job.processingtime !== '')">Processingtime: {{svc.job.processingtime | number: '1.0-0'}} seconds<br></span>
              <span style="margin: 0px; margin-bottom: 0px; font-size: 0.9em; margin-left: 7px;" *ngIf="(svc.job.processingtime !== '')">Finished: {{svc.job.statuschanged | date:'yyyy/MM/dd HH:mm'}}<br></span>

              <button *ngIf="svc.job.status === 'finished'" class="btn btn-success btn-xs" style="margin: 5px 0px;" (click)="fdc.requestnewdownload(outputpath.split('/'), [{type: 'file', name: svc.job.output.substr(svc.job.output.lastIndexOf('/') + 1), path: svc.job.output.substr(1)}])"><i class="fal fa-download"></i> Download Output</button>&nbsp;
              <button *ngIf="svc.job.status === 'finished'" class="btn btn-outline-success btn-xs" style="margin: 5px 0px;" (click)="fdc.requestnewdownload(overlaypath.split('/'), [{type: 'file', name: svc.job.output.substr(svc.job.output.lastIndexOf('/') + 1), path: overlaypath + '/' + svc.job.output.substr(svc.job.output.lastIndexOf('/') + 1)}])"><i class="fal fa-download"></i> Download Overlay</button>&nbsp;
              <button *ngIf="svc.job.status === 'finished'" class="btn btn-outline-success btn-xs" style="margin: 5px 0px;" (click)="fdc.requestnewdownload(contourspath.split('/'), [{type: 'folder', name: 'contoursdata', path: contourspath + '/'}])"><i class="fal fa-download"></i> Download Contours</button>
            </div>
          </td>
        </tr>
        </tbody>
      </table>


      <div style="padding:20px;font-size:0.9em; margin-bottom:5px;width:100%;max-height:400px; overflow:auto;border:1px solid #cccccc;background-color: #f4f4f4;" *ngFor="let statdata of svc.job.statsdata">
        <b>{{statdata.name}}</b> [{{statdata.type}}]<br><br>
        <div *ngIf="statdata.type === 'variables'" >
          <table class="table table-bordered table-striped" style="width:250px;border:0px;">
            <thead>
            <tr>
              <th style="height:25px;border-left:0px;border-right:0px;" *ngFor="let field of statdata.data.schema.fields">
                {{field.name}}
              </th>
            </tr>

            </thead>
            <tbody>
            <tr *ngFor="let row of statdata.data.data">
              <td *ngFor="let item of row | keyvalue">
                {{item.value}}
              </td>
            </tr>
            </tbody>
          </table>

        </div>
        <div *ngIf="statdata.type === 'table'">
          <table class="table table-bordered table-striped" style="width:250px;border:0px;">
            <thead>
            <tr>
              <th style="white-space:nowrap; height:25px;border-left:0px;border-right:0px;" *ngFor="let field of statdata.data.schema.fields">
                  <span ng-hide="field.title">{{field.name}}</span>
                  <span ng-show="field.title">{{field.title}}</span>
              </th>
            </tr>
            </thead>

            <tbody>
            <tr *ngFor="let row of statdata.data.data">
              <td *ngFor="let field of statdata.data.schema.fields">
                {{row[field.name]}}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

    </div>
    <wol-footer></wol-footer>
  </div>
</div>
