export const environment = {
  // envname "development", 'test' or 'production'
  envname: 'development',
  production: true,
  apiurl: 'https://dev.api.wolution.ai',
  // sessioncookiename: '_t_dev', '_t_test' or '_t'
  sessioncookiename: '_t_dev',
  consoleLogging: true,
  // data exchange target "development", 'test' or 'production'
  exchangetarget: 'test'
};
