import { filter } from 'rxjs/operators';
import { Injectable, ElementRef, Renderer2 } from '@angular/core';
import * as THREE from 'three';
import * as _ from 'lodash';
import { MapService } from './map.service';
import { MinimapService } from './minimap.service';
import { DataviewerControllerService } from '../shared/services/dataviewer-controller.service';


@Injectable({
  providedIn: 'root'
})
export class WebglService {

  public renderid = 0;
  private lastRender = 0;
  public mapRenderer = new THREE.WebGLRenderer({antialias: false});
  public mapElementRef: ElementRef;
  public mapCanvas: HTMLCanvasElement;
  public minimapRenderer = new THREE.WebGLRenderer({antialias: false});
  public minimapElementRef: ElementRef;
  public minimapCanvas: HTMLCanvasElement;
  public maxFps = 30;

  render(force?: string) {
    if ((this.lastRender < (Date.now() - (1000 / this.maxFps))) || (force === 'force')) {
      this.lastRender = Date.now();
      this.mapRenderer.render(this.dvc.scene, this.mapService.camera);
      this.minimapRenderer.render(this.dvc.scene, this.minimapService.camera);
    }
  }

  init(mapElementRef: ElementRef, minimapElementRef: ElementRef, ngRenderer: Renderer2) {
    this.dvc.scene.background = new THREE.Color( 0xEEEEEE );
    this.mapElementRef = mapElementRef;
    this.minimapElementRef = minimapElementRef;

    const mapElement = this.mapElementRef.nativeElement;
    const minimapElement = this.minimapElementRef.nativeElement;

    this.mapRenderer.setSize(
      mapElement.clientWidth,
      mapElement.clientHeight
    );
    ngRenderer.appendChild(
      mapElement,
      this.mapRenderer.domElement
    );

    this.minimapRenderer.setSize(
      minimapElement.clientWidth,
      minimapElement.clientWidth * (mapElement.clientHeight / mapElement.clientWidth)
    );

    ngRenderer.appendChild(
      minimapElement,
      this.minimapRenderer.domElement
    );

    this.mapCanvas = this.mapElementRef.nativeElement.querySelectorAll('canvas')[1] as HTMLCanvasElement;
    this.minimapCanvas = this.minimapElementRef.nativeElement.querySelectorAll('canvas')[0] as HTMLCanvasElement;

    this.render();

    this.dvc.rerender.subscribe({next: rerender => {
      if (rerender) {
        this.render('force');
        this.dvc.rerenderUpdate(false);
      }
    }}
    );
  }

  constructor(public mapService: MapService, public minimapService: MinimapService, public dvc: DataviewerControllerService) { }
}
