<div class="filelist-datatable" style="height:100%;">
  <ngx-datatable
  #filelisttable
  class="bootstrap"
  style="height: calc(100vh - 290px);"
  [rows]="fbs.currentFiles"
  [loadingIndicator]="fbs.isLoading > 0"
  [columns]="columns"
  [columnMode]="'force'"
  [headerHeight]="50"
  [footerHeight]="30"
  [selected]="fbs.selected"
  [selectionType]="'multi'"
  [scrollbarV]="true"
  (activate)="onActivate($event)"
  (select)='onSelect($event)'
  (tableContextmenu)="onTableContextMenu($event)"
  [rowHeight]="50"
  [externalPaging]="true"
  [count]="fbs.page.totalElements"
  [offset]="fbs.page.pageNumber"
  (page)="setPage($event)"
  [externalSorting]="true"
  (sort)="setSort($event)"
  [sorts]="[{ prop: fbs.page.sortname, dir: fbs.page.sortorder }]">
  </ngx-datatable>
  <div *ngIf="fbs.isLoading > 0" style="position:relative; top: -81px; height: 50px; background-color: #ffffff; padding: 8px;">
    <div class="d-flex justify-content-center" style="margin-top:5px;">
      <div class="spinner-grow text-primary" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</div>
<!--[scrollbarV]="fbs.modus === 'fileselector'"-->
<!--[limit]="fbs.page.size"-->
<ng-template #thumbnail let-value="value" let-row="row">
  <span *ngIf="row">
    <fa-icon *ngIf="row.type == 'folder'" [icon]="['far', 'folder']"></fa-icon>
    <fa-icon *ngIf="(row.type == 'file') && (listviewmodus === 'nothumbnails')" [icon]="['far', 'file']"></fa-icon>
    <wol-thumbnail [maxsize]="'32'" *ngIf="(row.type == 'file') && (listviewmodus === 'smallthumbnails')" path="ufs_32px" filename="{{row.path}}"></wol-thumbnail>
  </span>
</ng-template>

<ng-template #lastmodified let-value="value">
  {{value | date: "MM/d/yy, h:mm"}}
</ng-template>

<ng-template #size let-value="value" let-row="row">
  <span *ngIf="((row) && (row.type === 'file'))">
    {{value / 1024 | number: '1.0-0'}} kB
  </span>
</ng-template>

<ng-template #loading>
  <div class="d-flex justify-content-center" style="margin-top:50px;">
    <div class="spinner-grow text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>

<wol-filecontextmenu></wol-filecontextmenu>
