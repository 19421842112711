import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FileStoreService } from '../../services/file-store.service';
import { TreeComponent, ITreeOptions } from '@circlon/angular-tree-component';
import * as _ from 'lodash';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'wol-foldertree',
  templateUrl: './foldertree.component.html',
  styleUrls: ['./foldertree.component.scss']
})
export class FoldertreeComponent implements OnInit {

  @ViewChild('tree', { static: true }) tree: TreeComponent;
  @Output() setSelectedFolder = new EventEmitter<any>();

  public foldertreeitems: any;
  public nodes: any[];

  options: ITreeOptions = {
    getChildren: this.getChildren.bind(this),
    actionMapping: {
      mouse: {
        click: (tree, node, $event) => {
          node.toggleExpanded();
          node.setIsActive(true);
          this.logger.debug(tree.activeNodes);
          this.setSelectedFolder.emit(tree.activeNodes[0]);
        }
      }
    }
  };

  getChildren(node: any) {
    if (node.index !== '/') {
      this.logger.debug('request childtree');
      return new Promise((resolve, reject) => {
        this.fs.folderTree(node.data.path).subscribe(res => {
          this.logger.debug('received node children');
          const newNodes = [];
          _.each(res.children, (childitem, index, list) => {
            // tslint:disable-next-line: max-line-length
            if (childitem.type === 'folder') {
              // this.logger.debug(childitem);
              // tslint:disable-next-line: max-line-length
              newNodes.push({path: childitem.path, name: childitem.name, hasChildren: true, children: null, isExpanded: false});
            }
          });
          resolve(newNodes);
        });
      });
    }
  }

  public setTree() {
    this.nodes = [];
    // tslint:disable-next-line: max-line-length
    this.nodes.push({path: '/', name: 'Wolution Files', children: [], hasChildren: true, isExpanded: true});
    this.tree.treeModel.update();

    _.each(this.foldertreeitems.children, (treeitem, index2, list2) => {
      // tslint:disable-next-line: max-line-length
      if (treeitem.type === 'folder') {
        // tslint:disable-next-line: max-line-length
        this.nodes[this.nodes.length - 1].children.push({path: treeitem.path, name: treeitem.name, children: null, hasChildren: true, isExpanded: false});
      }
    });
    this.tree.treeModel.update();
  }

  constructor(public fs: FileStoreService, private logger: NGXLogger) { }

  ngOnInit() {
    this.fs.folderTree('').subscribe(res => {
      this.foldertreeitems = res;
      this.logger.debug(res);
      this.setTree();
    });
  }

}
