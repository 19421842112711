import { NotificationRaw } from './notification-raw';
import { Notification } from './notification';

export class NotificationFactory {
  static fromRaw(n: NotificationRaw): Notification {
    return {
      ...n
    };
  }
}
