import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wol-jobs-statusbatch',
  templateUrl: './jobs-statusbatch.component.html',
  styleUrls: ['./jobs-statusbatch.component.scss']
})
export class JobsStatusbatchComponent implements OnInit {

  @Input() status: string;

  constructor() { }

  ngOnInit() {
  }

}
