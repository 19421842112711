import { ToastmessageStoreService } from './../shared/services/toastmessage-store.service';
import { NGXLogger } from 'ngx-logger';
import { FileStoreService } from 'src/app/shared/services/file-store.service';
import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { ModalRenameComponent } from './modal-rename/modal-rename.component';
import { ModalMoveComponent } from './modal-move/modal-move.component';
import { ModalCopyComponent } from './modal-copy/modal-copy.component';
import { ModalUploadComponent } from './modal-upload/modal-upload.component';
import { File } from './../shared/interfaces/file';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { FiledownloaderControllerService } from '../shared/services/filedownloader-controller.service';
import { FileuploaderControllerService } from '../shared/services/fileuploader-controller.service';
import { ModalCreatefolderComponent } from './modal-createfolder/modal-createfolder.component';

@Injectable({
  providedIn: 'root'
})
export class FilebrowserService {
  public isLoading = 0;
  public selected = [];
  public currentpath = [];
  public currentFiles: File[] = [];
  public currentFilesCache: any = {};
  public currentFilesFiltered: File[] = [];
  // how many pages per request?
  public pagesPerRequest = 1;

  public modus = 'filebrowser';
  public page = {pageNumber: 0, size: 0, totalElements: 0, sortname: '', sortorder: '', searchstring: ''};

  public listviewmodusSource = new BehaviorSubject<string>('smallthumbnails');
  public listviewmodus = this.listviewmodusSource.asObservable();
  public listviewmoduschanged = false; // prevent ffox wrong behaviour by setting a marker when listviewmodus got changed manually
  public searchstringSource = new BehaviorSubject<string>('');
  public searchstring = this.searchstringSource.asObservable();

  public listviewmodusUpdate(value: string) {
    this.listviewmodusSource.next(value);
  }

  public searchstringUpdate(value: string) {
    this.searchstringSource.next(value);
  }

  public countSelectedFolders() {
    let count = 0;
    _.each(this.selected, (item, index, list) => {
      if (item.type === 'folder') {
        count += 1;
      }
    });

    return count;
  }

  public loadpage(pageinfo) {
    this.logger.debug('load page');
    this.fs.getItemlistPage(
      this.currentpath.join('/') + '/' ,
      {
        page: pageinfo.pageNumber,
        pagesPerRequest: this.pagesPerRequest,
        size: pageinfo.size,
        sortname: pageinfo.sortname,
        sortorder: pageinfo.sortorder,
        searchstring: pageinfo.searchstring
      }
    ).subscribe(res => {
      this.page.totalElements = res.itemcount;
      if ((this.page.totalElements > 50) && (pageinfo.pageNumber === 0) && (!this.listviewmoduschanged)){
        this.listviewmodusUpdate('nothumbnails');
      }
      this.logger.debug('page: ' + res.pageNumber + ' itemcount: ' + res.itemdata.length);
      if (res.itemdata.length) {
        for (let i = 0; i < this.pagesPerRequest; i++) {
          this.currentFilesCache[res.pageNumber + i] = true;
        }
      }

      // create array to store data if missing
      if (!this.currentFiles.length) {
        // length should be total count
        this.currentFiles = new Array<File>(this.page.totalElements || 0);
      }

      // calc starting index
      const start = pageinfo.pageNumber * pageinfo.size;

      // copy existing data
      const currentFiles = [...this.currentFiles];

      // insert new rows into new position
      currentFiles.splice(start, res.itemdata.length, ...res.itemdata);

      // set rows to our new rows
      this.currentFiles = currentFiles;

      this.currentFiles = [...this.currentFiles];

      // hotfix: sometimes not rendering if pagenumber is 0
      if (res.pageNumber === 0) {
        // setTimeout(() => { document.getElementsByTagName('datatable-body')[0].scrollTop++; }, 50);
      }

      this.isLoading--;
    });
  }

  public countSelectedFiles() {
    let count = 0;
    _.each(this.selected, (item, index, list) => {
      if (item.type === 'file') {
        count += 1;
      }
    });

    return count;
  }

  public filterFilelist(searchstring: string) {

    // hotfix for swimlane table: table would be "empty" when vertical scroll is not reset
    document.getElementsByTagName('datatable-body')[0].scrollTop = 0;

    setTimeout(() => {
      this.page.pageNumber = 0;
      this.page.searchstring = searchstring;
      this.currentFilesCache = {};
      this.currentFiles = [];
      this.isLoading = 0;
      this.isLoading++;
      this.loadpage(this.page);
    }, 50);
  }

  public refreshCurrentFiles = () => {
    // hotfix for swimlane table: table would be "empty" when vertical scroll is not reset
    document.getElementsByTagName('datatable-body')[0].scrollTop = 0;

    setTimeout(() => {
      this.page.pageNumber = 0;
      this.page.searchstring = '';
      this.currentFilesCache = {};
      this.currentFiles = [];
      this.isLoading = 0;
      this.isLoading++;
      this.loadpage(this.page);
    }, 50);
  }

  public requestdelete = (selecteditems) => {
    this.logger.debug('request delete item');

    // timeout necessary to prevent error on click in combination with datatable!
    // const modalRef = this.modalService.open(ArticleRemoveModalComponent);
    // modalRef.componentInstance.name = 'World';
    setTimeout(() => {
      const modalRef = this.modalService.open(ModalDeleteComponent, {ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.type = 'delete';
      modalRef.componentInstance.title = 'Delete Item';
      modalRef.componentInstance.text = `Are you sure you want to permanently delete:<br>`;
      modalRef.componentInstance.objectlist = selecteditems;
      modalRef.result.then((result) => {
        this.logger.debug(`closed: ${result}`);

        this.fs.deleteItems(selecteditems)
          .subscribe(
            (res: any) => {
              this.logger.debug(res);
              this.selected = [];
              this.toastService.show('Items deleted!', res[0].message);
              this.refreshCurrentFiles();
            },
            (err) => {
              this.logger.debug(err);
              this.toastService.show('ERROR: Items not deleted!', 'Could not delete items.');
            }
          );
      }, (reason) => {
        this.logger.debug(`dismissed: ${reason}`);
      });
    });
  }

  public requestrename = (selecteditems) => {
    this.logger.debug('request rename item');

    // timeout necessary to prevent error on click in combination with datatable!
    // const modalRef = this.modalService.open(ArticleRemoveModalComponent);
    // modalRef.componentInstance.name = 'World';
    setTimeout(() => {
      const modalRef = this.modalService.open(ModalRenameComponent, {ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.type = 'rename';
      modalRef.componentInstance.title = 'Rename Item';
      modalRef.componentInstance.text = `Are you sure you want to permanently rename:<br>`;
      modalRef.componentInstance.oldFoldername = selecteditems[0].name;
      modalRef.result.then((result) => {
        const newname = result.value.foldername;
        const oldname = selecteditems[0].name;
        this.logger.debug([selecteditems[0].type, `closed - foldername: ${newname}`, oldname]);

        if ((oldname !== newname) && (newname !== '')) {
          // tslint:disable-next-line: max-line-length
          const credentials = {renamepath: selecteditems[0].path, oldname: selecteditems[0].name, newname, type: selecteditems[0].type};
          this.logger.debug(credentials);
          this.fs.renameItem(credentials)
            .subscribe(
              (res: any) => {
                this.logger.debug(res);
                if (selecteditems[0].type === 'folder') {
                  this.toastService.show('Folder renamed!', 'Folder successfully renamed.');
                } else {
                  this.toastService.show('File renamed!', 'File successfully renamed.');
                }
                this.refreshCurrentFiles();
              },
              (err) => {
                this.logger.debug(err);
                if (selecteditems[0].type === 'folder') {
                  this.toastService.show('ERROR: Folder not renamed!', 'Could not rename folder.');
                } else {
                  this.toastService.show('ERROR: File not renamed!', 'Could not rename file.');
                }
              }
            );
        }
      }, (reason) => {
        this.logger.debug(`dismissed: ${reason}`);
      });
    });
  }

  public requestmove = (selecteditems) => {
    this.logger.debug('request move item');

    // timeout necessary to prevent error on click in combination with datatable!
    // const modalRef = this.modalService.open(ArticleRemoveModalComponent);
    // modalRef.componentInstance.name = 'World';
    setTimeout(() => {
      const modalRef = this.modalService.open(ModalMoveComponent, {ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.type = 'move';
      modalRef.componentInstance.title = 'Move Item';
      modalRef.componentInstance.text = `Are you sure you want to permanently move:<br>`;
      modalRef.result.then((result) => {
        this.logger.debug(`closed: ${result}`);

        this.fs.moveItems(selecteditems, result)
          .subscribe(
            (res: any) => {
              this.logger.debug(res);
              this.toastService.show('Items moved!', 'Items successfully moved.');
              this.refreshCurrentFiles();
            },
            (err) => {
              this.logger.debug(err);
              this.toastService.show('ERROR: Items not moved!', 'Could not move items.');
            }
          );
      }, (reason) => {
        this.logger.debug(`dismissed: ${reason}`);
      });
    });
  }

  public requestcopy = (selecteditems) => {
    this.logger.debug('request copy item');

    // timeout necessary to prevent error on click in combination with datatable!
    // const modalRef = this.modalService.open(ArticleRemoveModalComponent);
    // modalRef.componentInstance.name = 'World';
    setTimeout(() => {
      const modalRef = this.modalService.open(ModalCopyComponent, {ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.type = 'copy';
      modalRef.componentInstance.title = 'Copy Item';
      modalRef.componentInstance.text = `Are you sure you want to permanently copy:<br>`;
      modalRef.result.then((result) => {
        this.logger.debug(`closed: ${result}`);
        this.fs.copyItems(selecteditems, result)
          .subscribe(
            (res: any) => {
              this.logger.debug(res);
              this.toastService.show('Items copied!', 'Items successfully copied.');
              this.refreshCurrentFiles();
            },
            (err) => {
              this.logger.debug(err);
              this.toastService.show('ERROR: Items not copied!', 'Could not copy items.');
            }
          );
      }, (reason) => {
        this.logger.debug(`dismissed: ${reason}`);
      });
    });
  }

  public requestupload = () => {
    this.logger.debug('request delete item');

    // timeout necessary to prevent error on click in combination with datatable!
    // const modalRef = this.modalService.open(ArticleRemoveModalComponent);
    // modalRef.componentInstance.name = 'World';
    setTimeout(() => {
      const modalRef = this.modalService.open(ModalUploadComponent, {ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.type = 'upload';
      modalRef.componentInstance.title = 'Upload Files';
      modalRef.result.then((result) => {
        _.each(result, (item, list, index) => {
          this.logger.debug(item);
          item.context = 'files';
        });
        this.fuc.addFilesToUploadQueue(this.currentpath.join('/') + '/', result);
      }, (reason) => {
        this.logger.debug(`dismissed: ${reason}`);
      });
    });
  }

  public requestcreatefolder = () => {
    this.logger.debug('request create folder');

    // timeout necessary to prevent error on click in combination with datatable!
    setTimeout(() => {
      const modalRef = this.modalService.open(ModalCreatefolderComponent, {ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.type = 'create';
      modalRef.componentInstance.title = 'Create folder';
      modalRef.result.then((result) => {
        this.logger.debug(`closed - foldername: ${result.value.foldername}`);
        this.fs.createFolder({path: this.currentpath.join('/') + '/', name: result.value.foldername})
          .subscribe(
            (res: any) => {
              this.logger.debug(res);
              this.toastService.show('Folder created!', 'Folder successfully created.');
              this.refreshCurrentFiles();
            },
            (err) => {
              this.logger.debug(err);
              this.toastService.show('ERROR: Folder not created!', 'Could not create new folder.');
            }
          );
      }, (reason) => {
        this.logger.debug(`dismissed: ${reason}`);
      });
    });
  }

  constructor(
    public fs: FileStoreService,
    private logger: NGXLogger,
    public toastService: ToastmessageStoreService,
    public modalService: NgbModal,
    public fuc: FileuploaderControllerService,
    public fdc: FiledownloaderControllerService
  ) { }
}
