<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div  [innerHTML]="text"></div>
  <ul style="width:450px; max-height: 500px; overflow: auto;">
    <li *ngFor='let item of objectlist' style="white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
      {{item.name}}
    </li>
  </ul>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('cancel click')">cancel</button>
  <button type="button" class="btn btn-primary" ngbAutofocus (click)="activeModal.close('confirm click')">{{type}}</button>
</div>
