<div class="row nomargin align-items-center" style="min-height:35px;">
  <div class="col-md-auto" *ngIf="layer.show" style="padding:10px;" (tap)="showLayer()">
    <fa-icon [icon]="['far', 'eye']"></fa-icon>
  </div>
  <div class="col-md-auto" *ngIf="!layer.show" style="padding:10px;" (tap)="showLayer()">
    <fa-icon [icon]="['far', 'eye-slash']"></fa-icon>
  </div>

  <div class="col"(tap)="selectLayer()">
    <div class="row">
      <div class="col" style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
        <div class="row">
          <b style="padding-right:5px;">{{layer.name}}</b><br>
        </div>
        <div class="row" style="font-size: 0.9em;">
          opacity: {{layer.plane.material.opacity * 100 | number: '1.0-0'}} %
        </div>
        <div [hidden]="layer.label" class="row">
          <select (tap)="$event.stopPropagation();" (change)="labeltoolsService.changeColorchannel($event.target.value)" style="font-size:0.9em;padding:3px;width:90px; margin-top:5px;">
            <option value="all">all channels</option>
            <option value="red">red</option>
            <option value="green">green</option>
            <option value="blue">blue</option>
          </select>
        </div>
        <div class="row" [hidden]="!(((dvc.modus | async) === 'analysis') && (layer.name !== 'Original Image'))"  style="font-size: 0.9em;">
          <span>interpret as label</span>
          <input type="checkbox" (change)="interpretAsLabel($event.target.checked)" style="margin-top:2px; margin-left:5px;">
        </div>
      </div>
      <div [hidden]="(dvc.modus |async) !== 'dataset'" class="col-md-auto">
        <div (tap)="$event.stopPropagation();" [hidden]="!layer.label" class="btn-group" dropdown is-open="status.isopen" style="margin-top:7px;padding-right:5px;">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-sm btn-outline-success" id="dropdownStatistics" ngbDropdownToggle>edit</button>
            <div ngbDropdownMenu aria-labelledby="dropdownStatistics" style="font-size:0.9em;">
              <button ngbDropdownItem (tap)="openLayersettings()">
                settings
              </button>
              <button ngbDropdownItem (tap)="confirmClearlayer()">
                clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
