import { NGXLogger } from 'ngx-logger';
import { Job } from 'src/app/shared/interfaces/job';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StatsviewerControllerService {

  public job: Job;
  public visibleSource = new BehaviorSubject<boolean>(false);
  public visible = this.visibleSource.asObservable();

  public visibleUpdate(value: boolean) {
    this.visibleSource.next(value);
  }

  public open(job: Job) {
    this.job = job;
    this.logger.debug(job);
    this.visibleUpdate(true);
  }

  public close() {
    this.visibleUpdate(false);
    this.job = null;
  }

  constructor(private logger: NGXLogger) { }
}
