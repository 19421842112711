import { NGXLogger } from 'ngx-logger';
import { PenciltipsService } from './penciltips.service';
import { DataviewerControllerService } from './../shared/services/dataviewer-controller.service';
import { SharedModule } from './../shared/shared.module';
import { Injectable } from '@angular/core';
import { MapService } from './map.service';
import * as THREE from 'three';
import * as _ from 'lodash';
import { NumberFormatStyle } from '@angular/common';
import { WebglService } from './webgl.service';

@Injectable({
  providedIn: 'root'
})
export class LabeltoolsService {
  public startboxdetectionmarker: any;
  public startboxdetectionposition: any;
  public currentboxdetectionindex = -1;
  public undoredolabel: any;
  public drawmode = 'pencil';
  public drawmodeActive = false;
  public cursorposition = {x: 0, y: 0, offsetX: 0, offsetY: 0, onLayer: false};
  public moveboxactive = false;
  public movetype = '';
  public pointqueue: any[];
  public pointqueueX: any[];
  public pointqueueY: any[];
  public editboxdetected = false;
  public linewidth = 5;
  public magicwandTolerance = 10;
  public magicwandContiguous = true;

  public mouseup_segmentation(event, mapcanvasContainer) {
    this.webgl.render('force');
  }

  public mouseup_boxdetection(event, mapcanvasContainer) {

    class WolGroup extends THREE.Group {
      classId: any;
      trained: any;
    }

    this.logger.debug('mouse up BoxDetection');
    this.moveboxactive = false;
    this.movetype = '';
    if (this.dvc.pages[0].layers[this.dvc.activelayerindex].label) {
      this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
      this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;
      // update the picking ray with the camera and mouse position
      this.mapService.raycaster.setFromCamera( this.mapService.mouse, this.mapService.camera );

      // calculate objects intersecting the picking ray
      const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children );
      // console.timeEnd("intersect");
      if ((intersects.length > 0 ) && (this.drawmodeActive === true)) {
        this.drawmodeActive = false;
        if (this.drawmode === 'editbox') {
          this.currentboxdetectionindex = -1;
        }
        if (this.drawmode === 'addbox') {
          let posx = Math.ceil(intersects[0].point.x);
          let posy = Math.ceil(intersects[0].point.y);
          this.logger.debug(posx + ' : ' + posy);
          if (posx < Math.ceil(-1 * this.dvc.inputtexture.image.width / 2)) {
            posx = Math.ceil(-1 * this.dvc.inputtexture.image.width / 2);
          }
          if (posx > Math.ceil(1 * this.dvc.inputtexture.image.width / 2)) {
            posx = Math.ceil(1 * this.dvc.inputtexture.image.width / 2);
          }
          if (posy < Math.ceil(-1 * this.dvc.inputtexture.image.height / 2)) {
            posy =  Math.ceil(-1 * this.dvc.inputtexture.image.height / 2);
          }
          if (posy > Math.ceil(1 * this.dvc.inputtexture.image.height / 2)) {
            posy = Math.ceil(1 * this.dvc.inputtexture.image.height / 2);
          }

          // transform position from centered coordinates to left corner coordinates
          // posx = Math.ceil(posx + (this.dvc.inputtexture.image.width / 2));
          // posy = Math.ceil(posy + (this.dvc.inputtexture.image.height / 2));

          // check for minimum box size
          // tslint:disable-next-line: max-line-length
          if ((Math.abs(posx - this.startboxdetectionmarker.startX) > 5) && ((Math.abs(posx - this.startboxdetectionmarker.startX) > 5))) {
            const boxShape = new THREE.Shape();
            this.logger.debug('change1');

            boxShape.moveTo( this.startboxdetectionmarker.startX, this.startboxdetectionmarker.startY);
            boxShape.lineTo( posx, this.startboxdetectionmarker.startY);
            boxShape.lineTo( posx, posy);
            boxShape.lineTo( this.startboxdetectionmarker.startX, posy);
            boxShape.lineTo( this.startboxdetectionmarker.startX, this.startboxdetectionmarker.startY);

            const geometry = new THREE.ShapeGeometry( boxShape );
            // tslint:disable-next-line: max-line-length
            const material = new THREE.MeshBasicMaterial( { color: parseInt('0x' + this.dvc.activelayercolor.substr(1), 16), wireframe: true, } );
            const mesh = new THREE.Mesh( geometry, material ) ;
            mesh.name = 'boxwireframe';
            // tslint:disable-next-line: max-line-length
            const material2 = new THREE.MeshBasicMaterial( { color: parseInt('0x' + this.dvc.activelayercolor.substr(1), 16), transparent: true, opacity: 0.5 } );
            const mesh2 = new THREE.Mesh( geometry, material2 ) ;
            mesh2.name = 'box';

            if (this.currentboxdetectionindex < 0) {
              const group = new WolGroup();
              group.add(mesh);
              group.add(mesh2);
              group.classId = this.dvc.pages[0].layers[this.dvc.activelayerindex].classId;
              // this.dvc.boxdetectionmarker.push(mesh);
              // this.dvc.boxdetectionmarker.push(mesh2);
              this.dvc.boxdetectionmarker.push(group);
              this.currentboxdetectionindex = this.dvc.boxdetectionmarker.length;
            } else {
              this.logger.debug('change2');
              this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry = geometry;
              this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[1].geometry = geometry;
              // this.dvc.boxdetectionmarker[this.currentboxdetectionindex-1].geometry = geometry;
            }

            _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
              item.name = 'box_' + index;
              this.dvc.scene.add( item );
            });

            this.currentboxdetectionindex = -1;
            this.dvc.changesmade = true;
          }
        }
      } else {
        this.currentboxdetectionindex = -1;
        this.dvc.changesmade = true;
      }
      this.webgl.render();
    }

  }

  public mousedown_segmentation(event, mapcanvasContainer) {
    // this.logger.debug('mousedown segementation: ' + this.dvc.drawmode);
    this.drawmodeActive = true;
    this.pointqueueX = [];
    this.pointqueueY = [];
    this.pointqueue = [];

    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
    this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;

    // update the picking ray with the camera and mouse position
    this.mapService.raycaster.setFromCamera( this.mapService.mouse, this.mapService.camera );

    // calculate objects intersecting the picking ray
    const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children );

    this.dvc.pages[0].layerstatussaved = true;
    // this.logger.debug(intersects);
    if (intersects.length > 0 ) {
      this.dvc.pages[0].layerstatussaved = true;
      this.savelayerstatus('undo');

      let posx = Math.ceil(intersects[0].point.x);
      let posy = Math.ceil(intersects[0].point.y);

      // transform position from centered coordinates to left corner coordinates
      posx = Math.ceil(posx + (this.dvc.inputtexture.image.width / 2));
      posy = Math.ceil(posy + (this.dvc.inputtexture.image.height / 2));
      // this.logger.debug(posx + ' : ' + posy);

      if ((this.drawmode !== 'magicwand') && (this.drawmode !== 'fillpaint') && (this.drawmode !== 'cursor')) {
        // this.logger.debug(posx + ' ' + posy);
        this.pointqueueX.push(posx);
        this.pointqueueY.push(posy);
        this.pointqueue.push([posx, posy]);

        if (this.pointqueueX.length > 3) {
          this.pointqueueX.shift();
        }
        if (this.pointqueueY.length > 3) {
          this.pointqueueY.shift();
        }
        if (this.pointqueue.length > 3) {
          this.pointqueue.shift();
        }

        const f = this.penciltipsService.createInterpolant(this.pointqueueX, this.pointqueueY);
        const f2 = this.penciltipsService.createInterpolant(this.pointqueueY, this.pointqueueX);
        const message = '';
        if (this.pointqueueX.length > 1) {
          const dx = Math.abs(this.pointqueueX[this.pointqueueX.length - 1] - this.pointqueueX[this.pointqueueX.length - 2]);
          const dy = Math.abs(this.pointqueueY[this.pointqueueY.length - 1] - this.pointqueueY[this.pointqueueY.length - 2]);

          if (dx >= dy) {
            if (this.pointqueueX[this.pointqueueX.length - 2] < posx) {
              for (let x = this.pointqueueX[this.pointqueueX.length - 2]; x <= posx; x += 1) {
                // let xSquared = f(x);
                this.executeDraw(x, f(x));
              }
            } else {
              for (let x = this.pointqueueX[this.pointqueueX.length - 2]; x >= posx; x -= 1) {
                // let xSquared = f(x);
                this.executeDraw(x, f(x));
              }
            }
          } else {
            if (this.pointqueueY[this.pointqueueY.length - 2] < posy) {
              for (let y = this.pointqueueY[this.pointqueueY.length - 2]; y <= posy; y += 1) {
                // let xSquared = f(x);
                this.executeDraw(f2(y), y);
              }
            } else {
              for (let y = this.pointqueueY[this.pointqueueY.length - 2]; y >= posy; y -= 1) {
                // let xSquared = f(x);
                this.executeDraw(f2(y), y);
              }
            }
          }
        } else {
          this.executeDraw(this.pointqueueX[0], this.pointqueueY[0]);
        }
      } else {
        this.executeDraw(posx, posy);
      }
    }
  }

  public mousedown_boxdetection(event, mapcanvasContainer) {
    if (this.drawmode === 'addbox') {
      this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
      this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;
      // update the picking ray with the camera and mouse position
      this.mapService.raycaster.setFromCamera( this.mapService.mouse, this.mapService.camera );

      // calculate objects intersecting the picking ray
      const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children );
      this.logger.debug(intersects);
      // console.timeEnd("intersect");
      if (intersects.length > 0 ) {
        this.drawmodeActive = true;
        this.savelayerstatus('undo');
        this.startboxdetectionmarker = {startX: -1, startY: -1};
        const posx = Math.ceil(intersects[0].point.x);
        const posy = Math.ceil(intersects[0].point.y);
        // transform position from centered coordinates to left corner coordinates
        // posx = Math.ceil(posx + (this.dvc.inputtexture.image.width / 2));
        // posy = Math.ceil(posy + (this.dvc.inputtexture.image.height / 2));
        this.logger.debug('ADD MARKER: ' + posx + ' ' + posy);
        this.startboxdetectionmarker = {startX: posx, startY: posy};
        this.webgl.render();
        this.dvc.changesmade = true;
      }
    }

    if (this.drawmode === 'editbox') {
      this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
      this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;
      // update the picking ray with the camera and mouse position
      this.mapService.raycaster.setFromCamera( this.mapService.mouse, this.mapService.camera );

      // calculate objects intersecting the picking ray
      // this.logger.debug(this.dvc.scene);
      const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children, true);
      // console.timeEnd("intersect");
      if (intersects.length > 0 ) {
        this.drawmodeActive = true;
        this.currentboxdetectionindex = -1;
        this.moveboxactive = false;
        this.movetype = '';
        this.savelayerstatus('undo');
        this.startboxdetectionmarker = {startX: -1, startY: -1};
        const posx = Math.ceil(intersects[0].point.x);
        const posy = Math.ceil(intersects[0].point.y);
        // transform position from centered coordinates to left corner coordinates
        // posx = Math.ceil(posx + (this.dvc.inputtexture.image.width / 2));
        // posy = Math.ceil(posy + (this.dvc.inputtexture.image.height / 2));

        let tempSelectedgroup = -1;

        _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
          let tempcolor = '0x00ff00';
          _.each(this.dvc.pages[0].layers, (element, index2, list2) => {
            if (element.classId === item.classId) {
              // tslint:disable-next-line: max-line-length
              tempcolor = '0x' + this.dvc.decimalToHex(element.color_red) + this.dvc.decimalToHex(element.color_green) + this.dvc.decimalToHex(element.color_blue);
            }
          });
          item.children[0].material.color.setHex(parseInt(tempcolor, 16));
          item.children[1].material.color.setHex(parseInt(tempcolor, 16));
          if (item.children[0].name === 'box_selected') {
            item.children[0].name = 'box';
          }
          if (item.children[0].name === 'boxwireframe_selected') {
            item.children[0].name = 'boxwireframe';
          }
          if (item.children[1].name === 'box_selected') {
            item.children[1].name = 'box';
          }
          if (item.children[1].name === 'boxwireframe_selected') {
            item.children[1].name = 'boxwireframe';
          }

          let boxhit = false;

          if (intersects.length > 1) {
            // tslint:disable-next-line: max-line-length
            boxhit = ((item.name === intersects[intersects.length - 1].object.parent.name) || (item.name === intersects[intersects.length - 2].object.parent.name));
          }

          if (boxhit) {
            item.children = _.filter(item.children, (child) => child.name !== 'cornercube_ne');
            item.children = _.filter(item.children, (child) => child.name !== 'cornercube_nw');
            tempSelectedgroup = index;
            this.moveboxactive = true;
            this.startboxdetectionmarker = {startX: posx, startY: posy};
            this.startboxdetectionposition = {x: item.parent.position.x, y: item.parent.position.y};

            item.children[0].material.color.setHex(0xff0000);
            item.children[1].material.color.setHex(0xff0000);
            if (item.children[0].name === 'box') {
              item.children[0].name = 'box_selected';
            }
            if (item.children[0].name === 'boxwireframe') {
              item.children[0].name = 'boxwireframe_selected';
            }
            if (item.children[1].name === 'box') {
              item.children[1].name = 'box_selected';
            }
            if (item.children[1].name === 'boxwireframe') {
              item.children[1].name = 'boxwireframe_selected';
            }
            // $('#maincanvas > canvas').css('cursor', 'move');

            const cornercubeGeometry = new THREE.BoxGeometry( 10, 10, 1 );
            const cornercubeMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000, transparent: true, opacity: 1.0} );


            _.each(item.children[0].geometry.vertices, (element, index2: number, list2) => {

              let compareindex = 0;
              if (index2 === 0) {
                compareindex = 2;
              } else if (index2 === 1) {
                compareindex = 3;
              } else if (index2 === 2) {
                compareindex = 0;
              } else if (index2 === 3) {
                compareindex = 1;
              }

              let tempEdgecursor = 'ne';
              // tslint:disable-next-line: max-line-length
              if (Math.ceil(list2[index2].x + (this.dvc.inputtexture.image.width / 2)) <= Math.ceil(list2[compareindex].x + (this.dvc.inputtexture.image.width / 2))) {
                // dragged vertice is on the left side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[index2].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[compareindex].y + (this.dvc.inputtexture.image.height / 2))) {
                  // dragged vertice is on the left top
                  tempEdgecursor = 'nw';
                } else {
                  // dragged vertice is on the left bottom
                  tempEdgecursor = 'ne';
                }
              } else {
                // dragged vertice is on the right side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[index2].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[compareindex].y + (this.dvc.inputtexture.image.height / 2))) {
                  // dragged vertice is on the right top
                  tempEdgecursor = 'ne';
                } else {
                  // dragged vertice is on the right bottom
                  tempEdgecursor = 'nw';
                }
              }

              const cubeEdge = new THREE.Mesh( cornercubeGeometry, cornercubeMaterial );
              cubeEdge.position.x = element.x;
              cubeEdge.position.y = element.y;
              cubeEdge.position.z = 0.5;
              if (tempEdgecursor === 'nw') {
                cubeEdge.name = 'cornercube_nw';
              } else {
                cubeEdge.name = 'cornercube_ne';
              }

              item.add( cubeEdge );
            });

          } else {
            item.children = _.filter(item.children, (child) => child.name !== 'cornercube_ne');
            item.children = _.filter(item.children, (child) => child.name !== 'cornercube_nw');
          }
        });

        if (tempSelectedgroup >= 0) {
          this.dvc.boxdetectionmarker.push(this.dvc.boxdetectionmarker.splice(tempSelectedgroup, 1)[0]);
          this.currentboxdetectionindex = this.dvc.boxdetectionmarker.length;
        }
        _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
          this.dvc.scene.remove( item );
          this.dvc.scene.add( item );
        });

        this.dvc.changesmade = true;
      }
    }

    if (this.drawmode === 'removebox') {
      this.logger.debug('remove box');
      this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
      this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;
      // update the picking ray with the camera and mouse position
      this.mapService.raycaster.setFromCamera( this.mapService.mouse, this.mapService.camera );

      // calculate objects intersecting the picking ray
      // this.logger.debug(this.dvc.scene);
      const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children, true);
      // console.timeEnd("intersect");
      this.logger.debug(intersects);
      if (intersects.length > 0 ) {
        this.drawmodeActive = true;

        let boxremoved = false;

        _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
          this.logger.debug('EINS');

          let boxhit = false;

          if (intersects.length > 1) {
            this.logger.debug('ZWEI');
            // tslint:disable-next-line: max-line-length
            boxhit = ((item.name === intersects[intersects.length - 1].object.parent.name) || (item.name === intersects[intersects.length - 2].object.parent.name));
          }

          if (boxhit && !boxremoved) {
            this.logger.debug('DREI');
            this.dvc.scene.remove( item );
            item.name = 'deleted';
            boxremoved = true;
          }
        });
        this.dvc.boxdetectionmarker = _.filter(this.dvc.boxdetectionmarker, (item) => {
          return item.name !== 'deleted';
        });

        _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
          this.logger.debug(item.name);
          this.dvc.scene.remove( item );
          this.dvc.scene.add( item );
        });

        this.dvc.changesmade = true;
      }
    }
  }

  public mousemove_segmentation(event, mapcanvasContainer) {
    // calculate mouse position in normalized device coordinates
    // (-1 to +1) for both components
    this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
    this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;

    // update the picking ray with the camera and mouse position
    this.mapService.raycaster.setFromCamera( this.mapService.mouse, this.mapService.camera );

    // calculate objects intersecting the picking ray
    const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children );
    // console.timeEnd("intersect");
    if (intersects.length > 0 ) {
      if (!this.dvc.pages[0].layerstatussaved) {
        this.dvc.pages[0].layerstatussaved = true;
      }

      let posx = Math.ceil(intersects[0].point.x);
      let posy = Math.ceil(intersects[0].point.y);

      // transform position from centered coordinates to left corner coordinates
      posx = Math.ceil(posx + (this.dvc.inputtexture.image.width / 2));
      posy = Math.ceil(posy + (this.dvc.inputtexture.image.height / 2));
      // this.logger.debug(posx + ' : ' + posy);

      if ((this.drawmode !== 'magicwand') && (this.drawmode !== 'fillpaint')) {
        this.pointqueueX.push(posx);
        this.pointqueueY.push(posy);
        this.pointqueue.push([posx, posy]);

        if (this.pointqueueX.length > 3) {
          this.pointqueueX.shift();
        }
        if (this.pointqueueY.length > 3) {
          this.pointqueueY.shift();
        }
        if (this.pointqueue.length > 3) {
          this.pointqueue.shift();
        }

        const f = this.penciltipsService.createInterpolant(this.pointqueueX, this.pointqueueY);
        const f2 = this.penciltipsService.createInterpolant(this.pointqueueY, this.pointqueueX);

        const message = '';
        if (this.pointqueueX.length > 1) {
          const dx = Math.abs(this.pointqueueX[this.pointqueueX.length - 1] - this.pointqueueX[this.pointqueueX.length - 2]);
          const dy = Math.abs(this.pointqueueY[this.pointqueueY.length - 1] - this.pointqueueY[this.pointqueueY.length - 2]);

          if (dx >= dy) {
            if (this.pointqueueX[this.pointqueueX.length - 2] < posx) {
              for (let x = this.pointqueueX[this.pointqueueX.length - 2]; x <= posx; x += 1) {
                // let xSquared = f(x);
                this.executeDraw(x, f(x));
              }
            } else {
              for (let x = this.pointqueueX[this.pointqueueX.length - 2]; x >= posx; x -= 1) {
                // let xSquared = f(x);
                this.executeDraw(x, f(x));
              }
            }
          } else {
            if (this.pointqueueY[this.pointqueueY.length - 2] < posy) {
              for (let y = this.pointqueueY[this.pointqueueY.length - 2]; y <= posy; y += 1) {
                // let xSquared = f(x);
                this.executeDraw(f2(y), y);
              }
            } else {
              for (let y = this.pointqueueY[this.pointqueueY.length - 2]; y >= posy; y -= 1) {
                // let xSquared = f(x);
                this.executeDraw(f2(y), y);
              }
            }
          }
        } else {
          this.executeDraw(this.pointqueueX[0], this.pointqueueY[0]);
        }
      }
    }
  }

  public mousemove_boxdetection(event, mapcanvasContainer) {

    class WolGroup extends THREE.Group {
      classId: any;
      trained: any;
    }

    if (this.drawmode === 'editbox') {
      this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
      this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;
      // update the picking ray with the camera and mouse position
      this.mapService.raycaster.setFromCamera( this.mapService.mouse, this.mapService.camera );

      const resizebox = (posx: number, posy: number) => {
        // tslint:disable-next-line: max-line-length
        // this.logger.debug('resizebox: ' + (this.startboxdetectionmarker.startX - posx) + ' ' + (this.startboxdetectionmarker.startY - posy));

        // this.logger.debug('resize index: ' +this.currentboxdetectionindex-1);
        // this.logger.debug(this.dvc.boxdetectionmarker[this.currentboxdetectionindex-1]);

        let tempchildid = -1;
        let tempmindistance = 999999;

        _.each(this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children, (item, index: number, list) => {
          if ((item.name === 'cornercube_ne') || (item.name === 'cornercube_nw')) {
            const distanceX = Math.abs(item.position.x - posx);
            const distanceY = Math.abs(item.position.y - posy);
            if ((distanceX + distanceY) < tempmindistance) {
              tempmindistance = (distanceX + distanceY);
              tempchildid = index;
            }
          }
        });

        if (tempchildid >= 0) {
          // tslint:disable-next-line: max-line-length
          this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children = _.filter(this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children, (child) => child.name !== 'cornercube_ne');
          // tslint:disable-next-line: max-line-length
          this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children = _.filter(this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children, (child) => child.name !== 'cornercube_nw');

          const cornercubeGeometry = new THREE.BoxGeometry( 10, 10, 1 );
          const cornercubeMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000, transparent: true, opacity: 1.0} );

          // tslint:disable-next-line: max-line-length
          _.each(this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.vertices, (element, index2: number, list2) => {

            let tempEdgecursor = '';

            if (tempchildid === 2) {
              // tslint:disable-next-line: max-line-length
              if (Math.ceil(list2[0].x + (this.dvc.inputtexture.image.width / 2)) <= Math.ceil(list2[2].x + (this.dvc.inputtexture.image.width / 2))) {
                // dragged vertice is on the left side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[0].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[2].y + (this.dvc.inputtexture.image.height / 2)))  {
                  // dragged vertice is on the left top
                  tempEdgecursor = 'nw';
                  if (index2 === 0) {
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    tempEdgecursor = 'nw';
                  }
                  if (index2 === 1) {
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    tempEdgecursor = 'ne';
                  }
                  if (index2 === 3) {
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    tempEdgecursor = 'ne';
                  }
                } else {
                  // dragged vertice is on the left bottom
                  tempEdgecursor = 'ne';
                  if (index2 === 0) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 1) {
                    tempEdgecursor = 'nw';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                  }
                  if (index2 === 3) {
                    tempEdgecursor = 'nw';
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                }
              } else {
                // dragged vertice is on the right side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[0].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[2].y + (this.dvc.inputtexture.image.height / 2)))  {
                  // dragged vertice is on the right top
                  tempEdgecursor = 'ne';
                  if (index2 === 0) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 1) {
                    tempEdgecursor = 'nw';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                  }
                  if (index2 === 3) {
                    tempEdgecursor = 'nw';
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                } else {
                  // dragged vertice is on the right bottom
                  tempEdgecursor = 'nw';
                  if (index2 === 0) {
                    tempEdgecursor = 'nw';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 1) {
                    tempEdgecursor = 'ne';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 3) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                }
              }
            } else if (tempchildid === 3) {
              // tslint:disable-next-line: max-line-length
              if (Math.ceil(list2[1].x + (this.dvc.inputtexture.image.width / 2)) <= Math.ceil(list2[3].x + (this.dvc.inputtexture.image.width / 2))) {
                // dragged vertice is on the left side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[1].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[3].y + (this.dvc.inputtexture.image.height / 2)))  {
                  // dragged vertice is on the left top
                  tempEdgecursor = 'nw';
                  if (index2 === 1) {
                    tempEdgecursor = 'nw';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 2) {
                    tempEdgecursor = 'ne';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 0) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                } else {
                  // dragged vertice is on the left bottom
                  tempEdgecursor = 'ne';
                  if (index2 === 1) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 2) {
                    tempEdgecursor = 'nw';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                  }
                  if (index2 === 0) {
                    tempEdgecursor = 'nw';
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                }
              } else {
                // dragged vertice is on the right side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[1].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[3].y + (this.dvc.inputtexture.image.height / 2)))  {
                  // dragged vertice is on the right top
                  tempEdgecursor = 'ne';
                  if (index2 === 1) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 2) {
                    tempEdgecursor = 'nw';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                  }
                  if (index2 === 0) {
                    tempEdgecursor = 'nw';
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                } else {
                  // dragged vertice is on the right bottom
                  tempEdgecursor = 'nw';
                  if (index2 === 1) {
                    tempEdgecursor = 'nw';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 2) {
                    tempEdgecursor = 'ne';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 0) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                }
              }
            } else if (tempchildid === 4) {
              // tslint:disable-next-line: max-line-length
              if (Math.ceil(list2[2].x + (this.dvc.inputtexture.image.width / 2)) <= Math.ceil(list2[0].x + (this.dvc.inputtexture.image.width / 2)))  {
                // dragged vertice is on the left side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[2].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[0].y + (this.dvc.inputtexture.image.height / 2)))  {
                  // dragged vertice is on the left top
                  tempEdgecursor = 'nw';
                  if (index2 === 2) {
                    tempEdgecursor = 'nw';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 3) {
                    tempEdgecursor = 'ne';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 1) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                } else {
                  // dragged vertice is on the left bottom
                  tempEdgecursor = 'ne';
                  if (index2 === 2) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 3) {
                    tempEdgecursor = 'nw';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                  }
                  if (index2 === 1) {
                    tempEdgecursor = 'nw';
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                }
              } else {
                // dragged vertice is on the right side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[2].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[0].y + (this.dvc.inputtexture.image.height / 2)))  {
                  // dragged vertice is on the right top
                  tempEdgecursor = 'ne';
                  if (index2 === 2) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 3) {
                    tempEdgecursor = 'nw';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                  }
                  if (index2 === 1) {
                    tempEdgecursor = 'nw';
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                } else {
                  // dragged vertice is on the right bottom
                  tempEdgecursor = 'nw';
                  if (index2 === 2) {
                    tempEdgecursor = 'nw';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 3) {
                    tempEdgecursor = 'ne';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 1) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                }
              }
            } else if (tempchildid === 5) {
              // tslint:disable-next-line: max-line-length
              if (Math.ceil(list2[3].x + (this.dvc.inputtexture.image.width / 2)) <= Math.ceil(list2[1].x + (this.dvc.inputtexture.image.width / 2))) {
                // dragged vertice is on the left side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[3].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[1].y + (this.dvc.inputtexture.image.height / 2)))  {
                  // dragged vertice is on the left top
                  tempEdgecursor = 'nw';
                  if (index2 === 3) {
                    tempEdgecursor = 'nw';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 0) {
                    tempEdgecursor = 'ne';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 2) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                } else {
                  // dragged vertice is on the left bottom
                  tempEdgecursor = 'ne';
                  if (index2 === 3) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 0) {
                    tempEdgecursor = 'nw';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                  }
                  if (index2 === 2) {
                    tempEdgecursor = 'nw';
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                }
              } else {
                // dragged vertice is on the right side
                // tslint:disable-next-line: max-line-length
                if (Math.ceil(list2[3].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[1].y + (this.dvc.inputtexture.image.height / 2)))  {
                  // dragged vertice is on the right top
                  tempEdgecursor = 'ne';
                  if (index2 === 3) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 0) {
                    tempEdgecursor = 'nw';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                  }
                  if (index2 === 2) {
                    tempEdgecursor = 'nw';
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                } else {
                  // dragged vertice is on the right bottom
                  tempEdgecursor = 'nw';
                  if (index2 === 3) {
                    tempEdgecursor = 'nw';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 0) {
                    tempEdgecursor = 'ne';
                    // element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                  if (index2 === 2) {
                    tempEdgecursor = 'ne';
                    element.x = element.x - (this.startboxdetectionmarker.startX - posx);
                    // element.y = element.y - (this.startboxdetectionmarker.startY - posy);
                  }
                }
              }
            }

            const cubeEdge = new THREE.Mesh( cornercubeGeometry, cornercubeMaterial );

            if (element.x < Math.ceil(-1 * this.dvc.inputtexture.image.width / 2)) {
              element.x =  Math.ceil(-1 * this.dvc.inputtexture.image.width / 2);
            }
            if (element.x > Math.floor(1 * this.dvc.inputtexture.image.width / 2)) {
              element.x = Math.floor(1 * this.dvc.inputtexture.image.width / 2);
            }
            if (element.y < Math.ceil(-1 * this.dvc.inputtexture.image.height / 2)) {
              element.y =  Math.ceil(-1 * this.dvc.inputtexture.image.height / 2);
            }
            if (element.y > Math.floor(1 * this.dvc.inputtexture.image.height / 2)) {
              element.y = Math.floor(1 * this.dvc.inputtexture.image.height / 2);
            }

            cubeEdge.position.x = element.x;
            cubeEdge.position.y = element.y;
            cubeEdge.position.z = 0.51;
            if (tempEdgecursor === 'nw') {
              cubeEdge.name = 'cornercube_nw';
            } else {
              cubeEdge.name = 'cornercube_ne';
            }

            this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].add( cubeEdge );
          });
          this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.verticesNeedUpdate = true;
          this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.normalsNeedUpdate = true;
          this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.computeFaceNormals();
          this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.computeVertexNormals();
          this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.computeBoundingSphere();
          this.startboxdetectionmarker.startX = posx;
          this.startboxdetectionmarker.startY = posy;

          _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
            item.name = 'box_' + index;
            this.dvc.scene.add( item );
          });

          this.webgl.render();
          this.dvc.changesmade = true;
        }
      };

      const movebox = (posx: number, posy: number) => {

        // tslint:disable-next-line: max-line-length
        this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children = _.filter(this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children, (child) => child.name !== 'cornercube_ne');
        // tslint:disable-next-line: max-line-length
        this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children = _.filter(this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children, (child) => child.name !== 'cornercube_nw');

        const cornercubeGeometry = new THREE.BoxGeometry( 10, 10, 1 );
        const cornercubeMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000} );

        // tslint:disable-next-line: max-line-length
        _.each(this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.vertices, (element, index2: number, list2) => {
          let compareindex = 0;
          if (index2 === 0) {
            compareindex = 2;
          } else if (index2 === 1) {
            compareindex = 3;
          } else if (index2 === 2) {
            compareindex = 0;
          } else if (index2 === 3) {
            compareindex = 1;
          }

          let tempEdgecursor = 'ne';
          // tslint:disable-next-line: max-line-length
          if (Math.ceil(list2[index2].x + (this.dvc.inputtexture.image.width / 2)) <= Math.ceil(list2[compareindex].x + (this.dvc.inputtexture.image.width / 2))) {
            // dragged vertice is on the left side
            // tslint:disable-next-line: max-line-length
            if (Math.ceil(list2[index2].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[compareindex].y + (this.dvc.inputtexture.image.height / 2))) {
              // dragged vertice is on the left top
              tempEdgecursor = 'nw';
            } else {
              // dragged vertice is on the left bottom
              tempEdgecursor = 'ne';
            }
          } else {
            // dragged vertice is on the right side
            // tslint:disable-next-line: max-line-length
            if (Math.ceil(list2[index2].y + (this.dvc.inputtexture.image.height / 2)) >= Math.ceil(list2[compareindex].y + (this.dvc.inputtexture.image.height / 2)))  {
              // dragged vertice is on the right top
              tempEdgecursor = 'ne';
            } else {
              // dragged vertice is on the right bottom
              tempEdgecursor = 'nw';
            }
          }

          element.x = element.x - (this.startboxdetectionmarker.startX - posx);
          element.y = element.y - (this.startboxdetectionmarker.startY - posy);
          const cubeEdge = new THREE.Mesh( cornercubeGeometry, cornercubeMaterial );
          if (element.x < Math.ceil(-1 * this.dvc.inputtexture.image.width / 2)) {
              element.x =  Math.ceil(-1 * this.dvc.inputtexture.image.width / 2);
          }
          if (element.x > Math.floor(1 * this.dvc.inputtexture.image.width / 2)) {
              element.x = Math.floor(1 * this.dvc.inputtexture.image.width / 2);
          }
          if (element.y < Math.ceil(-1 * this.dvc.inputtexture.image.height / 2)) {
              element.y =  Math.ceil(-1 * this.dvc.inputtexture.image.height / 2);
          }
          if (element.y > Math.floor(1 * this.dvc.inputtexture.image.height / 2)) {
              element.y = Math.floor(1 * this.dvc.inputtexture.image.height / 2);
          }
          cubeEdge.position.x = element.x;
          cubeEdge.position.y = element.y;
          cubeEdge.position.z = 0.5;
          if (tempEdgecursor === 'nw') {
            cubeEdge.name = 'cornercube_nw';
          } else {
            cubeEdge.name = 'cornercube_ne';
          }

          this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].add( cubeEdge );
        });
        this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.verticesNeedUpdate = true;
        this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.normalsNeedUpdate = true;
        this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.computeFaceNormals();
        this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.computeVertexNormals();
        this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry.computeBoundingSphere();
        this.startboxdetectionmarker.startX = posx;
        this.startboxdetectionmarker.startY = posy;

        _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
          item.name = 'box_' + index;
          this.dvc.scene.add( item );
        });

        this.webgl.render();
        this.dvc.changesmade = true;
      };

      // calculate objects intersecting the picking ray
      const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children, true);
      if (intersects.length > 0 ) {
        const posx = Math.ceil(intersects[0].point.x);
        const posy = Math.ceil(intersects[0].point.y);
        let editboxdetected = false;

        for (const intersect of intersects) {
          if (intersect.object.name !== '') {
            if (intersect.object.name === 'cornercube_ne') {
              mapcanvasContainer.nativeElement.style.cursor = 'ne-resize';
              if (this.moveboxactive) {
                this.movetype = 'resize';
              }
            } else if (intersect.object.name === 'cornercube_nw') {
              mapcanvasContainer.nativeElement.style.cursor = 'nw-resize';
              if (this.moveboxactive) {
                this.movetype = 'resize';
              }
            } else if (intersect.object.name === 'box_selected') {
              mapcanvasContainer.nativeElement.style.cursor = 'move';
              if (this.moveboxactive) {
                if (this.movetype !== 'resize') {
                  this.movetype = 'move';
                }
              }
            } else if (intersect.object.name === 'boxwireframe_selected') {
              mapcanvasContainer.nativeElement.style.cursor = 'move';
              if (this.moveboxactive) {
                if (this.movetype !== 'resize') {
                  this.movetype = 'move';
                }
              }
            } else {
              mapcanvasContainer.nativeElement.style.cursor = 'crosshair';
            }

            editboxdetected = true;
          }
        }

        if (this.moveboxactive) {
          if (this.movetype === 'move') {
            this.logger.debug('MOVEBOX');
            movebox(posx, posy);
          }
          if (this.movetype === 'resize') {
            this.logger.debug('RESIZEBOX');
            resizebox(posx, posy);
          }
        }
        if (!editboxdetected) {
          mapcanvasContainer.nativeElement.style.cursor = 'crosshair';
        }

      } else {
        mapcanvasContainer.nativeElement.style.cursor = 'crosshair';
      }
    }

    if (this.drawmode === 'removebox') {
      this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
      this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;
      // update the picking ray with the camera and mouse position
      this.mapService.raycaster.setFromCamera(this.mapService.mouse, this.mapService.camera);

      const intersects = this.mapService.raycaster.intersectObjects(this.dvc.scene.children, true);
      // this.logger.debug(intersects);
      if (intersects.length > 1) {
        const posx = Math.ceil(intersects[0].point.x);
        const posy = Math.ceil(intersects[0].point.y);

        for (const intersect of intersects) {
          if (intersect.object.name !== '') {
            if (intersect.object.name === 'box') {
              // $('#maincanvas > canvas').css('cursor', 'pointer');
            } else if (intersect.object.name === 'boxwireframe') {
              // $('#maincanvas > canvas').css('cursor', 'pointer');
            } else {
              // $('#maincanvas > canvas').css('cursor', 'crosshair');
            }
          }
        }
      } else {
        // $('#maincanvas > canvas').css('cursor', 'crosshair');
      }
    }

    if ((this.drawmodeActive === true) && (this.drawmode === 'addbox')) {
      this.mapService.mouse.x = ( event.offsetX / mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
      this.mapService.mouse.y = - ( event.offsetY  / mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;
      // update the picking ray with the camera and mouse position
      this.mapService.raycaster.setFromCamera( this.mapService.mouse, this.mapService.camera );

      // calculate objects intersecting the picking ray
      const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children );
      // console.timeEnd("intersect");
      if ((intersects.length > 0 ) && (this.drawmodeActive === true)) {

        let posx = Math.ceil(intersects[0].point.x);
        let posy = Math.ceil(intersects[0].point.y);
        if (posx < Math.ceil(-1 * this.dvc.inputtexture.image.width / 2)) {
            posx =  Math.ceil(-1 * this.dvc.inputtexture.image.width / 2);
        }
        if (posx > Math.floor(1 * this.dvc.inputtexture.image.width / 2)) {
            posx = Math.floor(1 * this.dvc.inputtexture.image.width / 2);
        }
        if (posy < Math.ceil(-1 * this.dvc.inputtexture.image.height / 2)) {
            posy =  Math.ceil(-1 * this.dvc.inputtexture.image.height / 2);
        }
        if (posy > Math.floor(1 * this.dvc.inputtexture.image.height / 2)) {
            posy = Math.floor(1 * this.dvc.inputtexture.image.height / 2);
        }
        // transform position from centered coordinates to left corner coordinates
        // posx = Math.ceil(posx + (this.dvc.inputtexture.image.width / 2));
        // posy = Math.ceil(posy + (this.dvc.inputtexture.image.height / 2));

        // check for minimum box size
        // tslint:disable-next-line: max-line-length
        if ((Math.abs(posx - this.startboxdetectionmarker.startX) > 5) && ((Math.abs(posx - this.startboxdetectionmarker.startX) > 5))) {

          const boxShape = new THREE.Shape();
          this.logger.debug(this.dvc.activelayerindex);
          this.logger.debug(this.dvc.activelayercolor);

          boxShape.moveTo( this.startboxdetectionmarker.startX, this.startboxdetectionmarker.startY);
          // tslint:disable-next-line: max-line-length
          // boxShape.bezierCurveTo( this.startboxdetectionmarker.startX, this.startboxdetectionmarker.startY, 1, this.startboxdetectionmarker.startX, posy, 1);
          // tslint:disable-next-line: max-line-length
          // boxShape.bezierCurveTo( this.startboxdetectionmarker.startX, this.startboxdetectionmarker.startY, 1, posx, this.startboxdetectionmarker.startX, 1);
          // boxShape.bezierCurveTo( this.startboxdetectionmarker.startX, posy, 1, posx, posy, 1);
          // boxShape.bezierCurveTo( posx, this.startboxdetectionmarker.startX, 1, posx, posy, 1);
          boxShape.lineTo( posx, this.startboxdetectionmarker.startY);
          boxShape.lineTo( posx, posy);
          boxShape.lineTo( this.startboxdetectionmarker.startX, posy);
          boxShape.lineTo( this.startboxdetectionmarker.startX, this.startboxdetectionmarker.startY);


          const geometry = new THREE.ShapeGeometry( boxShape );
          // tslint:disable-next-line: max-line-length
          const material = new THREE.MeshBasicMaterial( { color: parseInt('0x' + this.dvc.activelayercolor.substr(1), 16), wireframe: true, transparent: true, opacity: 1.0} );
          const mesh = new THREE.Mesh( geometry, material ) ;
          mesh.name = 'boxwireframe';
          // tslint:disable-next-line: max-line-length
          const material2 = new THREE.MeshBasicMaterial( { color: parseInt('0x' + this.dvc.activelayercolor.substr(1), 16), transparent: true, opacity: this.dvc.pages[0].layers[this.dvc.activelayerindex].plane.material.opacity } );
          const mesh2 = new THREE.Mesh( geometry, material2 ) ;
          mesh2.name = 'box';
          if (this.currentboxdetectionindex < 0) {
            const group = new WolGroup();
            group.add(mesh);
            group.add(mesh2);
            group.classId = this.dvc.pages[0].layers[this.dvc.activelayerindex].classId;
            // this.dvc.boxdetectionmarker.push(mesh);
            // this.dvc.boxdetectionmarker.push(mesh2);
            this.dvc.boxdetectionmarker.push(group);
            this.currentboxdetectionindex = this.dvc.boxdetectionmarker.length;
          } else {
            this.logger.debug('update');
            this.logger.debug(this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1]);
            this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[0].geometry = geometry;
            this.dvc.boxdetectionmarker[this.currentboxdetectionindex - 1].children[1].geometry = geometry;
            // this.dvc.boxdetectionmarker[this.currentboxdetectionindex-2].geometry = geometry;
            // this.dvc.boxdetectionmarker[this.currentboxdetectionindex-1].geometry = geometry;
          }

          _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
            item.name = 'box_' + index;
            this.dvc.scene.add( item );
          });

          this.webgl.render();
          this.dvc.changesmade = true;
        }
      }
    }
  }

  // execute Draw
  public executeDraw(targetX: number , targetY: number) {
    if (this.dvc.pages[0].layers[this.dvc.activelayerindex].label) {
      targetY = Math.ceil(targetY - 1);
      targetX = Math.ceil(targetX - 1);

      if (this.dvc.datasettype === 'segmentation') {
        if ((this.drawmode === 'magicwand') || (this.drawmode === 'fillpaint')) {
          this.dvc.changesmade = true;
          let originalimageLayerid = 0;

          _.each(this.dvc.pages[0].layers, (layer, index: number, list) => {
            if (layer.label === false) {
              originalimageLayerid = index;
            }
          });

          let startR: number;
          let startG: number;
          let startB: number;
          let currenttolerance: number;

          if (this.drawmode === 'fillpaint') {
            // tslint:disable-next-line: max-line-length
            startR = this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * targetY) + targetX) * 4)];
            // tslint:disable-next-line: max-line-length
            startG = this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * targetY) + targetX) * 4) + 1];
            // tslint:disable-next-line: max-line-length
            startB = this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * targetY) + targetX) * 4) + 2];
            currenttolerance = 0;
          } else if (this.drawmode === 'magicwand') {
            startR = this.dvc.inputtexture.image.data[(((this.dvc.inputtexture.image.width * targetY) + targetX) * 4)];
            startG = this.dvc.inputtexture.image.data[(((this.dvc.inputtexture.image.width * targetY) + targetX) * 4) + 1];
            startB = this.dvc.inputtexture.image.data[(((this.dvc.inputtexture.image.width * targetY) + targetX) * 4) + 2];
            currenttolerance = this.magicwandTolerance;
          }

          const matchStartColor = (pixelPos2: number) => {
            let r: number;
            let g: number;
            let b: number;

            if (this.drawmode === 'fillpaint') {
              r = this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2];
              g = this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2 + 1];
              b = this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2 + 2];
            } else if (this.drawmode === 'magicwand') {
              r = this.dvc.inputtexture.image.data[pixelPos2];
              g = this.dvc.inputtexture.image.data[pixelPos2 + 1];
              b = this.dvc.inputtexture.image.data[pixelPos2 + 2];
            }

            // tslint:disable-next-line: max-line-length
            if ((this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2] === this.dvc.pages[0].layers[this.dvc.activelayerindex].color_red) &&
              // tslint:disable-next-line: max-line-length
              (this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2 + 1] === this.dvc.pages[0].layers[this.dvc.activelayerindex].color_green) &&
              // tslint:disable-next-line: max-line-length
              (this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2 + 2] === this.dvc.pages[0].layers[this.dvc.activelayerindex].color_blue)) {
                return false;
              }
            // tslint:disable-next-line: max-line-length
            if ((Math.abs(r - startR) <=  currenttolerance) && (Math.abs(g - startG) <=  currenttolerance) && (Math.abs(b - startB) <=  currenttolerance)) {
              return true;
            }
          };

          const colorPixel = (pixelPos2: number) => {
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_red;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2 + 1] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_green;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2 + 2] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_blue;
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[pixelPos2 + 3] = 255;
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.needsUpdate = true;
          };

          const pixelStack = [[targetX, targetY]];
          let newPos: any[];
          let x: number;
          let y: number;
          let pixelPos: number;
          let reachLeft: boolean;
          let reachRight: boolean;
          const drawingBoundLeft = 0;
          const drawingBoundTop = 0;
          const drawingBoundRight = this.dvc.inputtexture.image.width - 1;
          const drawingBoundBottom = this.dvc.inputtexture.image.height - 1;

          while (pixelStack.length) {
            newPos = pixelStack.pop();
            x = newPos[0];
            y = newPos[1];

            pixelPos = (y * this.dvc.inputtexture.image.width + x) * 4;
            while (y-- >= drawingBoundTop && matchStartColor(pixelPos)) {
              pixelPos -= this.dvc.inputtexture.image.width * 4;
            }
            pixelPos += this.dvc.inputtexture.image.width * 4;
            ++y;
            reachLeft = false;
            reachRight = false;
            while (y++ < this.dvc.inputtexture.image.height - 1 && matchStartColor(pixelPos)) {
              colorPixel(pixelPos);

              if (x > 0) {
                if (matchStartColor(pixelPos - 4)) {
                  if (!reachLeft) {
                    pixelStack.push([x - 1, y]);
                    reachLeft = true;
                  }
                } else if (reachLeft) {
                  reachLeft = false;
                }
              }

              if (x < this.dvc.inputtexture.image.width - 1) {
                if (matchStartColor(pixelPos + 4)) {
                  if (!reachRight) {
                    pixelStack.push([x + 1, y]);
                    reachRight = true;
                  }
                } else if (reachRight) {
                  reachRight = false;
                }
              }

              pixelPos += this.dvc.inputtexture.image.width * 4;
            }
          }
          if ((this.magicwandContiguous) || (this.drawmode === 'fillpaint')) {
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.needsUpdate = true;
          } else {
            for (let y2 = 0; y2 < this.dvc.inputtexture.image.height; y2++) {
              // loop through each column
              for (let x2 = 0; x2 < this.dvc.inputtexture.image.width; x2++) {
                pixelPos = (y2 * this.dvc.inputtexture.image.width + x2) * 4;
                if (matchStartColor(pixelPos)) {
                  colorPixel(pixelPos);
                }
              }
            }
          }


        } else if ((this.drawmode === 'pencil') || (this.drawmode === 'eraser')) {
          let startX: number;
          let endX: number;
          let startY: number;
          let endY: number;
          let x: number;
          let y: number;

          this.dvc.changesmade = true;

          if ((targetX - (this.linewidth / 2)) < 0) {
            startX = 0;
            startX = Math.round((targetX - (this.linewidth / 2)));
          } else {
            startX = Math.round((targetX - (this.linewidth / 2)));
          }
          if ((targetX + (this.linewidth / 2)) > this.dvc.inputtexture.image.width) {
            endX = this.dvc.inputtexture.image.width;
          } else {
            endX = Math.round((targetX + (this.linewidth / 2)));
          }

          if ((targetY - (this.linewidth / 2)) < 0) {
            startY = 0;
            startY = Math.round((targetY - (this.linewidth / 2)));
          } else {
            startY = Math.round((targetY - (this.linewidth / 2)));
          }
          if ((targetY + (this.linewidth / 2)) > this.dvc.inputtexture.image.height) {
            endY = this.dvc.inputtexture.image.height;
          } else {
            endY = Math.round((targetY + (this.linewidth / 2)));
          }

          // this.logger.debug(startX + ':' + endX + '   ' + startY + ':' + endY)

          // console.time("setcircle");
          // SET Circle
          if (this.linewidth === 1) {
            y = startY;
            x = startX;

            if (x < 0) {
              x = 0;
            }
            if (y < 0) {
              y = 0;
            }
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_red;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_green;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_blue;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
          } else {
            if (this.drawmode === 'pencil') {
              for (let y2 = startY; y2 < endY; y2++) {
                // loop through each column
                for (let x2 = startX; x2 < endX; x2++) {
                  if ((x2 >= 0) && (y2 >= 0)) {
                    if (this.penciltipsService.drawpatternsCircle[this.linewidth][x2 - startX][y2 - startY] === 1) {
                      // tslint:disable-next-line: max-line-length
                      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y2) + x2) * 4)] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_red;
                      // tslint:disable-next-line: max-line-length
                      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y2) + x2) * 4) + 1] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_green;
                      // tslint:disable-next-line: max-line-length
                      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y2) + x2) * 4) + 2] = this.dvc.pages[0].layers[this.dvc.activelayerindex].color_blue;
                      // tslint:disable-next-line: max-line-length
                      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y2) + x2) * 4) + 3] = 255;
                      this.webgl.render();
                    }
                  }
                }
              }
            }

            if (this.drawmode === 'eraser') {
              for (let y2 = startY; y2 < endY; y2++) {
                // loop through each column
                for (let x2 = startX; x2 < endX; x2++) {
                  if ((x2 >= 0) && (y2 >= 0)) {
                    if (this.penciltipsService.drawpatternsCircle[this.linewidth][x2 - startX][y2 - startY] === 1) {
                      // tslint:disable-next-line: max-line-length
                      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y2) + x2) * 4)] = 255;
                      // tslint:disable-next-line: max-line-length
                      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y2) + x2) * 4) + 1] = 255;
                      // tslint:disable-next-line: max-line-length
                      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y2) + x2) * 4) + 2] = 255;
                      // tslint:disable-next-line: max-line-length
                      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y2) + x2) * 4) + 3] = 0;
                      this.webgl.render();
                    }
                  }
                }
              }
            }

          }
        }

      }

      // console.timeEnd("setcircle");

      // this.dvc.pages[0].layers[1].texture.needsUpdate = true;
      this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.needsUpdate = true;
    }
  }

  public drawBoxdetectionMarker(index) {
    if (index < 0) {
      index = this.dvc.activelayerindex;
    }

    _.each(this.dvc.boxdetectionmarker, (item) => {
      this.dvc.scene.add( item );
    });
  }

  public changeColorchannel(channel) {
    this.logger.debug(['change Colorchannel LabeltoolsService: ', channel]);
    _.each(this.dvc.pages[0].layers, (layer, index, list) => {
      if (layer.name === 'Original Image') {
        this.dvc.loadingUpdate(true);
        const inputimage = new Image();
        inputimage.onload = () => {
          let canvas: any;
          // createcanvas for layerdrawing
          if (canvas) {
            canvas.remove();
          }
          canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          canvas.width = this.dvc.inputtexture.image.width;
          canvas.height = this.dvc.inputtexture.image.height;

          // bild an der x-achse spiegeln um unterschiedliche Korrdinatesystem in 2dcanvas und webgl zu berücksichtigen
          context.translate(0, canvas.height);
          context.scale(1, -1);

          context.clearRect(0, 0, canvas.width, canvas.height);
          context.drawImage(inputimage, 0, 0);

          // tslint:disable-next-line: max-line-length
          this.dvc.inputtexture = new THREE.DataTexture(new Uint8Array(context.getImageData(0, 0, inputimage.width, inputimage.height).data.buffer), inputimage.width, inputimage.height, THREE.RGBAFormat);
          this.dvc.inputtexture.type = THREE.UnsignedByteType;
          this.dvc.inputtexture.minFilter = THREE.NearestFilter;
          this.dvc.inputtexture.magFilter = THREE.NearestFilter;
          this.dvc.inputtexture.needsUpdate = true;

          _.times(layer.texture.image.height, (y) => {
            _.times(layer.texture.image.width, (x) => {
              // tslint:disable-next-line: max-line-length
              layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 0] = this.dvc.inputtexture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 0];
              // tslint:disable-next-line: max-line-length
              layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = this.dvc.inputtexture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1];
              // tslint:disable-next-line: max-line-length
              layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = this.dvc.inputtexture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2];
              layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
            });
          });

          if (channel === 'red'){
            _.times(layer.texture.image.height, (y) => {
              _.times(layer.texture.image.width, (x) => {
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = 0;
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = 0;
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
              });
            });
          }else if (channel === 'green') {
            _.times(layer.texture.image.height, (y) => {
              _.times(layer.texture.image.width, (x) => {
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = 0;
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = 0;
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
              });
            });
          }else if (channel === 'blue') {
            _.times(layer.texture.image.height, (y) => {
              _.times(layer.texture.image.width, (x) => {
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = 0;
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = 0;
                layer.texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
              });
            });
          }

          this.dvc.loadingUpdate(false);
          layer.texture.needsUpdate = true;
          this.webgl.render('force');
        };
        inputimage.crossOrigin = '';
        inputimage.src = this.dvc.dataobject1.path;

        this.webgl.render();
      }
    });
  }

  public recolor(layerindex) {
    if (this.dvc.datasettype === 'segmentation') {
      // this.logger.debug(['recolor called:', layerindex, 'segmentation']);
      if (this.dvc.pages[0].layers[layerindex].label) {
        for (let y = 0; y < this.dvc.inputtexture.image.height; y++) {
          // loop through each column
          for (let x = 0; x < this.dvc.inputtexture.image.width; x++) {
            // tslint:disable-next-line: max-line-length
            if (((this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 0] < 255) || (this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] < 255) || (this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] < 255)) && (this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] > 0)) {
              // tslint:disable-next-line: max-line-length
              this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = this.dvc.pages[0].layers[layerindex].color_red;
              // tslint:disable-next-line: max-line-length
              this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = this.dvc.pages[0].layers[layerindex].color_green;
              // tslint:disable-next-line: max-line-length
              this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = this.dvc.pages[0].layers[layerindex].color_blue;
              this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
            } else {
              this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = 255;
              this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = 255;
              this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = 255;
              this.dvc.pages[0].layers[layerindex].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 0;
            }
            this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.needsUpdate = true;
            this.webgl.render();

          }
        }
        setTimeout(() => {
          this.dvc.pages[0].layers[this.dvc.activelayerindex].texture.needsUpdate = true;
          this.webgl.render();
        }, 750);
      }
    }else if (this.dvc.datasettype === 'boxdetection') {
      this.logger.debug(['recolor called:', layerindex, 'boxdetection', this.dvc.modusSource.value]);
      const tempSelectedgroup = -1;

      _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
        let tempcolor = '0x00ff00';
        if (this.dvc.modusSource.value === 'trainedalgorithm') {
          _.each(this.dvc.pages[0].layers, (element, index2, list2) => {
            if ((element.classId === item.classId) && (element.trained === item.trained)) {
              // tslint:disable-next-line: max-line-length
              tempcolor = '0x' + this.dvc.decimalToHex(element.color_red) + this.dvc.decimalToHex(element.color_green) + this.dvc.decimalToHex(element.color_blue);
            }
          });
        }else{
          _.each(this.dvc.pages[0].layers, (element, index2, list2) => {
            if (element.classId === item.classId) {
              // tslint:disable-next-line: max-line-length
              tempcolor = '0x' + this.dvc.decimalToHex(element.color_red) + this.dvc.decimalToHex(element.color_green) + this.dvc.decimalToHex(element.color_blue);
            }
          });
        }

        item.children[0].material.color.setHex(parseInt(tempcolor, 16));
        item.children[1].material.color.setHex(parseInt(tempcolor, 16));
        if (item.children[0].name === 'box_selected') {
          item.children[0].name = 'box';
        }
        if (item.children[0].name === 'boxwireframe_selected') {
          item.children[0].name = 'boxwireframe';
        }
        if (item.children[1].name === 'box_selected') {
          item.children[1].name = 'box';
        }
        if (item.children[1].name === 'boxwireframe_selected') {
          item.children[1].name = 'boxwireframe';
        }

        item.children = _.filter(item.children, (child) => child.name !== 'cornercube_ne');
        item.children = _.filter(item.children, (child) => child.name !== 'cornercube_nw');
      });

      _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
        this.dvc.scene.remove( item );
        this.dvc.scene.add( item );
      });
      this.webgl.render('force');
    }
  }

  public clearlayer(index) {
    this.savelayerstatus('undo');
    this.logger.debug('clearlayer: ' + index);
    this.dvc.changesmade = true;

    if (this.dvc.datasettype === 'segmentation') {
      if (this.dvc.pages[0].layers[index].label === true) {
        // tslint:disable-next-line: max-line-length
        this.dvc.pages[0].layers[index].texture.image.data = new Uint8Array(this.dvc.pages[0].layers[index].texture.image.data.length);
      }
      this.dvc.pages[0].layers[index].texture.needsUpdate = true;
      this.webgl.render('force');
    }
    if (this.dvc.datasettype === 'boxdetection') {
      this.dvc.boxdetectionmarker = _.filter(this.dvc.boxdetectionmarker, (item) => {
        this.logger.debug(item);
        if (item.classId === this.dvc.pages[0].layers[index].classId) {
          this.dvc.scene.remove( item );
          return false;
        } else {
          return true;
        }
      });
      this.webgl.render('force');
    }
  }

  public clearcanvas() {
    this.logger.debug('CLEAR CANVAS');

    if (this.dvc.datasettype === 'boxdetection') {
      _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
        this.dvc.scene.remove( item );
      });
      this.dvc.boxdetectionmarker = [];
      _.each(this.dvc.pages[0].layers, (layer, index, list) => {
        if (layer.label === true) {
          this.logger.debug(index);
          this.drawBoxdetectionMarker(index);
        }
      });
    }

    if (this.dvc.datasettype === 'segmentation') {
      _.each(this.dvc.pages[0].layers, (layer, index, list) => {
        if (layer.label === true) {
          layer.texture.image.data = new Uint8Array(layer.texture.image.data.length);
        }
        layer.texture.needsUpdate = true;
      });
    }
    this.webgl.render();
  }

  public savelayerstatus(type: string) {

    if (type === 'undo') {
      // this.logger.debug('saveundo');
      this.dvc.redoavailable = false;
      this.dvc.undoavailable = true;
    } else if (type === 'redo') {
      // this.logger.debug('saveredo');
      this.dvc.redoavailable = true;
      this.dvc.undoavailable = false;
    }

    if (this.dvc.datasettype === 'segmentation') {
      const originalW = this.dvc.inputtexture.image.width;
      const originalH = this.dvc.inputtexture.image.height;
      let imagearray = new Array();
      for (let y = 0; y < originalH; y++) {
        imagearray[y] = new Uint8Array(originalW);
        // loop through each column
        for (let x = 0; x < originalW; x++) {
          imagearray[y][x] = 0;
        }
      }

      _.each(this.dvc.pages[0].layers, (layer, index, list) => {
        if (layer.label) {
          const data = layer.texture.image.data;

          for (let y = 0; y < originalH; y++) {
            // loop through each column
            for (let x = 0; x < originalW; x++) {
              // tslint:disable-next-line: max-line-length
              if (((data[(((originalW * (originalH - y - 1)) + x) * 4) + 0] < 255) || (data[(((originalW * (originalH - y - 1)) + x) * 4) + 1] < 255) || (data[(((originalW * (originalH - y - 1)) + x) * 4) + 2] < 255)) && (data[(((originalW * (originalH - y - 1)) + x) * 4) + 3] > 0)) {
                imagearray[y][x] = layer.classId;
              }
            }
          }
        }

      });
      this.undoredolabel = imagearray;
      imagearray = null;
    }
  }

  public loadlayerstatus(type) {
    this.dvc.loadingUpdate(true);
    let data = new Array();
    _.each(this.undoredolabel, (labelelement, index, list) => {
      data[index] = labelelement;
    });

    if (type === 'redo') {
      this.savelayerstatus('undo');
    } else if (type === 'undo') {
      this.savelayerstatus('redo');
    }

    this.clearcanvas();

    const classidlist = [];

    _.each(this.dvc.pages[0].layers, (layer, index, list) => {
      this.logger.debug(layer);
      layer.classId = parseInt(layer.classId, 10);
      classidlist.push(_.findIndex(this.dvc.pages[0].layers, {classId: (index + 1)}));
    });

    this.logger.debug(classidlist);

    if (this.dvc.datasettype === 'segmentation') {
      this.logger.debug('segmentation');
      for (let y = 0; y < this.dvc.inputtexture.image.height; y++) {
        // loop through each column

        for (let x = 0; x < this.dvc.inputtexture.image.width; x++) {
          if (data[(this.dvc.inputtexture.image.height - y - 1)][x] > 0) {
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[classidlist[(data[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = this.dvc.pages[0].layers[classidlist[(data[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_red;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[classidlist[(data[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = this.dvc.pages[0].layers[classidlist[(data[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_green;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[classidlist[(data[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = this.dvc.pages[0].layers[classidlist[(data[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_blue;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[classidlist[(data[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
            this.webgl.render();
          }
        }
      }
    }

    _.each(this.dvc.pages[0].layers, (layer, index, list) => {
      layer.texture.needsUpdate = true;
    });

    data = null;

    this.dvc.loadingUpdate(false);
    this.webgl.render('force');
  }

  public clickclearcanvas() {
    this.dvc.checkunsavedlabelchanges((result) => {
      if (result === true) {
        this.savelayerstatus('undo');
        this.dvc.changesmade = true;
        this.clearcanvas();
      } else {
        this.logger.debug('discard changes denied');
      }
    });
  }

  public undoLabel() {
    this.logger.debug('undoLabel');
    if (this.dvc.undoavailable) {
      this.loadlayerstatus('undo');
    }
  }

  public redoLabel() {
    this.logger.debug('redoLabel');
    if (this.dvc.redoavailable) {
      this.loadlayerstatus('redo');
    }
  }

  // tslint:disable-next-line: max-line-length
  constructor(
    public dvc: DataviewerControllerService,
    public penciltipsService: PenciltipsService,
    public mapService: MapService,
    private webgl: WebglService,
    private logger: NGXLogger
  ) { }
}
