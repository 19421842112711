<div class="row" style="background-color: #fff;border-bottom:2px solid #0366a8;margin:0px; margin-top:-35px;">
  <div class="col-md-5" style="padding-left:150px;height: 35px; min-width:180px; background-position:15px center; background-repeat:no-repeat; background-size: 131px; background-image: url('/assets/images/wolution_161.png');">
    <span style="color:#666666;display: block; margin-top: 7px;">StatisticsViewer</span>
  </div>
  <div class="col-md-6" style="padding:16px 0px; white-space: nowrap; padding-bottom: 2px; font-size: 0.9em;text-overflow: ellipsis;text-align:left;">
  </div>
  <div class="col-md-1" style="padding-right:0;">
    <div onmouseover="this.style.backgroundColor='#009eff';this.style.color='#ffffff';"
         onmouseout="this.style.backgroundColor='#ffffff';this.style.color='#000000';"
         (click)="svc.close()"
         style="float:right; background-color:#fff;padding:6px 11px;border-left:1px solid #ccc; border-bottom:0px solid #ccc;width: 35px;height: 35px; vertical-align: middle; text-align: center;">
      <fa-icon [icon]="['far', 'times']"></fa-icon>
    </div>
  </div>
</div>
