import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DataviewerRoutingModule } from './dataviewer-routing.module';
import { DataviewerComponent } from './dataviewer/dataviewer.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarLeftComponent } from './sidebar-left/sidebar-left.component';
import { SidebarRightComponent } from './sidebar-right/sidebar-right.component';
import { AngularCropperjsModule } from 'angular-cropperjs';
import { LayerlistComponent } from './layerlist/layerlist.component';
import { LayerlistItemComponent } from './layerlist-item/layerlist-item.component';
import { CommonmodalsComponent } from '../shared/components/commonmodals/commonmodals.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { ModalLayersettingsComponent } from './modal-layersettings/modal-layersettings.component';
import { HammerModule } from '@angular/platform-browser';


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [DataviewerComponent, HeaderComponent, FooterComponent, SidebarLeftComponent, SidebarRightComponent, LayerlistComponent, LayerlistItemComponent, ModalLayersettingsComponent],
  imports: [
    CommonModule,
    DataviewerRoutingModule,
    SharedModule,
    AngularCropperjsModule,
    ColorPickerModule,
    HammerModule
  ],
  exports: [
    DataviewerComponent
  ],
  entryComponents: [CommonmodalsComponent, ModalLayersettingsComponent]
})
export class DataviewerModule { }
