import { NGXLogger } from 'ngx-logger';
import { FiledownloaderControllerService } from 'src/app/shared/services/filedownloader-controller.service';
import { StatsviewerControllerService } from './../../shared/services/statsviewer-controller.service';
import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'wol-statsviewer',
  templateUrl: './statsviewer.component.html',
  styleUrls: ['./statsviewer.component.scss']
})
export class StatsviewerComponent implements AfterViewInit {

  public analysispath = '';
  public outputpath = '';
  public overlaypath = '';
  public contourspath = '';

  constructor(public svc: StatsviewerControllerService, public fdc: FiledownloaderControllerService, private logger: NGXLogger) { }

  ngAfterViewInit(): void {

    this.svc.visible.subscribe({next: visible => {
      this.logger.debug('statsviewer visible: ' + visible);

      if (!visible) {
        this.logger.debug('statsviewer closed');
      } else {
        this.logger.debug('statsviewer open');
        // tslint:disable-next-line: max-line-length
        const temppath = this.svc.job.output.substr(1, this.svc.job.output.lastIndexOf('/') - 1);
        this.analysispath = temppath.substr(0, temppath.lastIndexOf('/'));
        this.outputpath = this.analysispath + '/outputdata';
        this.overlaypath = this.analysispath + '/overlaydata';
        this.contourspath = this.analysispath + '/contoursdata';
      }
    }}
    );
  }

}
