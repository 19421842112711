<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    Select files for upload to your Filestorage. You can select multiple files!<br>
    <input style="margin-top:15px;" type="file" (change)="handleFileInput($event.target.files)" #file multiple/>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('cancel click')">cancel</button>
  <button type="button" class="btn btn-primary" ngbAutofocus (click)="activeModal.close(filesToUpload)">{{type}}</button>
</div>
