import { NGXLogger } from 'ngx-logger';
import { environment } from './../../../../environments/environment';
import { AccountService } from './../../services/account.service';
import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'wol-thumbnail',
  templateUrl: './thumbnail.component.html',
  styleUrls: ['./thumbnail.component.scss']
})
export class ThumbnailComponent implements OnChanges {

  @Input() filename: string;
  @Input() path: string;
  @Input() maxsize: number;
  @Input() owner: string;
  @Output() loadingstatus = new EventEmitter<boolean>();

  public environmentUrl = environment.apiurl;
  public loading = true;
  public imagesrc: string;
  public loadersize = 32;
  public loadermargin = 16;

  onLoad() {
    this.loading = false;
    this.loadingstatus.emit(this.loading);
  }

  onError() {
    this.logger.debug(['error loading thumbnail', this.imagesrc]);
    if (this.imagesrc !== '') {
      this.imagesrc = 'assets/images/noimage.jpg';
    }
    this.loadingstatus.emit(this.loading);
  }

  constructor(private logger: NGXLogger, public account: AccountService) { }

  ngOnChanges() {
    this.loading = true;
    this.loadingstatus.emit(this.loading);
    // tslint:disable-next-line: max-line-length
    if ((this.path === 'algorithmdata') || (this.path === 'trainedalgorithmdata') || (this.path === 'datasetdata')) {
      // tslint:disable-next-line: max-line-length
      this.imagesrc = this.environmentUrl + '/' + this.path + '/' + this.filename + '?_t=' + this.account.account.authtoken;
    } else {
      let userid = '';

      if (this.owner) {
        userid = this.owner;
      } else {
        userid = this.account.account._id;
      }

      // tslint:disable-next-line: max-line-length
      this.imagesrc = this.environmentUrl + '/' + this.path + '/' + userid + '/' + this.filename + '?_t=' + this.account.account.authtoken;
    }

    if (this.maxsize <= 32) {
      this.loadersize = 16;
      this.loadermargin = 8;
    } else if (this.maxsize <= 64) {
      this.loadersize = 32;
      this.loadermargin = 16;
    } else if (this.maxsize <= 128) {
      this.loadersize = 32;
      this.loadermargin = 48;
    } else {
      this.loadersize = 32;
      this.loadermargin = 50;
    }
  }

}
