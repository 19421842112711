import { NotificationStoreService } from 'src/app/shared/services/notification-store.service';
import { FileStoreService } from 'src/app/shared/services/file-store.service';
import { AccountService } from './account.service';
import { Router } from '@angular/router';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, delay } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { retry, map, catchError } from 'rxjs/operators';
import { ErrorhandlerService } from './errorhandler.service';
import { ThrowStmt } from '@angular/compiler';
import { CookieService } from 'ngx-cookie-service';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    private router: Router,
    private as: AccountService,
    private fs: FileStoreService,
    private ns: NotificationStoreService,
    private cookieService: CookieService
  ) { }

  isLoggedIn = false;
  loginresult: any[] = [];

  // store the URL so we can redirect after logging in
  redirectUrl: string;

  login(username: string, password: string): Observable<boolean> {
    return this.http.post<any>(
      `${environment.apiurl}/auth/signin`,
      { username: `${username}`, password: `${password}` }
      ).pipe(
        retry(3),
        map(res => {
          this.logger.debug(res);
          this.loginresult = res;
          return res;
        }),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  requestAccount(formdata: any): Observable<boolean> {
    return this.http.post<any[]>(
      `${environment.apiurl}/auth/request`,
      formdata
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  requestResetPassword(email: string): Observable<boolean> {
    return this.http.post<any[]>(
      `${environment.apiurl}/auth/resetpwd`,
      { email: `${email}`}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  pwdforgottenkeyCheck(key: string): Observable<boolean> {
    return this.http.post<any[]>(
      `${environment.apiurl}/auth/resetpwdkeycheck`,
      { resetkey: `${key}`}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  resetPassword(resetkey: string, newpwd: string): Observable<boolean> {
    return this.http.post<any[]>(
      `${environment.apiurl}/auth/resetpwdset`,
      {resetkey: `${resetkey}`, newpwd: `${newpwd}`}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  initAccountdata(): void {
    // check for used filespace
    this.fs.refreshspaceinfo().subscribe(
      (res1: any) => {
        this.logger.debug(res1);
        this.fs.spaceinfo = res1;
        this.as.account.useddiskspace = parseInt(res1.spaceused, 10);
      },
      (err) => {
        this.logger.debug(err);
      }
    );
    // check for notifications
    this.ns.getOwnNewCount().subscribe(res2 => {
      this.logger.debug(this.ns.ownNewNotificationsCount);
    });
  }

  logout(): void {
    this.isLoggedIn = false;
    this.as.account = undefined;
    this.cookieService.delete(environment.sessioncookiename);
    this.router.navigate(['/login']);
  }
}
