import { ToastmessageStoreService } from 'src/app/shared/services/toastmessage-store.service';
import { NGXLogger } from 'ngx-logger';
import { timeout } from 'q';
import { MinimapService } from './../minimap.service';
import { DataviewerControllerService } from './../../shared/services/dataviewer-controller.service';
import { Component, OnInit, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { LabeltoolsService } from '../labeltools.service';
import { CropperComponent } from 'angular-cropperjs';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColorPickerService } from 'ngx-color-picker';
import * as _ from 'lodash';

@Component({
  selector: 'wol-sidebar-right',
  templateUrl: './sidebar-right.component.html',
  styleUrls: ['./sidebar-right.component.scss']
})
export class SidebarRightComponent implements OnInit, AfterViewInit {

  @ViewChild('minimapcanvas') minimapcanvasContainer: ElementRef;
  @ViewChild('minimapAreaselect') minimapareaselect: CropperComponent;

  public opacityForm: FormGroup;
  public linewidthForm: FormGroup;
  public magicwandForm: FormGroup;
  public toggleColorpicker = false;

  onPan(event) {
    // console.log(event);
    this.minimapService.mouseHover = true;
  }

  public onEventLog(event: string, data: any): void {
    this.logger.debug(event, data);
  }

  public setOpacity = (opacity: number) => {
    if (opacity >= 0) {
      this.opacityForm.controls.opacityValue.setValue(opacity);
    } else {
      // this.dvc.resetview();
    }
  }

  public setColor = (mode) => {
    this.logger.debug('setColor');
    _.each(this.dvc.datasetclasses, (item) => {
      if (item.class_id === this.dvc.pages[0].layers[this.dvc.activelayerindex].classId) {
        item.color_red = parseInt(this.dvc.activelayercolor.substr(1, 2), 16);
        item.color_green = parseInt(this.dvc.activelayercolor.substr(3, 2), 16);
        item.color_blue = parseInt(this.dvc.activelayercolor.substr(5, 2), 16);
      }
    });
    if (mode === 'preview') {
      this.dvc.pages[0].layers[this.dvc.activelayerindex].color_red = parseInt(this.dvc.activelayercolor.substr(1, 2), 16);
      this.dvc.pages[0].layers[this.dvc.activelayerindex].color_green = parseInt(this.dvc.activelayercolor.substr(3, 2), 16);
      this.dvc.pages[0].layers[this.dvc.activelayerindex].color_blue = parseInt(this.dvc.activelayercolor.substr(5, 2), 16);
      this.labeltoolsService.recolor(this.dvc.activelayerindex);
    } else if (mode === 'save') {
      this.dvc.sendSaveLayersettings(JSON.stringify(this.dvc.datasetclasses), this.dvc.datasetid)
      .subscribe(
        (res: any) => {
          this.logger.debug(res);
          this.dvc.pages[0].layers[this.dvc.activelayerindex].color_red = parseInt(this.dvc.activelayercolor.substr(1, 2), 16);
          this.dvc.pages[0].layers[this.dvc.activelayerindex].color_green = parseInt(this.dvc.activelayercolor.substr(3, 2), 16);
          this.dvc.pages[0].layers[this.dvc.activelayerindex].color_blue = parseInt(this.dvc.activelayercolor.substr(5, 2), 16);
          this.labeltoolsService.recolor(this.dvc.activelayerindex);
          this.toastService.show('Layersettings saved!', 'Layersettings successfully saved.');
        },
        (err) => {
          this.logger.debug(err);
          this.toastService.show('ERROR: Layersettings not saved!', 'Could not save layersettings.');
        }
      );
    }
  }

  // tslint:disable-next-line: max-line-length
  constructor(
    private logger: NGXLogger,
    public dvc: DataviewerControllerService,
    private fb: FormBuilder,
    public labeltoolsService: LabeltoolsService,
    public minimapService: MinimapService,
    private cpService: ColorPickerService,
    public toastService: ToastmessageStoreService
  ) { }

  ngAfterViewInit(): void {
    this.minimapService.setCropbox.subscribe({next: newdata => {
      this.logger.debug('trigger setCropBoxData: ' + newdata.width + ' : ' + newdata.height);
      if ((newdata.width !== 0) && (newdata.height !== 0)) {
        try {
          this.minimapareaselect.cropper.setCropBoxData(newdata);
        } catch (error) {
          setTimeout(() => {this.minimapareaselect.cropper.setCropBoxData(newdata); }, 1000);
        }
      }
    }}
    );
  }

  ngOnInit(): void {

    this.opacityForm = this.fb.group({
      opacityValue: [0]
    });

    this.linewidthForm = this.fb.group({
      linewidthValue: [this.labeltoolsService.linewidth]
    });

    this.magicwandForm = this.fb.group({
      magicwandToleranceValue: [this.labeltoolsService.magicwandTolerance],
      magicwandContiguousValue: [this.labeltoolsService.magicwandContiguous]
    });

    this.dvc.loading.subscribe({next: loading => {
      if ((loading === false) && (this.dvc.pages)) {
        try {
          this.setOpacity(Math.round(this.dvc.pages[0].layers[this.dvc.activelayerindex].plane.material.opacity * 100));
        } catch (error) {
          this.logger.debug(error);
        }
      }
    }}
    );

    this.opacityForm.valueChanges.subscribe(() => {
      this.dvc.pages[0].layers[this.dvc.activelayerindex].plane.material.opacity = this.opacityForm.controls.opacityValue.value / 100;
      this.dvc.pages[0].layers[this.dvc.activelayerindex].opacity =  this.opacityForm.controls.opacityValue.value / 100;
      this.dvc.pages[0].layers[this.dvc.activelayerindex].plane.material.needsUpdate = true;
      this.logger.debug(this.dvc.modusSource.getValue());
      this.logger.debug(this.dvc.activelayerindex);
      this.logger.debug(this.dvc.pages[0].layers.length);
      if ((this.dvc.modusSource.getValue() === 'dataset') && (this.dvc.datasettype === 'boxdetection')) {
        this.dvc.changeBoxOpacity();
      }
      if ((this.dvc.modusSource.getValue() === 'trainedalgorithm') && (this.dvc.datasettype === 'boxdetection')) {
        this.dvc.changeBoxOpacity();
      }
      this.dvc.rerenderUpdate(true);
    });

    this.linewidthForm.valueChanges.subscribe(() => {
      this.labeltoolsService.linewidth = this.linewidthForm.controls.linewidthValue.value;
    });

    this.magicwandForm.valueChanges.subscribe(() => {
      this.labeltoolsService.magicwandTolerance = this.magicwandForm.controls.magicwandToleranceValue.value;
      this.labeltoolsService.magicwandContiguous = this.magicwandForm.controls.magicwandContiguousValue.value;
    });

    this.dvc.triggerSetopacity.subscribe({next: setopacity => {
      if (setopacity === true) {
        if (this.dvc.pages.length) {
          this.setOpacity(Math.round(this.dvc.pages[0].layers[this.dvc.activelayerindex].plane.material.opacity * 100));
        }
      }
    }}
    );
  }
}
