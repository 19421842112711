import { NGXLogger } from 'ngx-logger';
import { CropperComponent } from 'angular-cropperjs';
import { DataviewerControllerService } from './../shared/services/dataviewer-controller.service';
import { MapService } from './map.service';
import { Injectable, ElementRef, Renderer2 } from '@angular/core';
import * as THREE from 'three';
import * as Hammer from 'hammerjs';
import * as _ from 'lodash';
import { WebglService } from './webgl.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MinimapService {
  public camera: THREE.PerspectiveCamera;
  private currentZoom = 1.0;
  public mouseHover = false;
  public canvasWidth = 0;
  public canvasHeight = 0;
  public setCropboxSource = new BehaviorSubject<any>({top: 0, left: 0, width: 0, height: 0});
  public setCropbox = this.setCropboxSource.asObservable();
  public cropperConfig = {
    viewMode: 0,
    dragMode: 'none',
    aspectRatio: 1,
    modal: false,
    background: false,
    center: false,
    guides: false,
    highlight: false,
    movable: false,
    minContainerHeight: 250,
    minCanvasHeight: 5
  };

  public setCropboxUpdate(value: any) {
    this.setCropboxSource.next(value);
  }

  // tslint:disable-next-line: max-line-length
  public init(mapcanvasElementRef: ElementRef, minimapcanvasElementRef: ElementRef, minimapAreaselect: CropperComponent, ngRenderer: Renderer2) {
    // tslint:disable-next-line: max-line-length
    minimapcanvasElementRef.nativeElement.style.height = Math.ceil(minimapcanvasElementRef.nativeElement.clientWidth * (mapcanvasElementRef.nativeElement.clientHeight / mapcanvasElementRef.nativeElement.clientWidth)) + 'px';
    minimapAreaselect.cropperOptions.minContainerHeight = minimapcanvasElementRef.nativeElement.clientHeight;
    // tslint:disable-next-line: max-line-length
    minimapAreaselect.cropperOptions.aspectRatio = mapcanvasElementRef.nativeElement.clientWidth / mapcanvasElementRef.nativeElement.clientHeight;

    // tslint:disable-next-line: max-line-length
    this.camera = new THREE.PerspectiveCamera(45, mapcanvasElementRef.nativeElement.clientWidth / mapcanvasElementRef.nativeElement.clientHeight, 60, 150000);
    // this.camera = new THREE.PerspectiveCamera( 45, 1.77, 60, 150000 );
    this.camera.lookAt(new THREE.Vector3(0, 0, 0));
    this.camera.position.z = 1700;
    // this.camera.position.set(1700, 50, 1900);

    this.setCropBoxPosition(this.mapService.camera, mapcanvasElementRef, minimapcanvasElementRef, minimapAreaselect);
  }

  public minimapareaselectChange = (event) => {
    // this.logger.debug(this.minimapareaselect.cropper.getCropBoxData());
    // this.logger.debug(this.minimapareaselect.cropperOptions);
    // if (this.minimapService.mouseHover) {
    //  this.minimapService.setZoom(this.minimapareaselect.cropper.getCropBoxData(), this.minimapcanvasContainer);
    // }
  }

  public minimapareaselectCropstart = (event) => {
    // this.logger.debug(this.minimapareaselect.cropper.getCropBoxData());
    // this.logger.debug(this.minimapareaselect.cropperOptions);
  }
  public minimapareaselectCropend = (event) => {
    // console.log(this.mouseHover);
    if (this.mouseHover) {
      this.setZoom(event.srcElement.cropper.getCropBoxData());
    }
  }
  public minimapareaselectCropmove = (event) => {
    // console.log(this.mouseHover);
    if (this.mouseHover) {
      this.setZoom(event.srcElement.cropper.getCropBoxData());
    }
  }

  public resetView() {
    this.logger.debug('minimapResetView');
    const vFOV2 = this.camera.fov * Math.PI / 180; // convert vertical fov to radians
    const minimapZoomfactorX = this.canvasWidth / (this.dvc.inputwidth + 30); // 30px as a optical frame
    const minimapZoomfactorY = this.canvasHeight / (this.dvc.inputheight + 30); // 30px as a optical frame
    let minimapZoomfactor = 1;

    if (minimapZoomfactorX < minimapZoomfactorY) {
      minimapZoomfactor = minimapZoomfactorX;
    } else {
      minimapZoomfactor = minimapZoomfactorY;
    }

    const zoomheight = Math.ceil(this.canvasHeight * (1 / minimapZoomfactor));
    this.camera.position.z = zoomheight / (2 * Math.tan( vFOV2 / 2 ));
    // tslint:disable-next-line: max-line-length
    this.setCropboxUpdate({top: 0, left: 0, width: this.canvasWidth, height: this.canvasHeight});
    this.setZoom({top: 0, left: 0, width: this.canvasWidth, height: this.canvasHeight}, true);
  }

  public setZoom(cropBoxData, resetzoom?) {
    //  this.logger.debug('minimapSetZoom');
    const minimapvFOV = this.camera.fov  * Math.PI / 180;        // convert vertical fov to radians
    const minimapVisibleHeight = 2 * Math.tan( minimapvFOV / 2 ) * this.camera.position.z; // visible height
    const minimapVisibleWidth = minimapVisibleHeight * this.camera.aspect;

    const mapvFOV = this.mapService.camera.fov  * Math.PI / 180;        // convert vertical fov to radians
    let mapVisibleHeight = 2 * Math.tan( mapvFOV / 2 ) * this.mapService.camera.position.z; // visible height
    const mapVisibleWidth = mapVisibleHeight * this.mapService.camera.aspect;

    // set map zoom
    const heightRatio = cropBoxData.height / this.canvasHeight;
    mapVisibleHeight = minimapVisibleHeight * heightRatio;
    this.mapService.camera.position.z = mapVisibleHeight / (2 * (Math.tan( mapvFOV / 2 )));
    const newzoom = Math.round(this.mapService.canvasHeight / mapVisibleHeight * 100);
    // set map position
    // const scaleareaRatio = minimapcanvasElementRef.nativeElement.clientHeight / this.mapService.canvasHeight;
    const scaleareaRatio = minimapVisibleHeight / this.canvasHeight;
    const scaleareaRatio2 = this.canvasHeight / this.mapService.canvasHeight;

    const areaselectCenteredtop = Math.ceil((this.canvasHeight - cropBoxData.height) / 2);
    const areaselectCenteredleft = Math.ceil((this.canvasWidth - cropBoxData.width) / 2);


    if (resetzoom) {
      this.dvc.initialzoom = newzoom;
    }

    if (scaleareaRatio <= 100) {
      // tslint:disable-next-line: max-line-length
      this.mapService.camera.position.x = - Math.ceil((areaselectCenteredleft - cropBoxData.left) / (scaleareaRatio2 * (this.dvc.initialzoom / 100)));
      // tslint:disable-next-line: max-line-length
      this.mapService.camera.position.y = Math.ceil((areaselectCenteredtop - cropBoxData.top) / (scaleareaRatio2 * (this.dvc.initialzoom / 100)));
    } else {
      this.mapService.camera.position.x = 0;
      this.mapService.camera.position.y = 0;
      // tslint:disable-next-line: max-line-length
      this.setCropboxUpdate({top: 0, left: 0});
    }
    this.dvc.zoomUpdate(newzoom);
    this.dvc.rerenderUpdate(true);

  }

  // tslint:disable-next-line: max-line-length
  public setCropBoxPosition(mapcamera, mapcanvasElementRef: ElementRef, minimapcanvasElementRef: ElementRef, minimapAreaselect: CropperComponent) {
    if (minimapAreaselect.cropper) {
      const initialCropBoxData =  minimapAreaselect.cropper.getCropBoxData();
      const newCropBoxData = initialCropBoxData;

      const mapvFOV = mapcamera.fov  * Math.PI / 180;        // convert vertical fov to radians
      const mapVisibleHeight = 2 * Math.tan( mapvFOV / 2 ) * mapcamera.position.z; // visible height
      const mapVisibleWidth = mapVisibleHeight * mapcamera.aspect;
      // this.logger.debug(mapVisibleHeight + ' ' + mapVisibleWidth);

      const minimapvFOV = this.camera.fov  * Math.PI / 180;        // convert vertical fov to radians
      const minimapVisibleHeight = 2 * Math.tan( minimapvFOV / 2 ) * this.camera.position.z; // visible height
      const minimapVisibleWidth = minimapVisibleHeight * this.camera.aspect;
      // this.logger.debug(minimapVisibleHeight + ' ' + minimapVisibleWidth);

      const scaleareaRatio2 = this.canvasHeight / this.mapService.canvasHeight;

      if (minimapVisibleWidth <= mapVisibleWidth) {
        newCropBoxData.width = minimapcanvasElementRef.nativeElement.clientWidth;
        newCropBoxData.left = 0;
      } else {
        const tempRatioW = mapVisibleWidth / minimapVisibleWidth;
        newCropBoxData.width = tempRatioW * minimapcanvasElementRef.nativeElement.clientWidth;
        // newCropBoxData.left = Math.ceil((minimapcanvasElementRef.nativeElement.clientWidth - newCropBoxData.width) / 2);
        // tslint:disable-next-line: max-line-length
        newCropBoxData.left = Math.ceil(((scaleareaRatio2 * (this.dvc.initialzoom / 100)) * mapcamera.position.x) + ((minimapcanvasElementRef.nativeElement.clientWidth - newCropBoxData.width) / 2));
      }

      if (minimapVisibleHeight <= mapVisibleHeight) {
        newCropBoxData.height = minimapcanvasElementRef.nativeElement.clientHeight;
        newCropBoxData.top = 0;
      } else {
        const tempRatioH = mapVisibleHeight / minimapVisibleHeight;
        newCropBoxData.height = tempRatioH * minimapcanvasElementRef.nativeElement.clientHeight;
        // newCropBoxData.top = Math.ceil((minimapcanvasElementRef.nativeElement.clientHeight - newCropBoxData.height) / 2);
        // tslint:disable-next-line: max-line-length
        newCropBoxData.top = Math.ceil(((minimapcanvasElementRef.nativeElement.clientHeight - newCropBoxData.height) / 2) - ((scaleareaRatio2 * (this.dvc.initialzoom / 100)) * mapcamera.position.y));
      }

      try {
        // tslint:disable-next-line: max-line-length
        minimapAreaselect.cropper.setAspectRatio(mapcanvasElementRef.nativeElement.clientWidth / mapcanvasElementRef.nativeElement.clientHeight);
        // tslint:disable-next-line: max-line-length
        this.setCropboxUpdate({top: newCropBoxData.top, left: newCropBoxData.left, width: newCropBoxData.width, height: newCropBoxData.height});
      } catch (error) {
        this.logger.debug(error);
      }

    }
  }

  constructor(
    public mapService: MapService,
    public dvc: DataviewerControllerService,
    private logger: NGXLogger
  ) { }
}
