<form [formGroup]="toolbarform">
  <div class="row">
    <div class="col-md-auto">
      <div class="form-group">
        <span style="display: inline-block; padding:7px;"><fa-icon [icon]="['far', 'search']"></fa-icon></span>
        <input formControlName="searchstring" type="text" class="form-control" id="formGroupSearchstringInput" style="float:right; width:200px;">
      </div>
    </div>
    <div class="col"></div>
    <div class="col-md-auto">
      <div class="form-group">
        <select formControlName="listviewmodus" class="form-control" id="formGroupListviewmodusInput" aria-describedby="listviewmodusHelpBlock">
          <option [ngValue]="'nothumbnails'">no thumbnails</option>
          <option [ngValue]="'smallthumbnails'">small thumbnails</option>
        </select>
      </div>
    </div>
    <div class="col-md-3" *ngIf="fbs.modus === 'filebrowser'"></div>
  </div>
</form>
