import { ToastmessageStoreService } from './../../services/toastmessage-store.service';
import { Component, OnInit, TemplateRef } from '@angular/core';

@Component({
  selector: 'wol-toastmessages',
  templateUrl: './toastmessages.component.html',
  styleUrls: ['./toastmessages.component.scss']
})
export class ToastmessagesComponent implements OnInit {

  constructor(public toastService: ToastmessageStoreService) { }

  ngOnInit() {}

}
