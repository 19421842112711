import { DataviewerControllerService } from '../../shared/services/dataviewer-controller.service';
import { Component, OnInit } from '@angular/core';
import { LabeltoolsService } from '../labeltools.service';

@Component({
  selector: 'wol-sidebar-left',
  templateUrl: './sidebar-left.component.html',
  styleUrls: ['./sidebar-left.component.scss']
})
export class SidebarLeftComponent implements OnInit {

  constructor(public dvc: DataviewerControllerService, public labeltoolsService: LabeltoolsService) { }

  ngOnInit() {
  }

}
