<div class="row" *ngIf="fbs.modus === 'filebrowser'">
  <div class="col-md-9">
    <wol-filelist></wol-filelist>
  </div>
  <div class="col-md-3" style="border-left: 1px solid #efefef;">
    <wol-previewbar></wol-previewbar>
  </div>
</div>

<div class="row" *ngIf="fbs.modus === 'fileselector'" [ngClass]="{'fb-fileselector': fbs.modus === 'fileselector'}">
  <div class="col-md-12">
    <wol-filelist></wol-filelist>
  </div>
</div>
