<context-menu #basicmenu>
  <ng-template contextMenuItem (execute)="fdc.requestnewdownload(fbs.currentpath, fbs.selected)">
    Download
  </ng-template>
  <ng-template contextMenuItem (execute)="fbs.requestdelete(fbs.selected)">
    delete
  </ng-template>
  <ng-template *ngIf="fbs.selected.length == 1" contextMenuItem (execute)="fbs.requestrename(fbs.selected)">
    rename
  </ng-template>
  <ng-template contextMenuItem (execute)="fbs.requestmove(fbs.selected)">
    move
  </ng-template>
  <ng-template contextMenuItem (execute)="fbs.requestcopy(fbs.selected)">
    copy
  </ng-template>
  <!--<ng-template contextMenuItem divider="true"></ng-template>-->
</context-menu>
