import { Injectable } from '@angular/core';
import { SidebarNavitem } from './../interfaces/sidebar-navitem';
import { SidebarNavigation } from './../interfaces/sidebar-navigation';

@Injectable({
  providedIn: 'root'
})
export class SidebarNavigationService {

  sidebarNavigations: SidebarNavigation[];

  constructor() {
    this.sidebarNavigations = [
      { id: 'analyses',
        title: 'Analyses',
        navitems: [
          {title: 'Overview', icon: ['far', 'info-square'], itemtype: 'mainlink', link: '/analyses/overview'},
          {title: 'My Analyses', icon: ['far', 'flask'], itemtype: 'mainlink', link: '/analyses/show'},
          {title: 'New Analysis', icon: ['far', 'plus'], itemtype: 'sublink', link: '/analyses/create'}
          // {title: 'Test Board', icon: ['far', 'wrench'], itemtype: 'mainlink', link: '/analyses/testboard'}
        ]
      },
      { id: 'algorithms',
        title: 'Algorithms',
        navitems: [
          {title: 'Overview', icon: ['far', 'info-square'], itemtype: 'mainlink', link: '/algorithms/overview'},
          {title: 'Trained Algorithms', icon: ['far', 'graduation-cap'], itemtype: 'mainlink', link: '/algorithms/trainedalgorithms/show'},
          {title: 'New Training', icon: ['far', 'plus'], itemtype: 'sublink', link: '/algorithms/trainedalgorithms/create'},
          {title: 'Standard Algorithms', icon: ['far', 'lightbulb'], itemtype: 'mainlink', link: '/algorithms/standardalgorithms/list'},
          // {title: 'Workflows', icon: ['far', 'cubes'], itemtype: 'mainlink', link: '/algorithms/workflows/list'},
          {title: 'Private Algorithms', icon: ['far', 'key'], itemtype: 'mainlink', link: '/algorithms/privatealgorithms/list'},
        ]
      },
      { id: 'data',
        title: 'Data',
        navitems: [
          {title: 'Overview', icon: ['far', 'info-square'], itemtype: 'mainlink', link: '/data/overview'},
          {title: 'Files', icon: ['far', 'hdd'], itemtype: 'mainlink', link: '/data/files'},
          {title: 'Training Datasets', icon: ['far', 'database'], itemtype: 'mainlink', link: '/data/datasets/show'},
          {title: 'New Dataset', icon: ['far', 'plus'], itemtype: 'sublink', link: '/data/datasets/create'}
        ]
      },
      { id: 'account',
        title: 'Account',
        navitems: [
          {title: 'Overview', icon: [], itemtype: 'mainlink', link: '/account/overview'},
          {title: 'Profile', icon: ['far', 'user'], itemtype: 'headline', link: ''},
          {title: 'Coins', icon: [], itemtype: 'mainlink', link: '/account/coins'},
          // {title: 'Partnering', icon: [], itemtype: 'mainlink', link: '/account/partnering'},
          {title: 'Subscription', icon: [], itemtype: 'mainlink', link: '/account/subscription'},
          {title: 'Edit profile', icon: [], itemtype: 'mainlink', link: '/account/profile'},
          {title: 'Password', icon: [], itemtype: 'mainlink', link: '/account/password'},
          // {title: 'Notifications', icon: [], itemtype: 'mainlink', link: '/account/notifications'},
          // {title: 'Billing', icon: [], itemtype: 'mainlink', link: '/account/billing'},
          // {title: 'Group', icon: ['far', 'users'], itemtype: 'headline', link: ''},
          // {title: 'Members', icon: [], itemtype: 'mainlink', link: '/account/groupmembers'},
          // {title: 'Invite', icon: [], itemtype: 'mainlink', link: '/account/groupinvite'}
        ]
      },
      { id: 'support',
        title: 'Support',
        navitems: [
          {title: 'Tutorials', icon: ['far', 'magic'], itemtype: 'mainlink', link: '/support/tutorials'},
          {title: 'Video Tutorials', icon: ['far', 'video'], itemtype: 'mainlink', link: '/support/videotutorials'},
          {title: 'Frequent Questions', icon: ['far', 'question'], itemtype: 'mainlink', link: '/support/frequentquestions'},
          {title: 'About Us', icon: ['far', 'coffee'], itemtype: 'mainlink', link: '/support/aboutus'},
          {title: 'Contact', icon: ['far', 'envelope'], itemtype: 'mainlink', link: '/support/contact'},
          {title: 'Info & Legal Notices', icon: ['far', 'info-circle'], itemtype: 'mainlink', link: '/support/legalnotices'}
        ]
      },
      { id: 'admin',
        title: 'Administration',
        navitems: [
          {title: 'Overview', icon: [], itemtype: 'mainlink', link: '/admin/overview'},
          {title: 'Backups', icon: [], itemtype: 'mainlink', link: '/admin/backups'},
          {title: 'Accounts', icon: ['far', 'users'], itemtype: 'headline', link: ''},
          {title: 'ActivityLog', icon: [], itemtype: 'mainlink', link: '/admin/activitylog'},
          {title: 'Users/Accounts', icon: [], itemtype: 'mainlink', link: '/admin/accounts'},
          {title: 'AccountTypes', icon: [], itemtype: 'mainlink', link: '/admin/accounttypes'},
          {title: 'Notifications', icon: [], itemtype: 'mainlink', link: '/admin/notifications'},
          {title: 'Articles', icon: [], itemtype: 'mainlink', link: '/admin/articles'},
          {title: 'Analyses', icon: ['far', 'flask'], itemtype: 'headline', link: ''},
          {title: 'Analyses', icon: [], itemtype: 'mainlink', link: '/admin/analyses'},
          {title: 'Jobs', icon: [], itemtype: 'mainlink', link: '/admin/jobs'},
          {title: 'Engines', icon: [], itemtype: 'mainlink', link: '/admin/engines'},
          {title: 'Algorithms', icon: ['far', 'lightbulb'], itemtype: 'headline', link: ''},
          {title: 'Standard Algorithms', icon: [], itemtype: 'mainlink', link: '/admin/standardalgorithms'},
          {title: 'Private Algorithms', icon: [], itemtype: 'mainlink', link: '/admin/privatealgorithms'},
          {title: 'Trained Algorithms', icon: [], itemtype: 'mainlink', link: '/admin/trainedalgorithms'},
          {title: 'Learning Algorithms', icon: [], itemtype: 'mainlink', link: '/admin/learningalgorithms'},
          {title: 'Workflows', icon: [], itemtype: 'mainlink', link: '/admin/workflows'},
          {title: 'Data', icon: ['far', 'hdd'], itemtype: 'headline', link: ''},
          {title: 'Datasets', icon: [], itemtype: 'mainlink', link: '/admin/datasets'},
          {title: 'Support', icon: ['far', 'life-ring'], itemtype: 'headline', link: ''},
          {title: 'Frequent Questions', icon: [], itemtype: 'mainlink', link: '/admin/frequentquestions'},
          {title: 'Finances', icon: ['far', 'money-bill'], itemtype: 'headline', link: ''},
          {title: 'Transactions', icon: [], itemtype: 'mainlink', link: '/admin/transactions'},
          {title: 'Coin Usage', icon: [], itemtype: 'mainlink', link: '/admin/coinusage'},
          {title: 'Coin Coupons', icon: [], itemtype: 'mainlink', link: '/admin/coincoupons'},
        ]
      }
    ];
  }

  getAll() {
    return this.sidebarNavigations;
  }

}
