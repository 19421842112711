import { NGXLogger } from 'ngx-logger';
import { FileStoreService } from './../../shared/services/file-store.service';
import { FilebrowserService } from './../filebrowser.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  public setcurrentpath = (id: number) => {
    this.logger.debug('set path to: ', id);
    let temppath = [];

    if (id === -1) {
      temppath = [];
    } else {
      temppath = this.fbs.currentpath.slice( 0, id + 1);
    }

    // hotfix for swimlane table: table would be "empty" when vertical scroll is not reset
    document.getElementsByTagName('datatable-body')[0].scrollTop = 0;

    this.fbs.currentpath = temppath;

    setTimeout(() => {
      this.fs.filesChangedUpdate('');
      this.fbs.searchstringUpdate('');
      this.fbs.selected = [];
      this.fbs.searchstringUpdate('');
      this.fbs.page.pageNumber = 0;
      this.logger.debug(['pageinfo', this.fbs.page]);
      this.fbs.currentFilesCache = {};
      this.fbs.currentFiles = [];
      this.fbs.page.pageNumber = 0;
      this.fbs.isLoading = 0;
      this.fbs.isLoading++;
      this.fbs.loadpage(this.fbs.page);
    });

    /*
    this.fs.listFolder(temppath.join('/') + '/').subscribe(res => {
      this.fbs.currentpath = temppath;
      this.logger.debug(res);
      this.fbs.currentFiles = res;
      this.fbs.loading = false;
    });
    */
  }

  constructor(public logger: NGXLogger, public fbs: FilebrowserService, public fs: FileStoreService) { }

  ngOnInit() {
  }

}
