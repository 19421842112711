<div class="row justify-content-center" *ngIf="!foldertreeitems">
  <wol-loader [size]="50" [margin]="50"></wol-loader>
</div>
<div style="width:100%; max-height: 500px; overflow: auto;padding: 1rem;">
  <tree-root [nodes]="nodes" [options]="options" #tree>
    <ng-template #treeNodeWrapperTemplate let-node let-index="index">
      <div class="node-wrapper" [style.padding-left]="node.getNodePadding()">
        <div class="node-content-wrapper"
          [class.node-content-wrapper-active]="node.isActive"
          [class.node-content-wrapper-focused]="node.isFocused"
          (click)="node.mouseAction('click', $event)"
          (dblclick)="node.mouseAction('dblClick', $event)"
          (contextmenu)="node.mouseAction('contextMenu', $event)"
          (treeDrop)="node.onDrop($event)"
          [treeAllowDrop]="node.allowDrop"
          [treeDrag]="node"
          [treeDragEnabled]="node.allowDrag()">
          <fa-icon *ngIf="!node.isExpanded" [icon]="['far', 'folder']"></fa-icon>
          <fa-icon *ngIf="node.isExpanded" [icon]="['far', 'folder-open']"></fa-icon>
          <span style="padding-left:5px;">{{ node.data.name }}</span>
          <!--<tree-node-content [node]="node" [index]="index"></tree-node-content>-->
        </div>
      </div>
    </ng-template>

    <ng-template #loadingTemplate>
      <div class="row">
        <div class="col-md-auto" style="padding:0px 8px;">
          <wol-loader [size]="10" [margin]="8"></wol-loader>
        </div>
        <div class="col" style="padding:0px;">
          loading data...
        </div>
      </div>
    </ng-template>
  </tree-root>
</div>
