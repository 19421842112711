<div class="row" style="background-color: #fff;border-bottom:1px solid #ccc;margin:0px; margin-bottom:-35px;">
  <div style="padding:5px; white-space: nowrap; font-size: 0.9em;text-overflow: ellipsis;float:left;">
    <form [formGroup]="zoomForm" style="width: 250px; margin: auto;">
      <fa-icon style="float:left; padding:6px;color:#666666;" [icon]="['far', 'search']"></fa-icon>
      <input formControlName="zoomFactor" style="width:100px; margin-top:8px; margin-left:10px; margin-right: 10px; margin-bottom: 3px; padding:0px; font-size:0.8em;float:left;" type="range" min="5"  max="1000">

      <span style="margin-top:6px;font-size:0.9em;width:25px; display: inline-block;">{{zoomForm.value.zoomFactor}} %</span>
      <button class="btn btn-primary btn-sm" (tap)="setZoom(100)" style="padding: 2px 7px;margin-left:10px;"><fa-icon [icon]="['far', 'search']"></fa-icon> 100%</button>
      <button class="btn btn-primary btn-sm" (tap)="setZoom(0)" style="padding: 2px 7px;margin-left:10px;"><fa-icon [icon]="['far', 'undo']"></fa-icon> reset view</button>
    </form>
  </div>

  <div style="padding:5px; white-space: nowrap; font-size: 0.9em;text-overflow: ellipsis;float:right;">
      <!--<button class="btn btn-primary btn-sm" style="padding: 2px 7px;"><i class="fal fa-arrows-alt"></i> fullscreen</button>-->
  </div>
</div>
