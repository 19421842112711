import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wol-modal-upload',
  templateUrl: './modal-upload.component.html',
  styleUrls: ['./modal-upload.component.scss']
})
export class ModalUploadComponent implements OnInit {

  @Input() title: string;
  @Input() type: string;

  public filesToUpload: FileList;

  public handleFileInput(files: FileList) {
    this.filesToUpload = files;
  }

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
