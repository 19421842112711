import { LoginlayoutComponent } from './loginlayout/loginlayout.component';
import { MainlayoutComponent } from './mainlayout/mainlayout.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../shared/guards/auth.guard';

const routes: Routes = [
  { path: 'login',
    component: LoginlayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../login/login.module').then(mod => mod.LoginModule),
      }
    ]
  },
  { path: '',
    component: MainlayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('../section-dashboard/section-dashboard.module').then(mod => mod.SectionDashboardModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'analyses',
        loadChildren: () => import('../section-analyses/section-analyses.module').then(mod => mod.SectionAnalysesModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'algorithms',
        loadChildren: () => import('../section-algorithms/section-algorithms.module').then(mod => mod.SectionAlgorithmsModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'data',
        loadChildren: () => import('../section-data/section-data.module').then(mod => mod.SectionDataModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'account',
        loadChildren: () => import('../section-account/section-account.module').then(mod => mod.SectionAccountModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'support',
        loadChildren: () => import('../section-support/section-support.module').then(mod => mod.SectionSupportModule),
        canActivateChild: [AuthGuard]},
      {
        path: 'admin',
        // tslint:disable-next-line: max-line-length
        loadChildren: () => import('../section-administration/section-administration.module').then(mod => mod.SectionAdministrationModule),
        canActivateChild: [AuthGuard],
        data: {role: 'admin'}
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CoreRoutingModule { }
