import { FileselectorModule } from './../fileselector/fileselector.module';
import { FileuploaderModule } from './../fileuploader/fileuploader.module';
import { StatsviewerModule } from './../statsviewer/statsviewer.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';
import { DataviewerModule } from './../dataviewer/dataviewer.module';

import { CoreRoutingModule } from './core-routing.module';
import { MainlayoutComponent } from './mainlayout/mainlayout.component';
import { LoginlayoutComponent } from './loginlayout/loginlayout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { StatusmenuComponent } from './statusmenu/statusmenu.component';
import { FiledownloaderModule } from '../filedownloader/filedownloader.module';
import { SysteminformationPanelComponent } from './systeminformation-panel/systeminformation-panel.component';


@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [MainlayoutComponent, LoginlayoutComponent, HeaderComponent, SidebarComponent, StatusmenuComponent, SysteminformationPanelComponent],
  imports: [
    CommonModule,
    CoreRoutingModule,
    SharedModule,
    DataviewerModule,
    StatsviewerModule,
    FileuploaderModule,
    FiledownloaderModule,
    FileselectorModule
  ],
  exports: [
    SysteminformationPanelComponent
  ]
})
export class CoreModule { }
