import { RouterModule } from '@angular/router';
import { SharedModule } from './../shared/shared.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { JobsListComponent } from './jobs-list/jobs-list.component';
import { JobsListAnalysisdetailsComponent } from './jobs-list-analysisdetails/jobs-list-analysisdetails.component';
import { JobsListitemAnalysisdetailsComponent } from './jobs-listitem-analysisdetails/jobs-listitem-analysisdetails.component';
import { JobsStatusbatchComponent } from './jobs-statusbatch/jobs-statusbatch.component';
import { JobDetailstabComponent } from './job-detailstab/job-detailstab.component';



@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [JobsListComponent, JobsListAnalysisdetailsComponent, JobsListitemAnalysisdetailsComponent, JobsStatusbatchComponent, JobDetailstabComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule
  ],
  exports: [
    JobsListComponent,
    JobsListAnalysisdetailsComponent,
    JobsListitemAnalysisdetailsComponent,
    JobsStatusbatchComponent,
    JobDetailstabComponent
  ]
})
export class JobsModule { }
