import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { FilebrowserService } from 'src/app/filebrowser/filebrowser.service';

@Injectable({
  providedIn: 'root'
})
export class FileselectorControllerService {

  public visibleSource = new BehaviorSubject<boolean>(false);
  public visible = this.visibleSource.asObservable();

  public visibleUpdate(value: boolean) {
    this.visibleSource.next(value);
  }

  public open() {
    this.fbs.modus = 'fileselector';
    this.visibleUpdate(true);
  }

  public close() {
    this.fbs.modus = 'filebrowser';
    this.visibleUpdate(false);
  }

  constructor(public fbs: FilebrowserService) { }
}
