import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'Required',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword:
        'Invalid password. Password must be at least 6 characters long, and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`
    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (
      control.value.match(
        /^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/
      )
    ) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  static emailValidator(control) {
    // RFC 2822 compliant regex
    if (
      control.value.match(
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
      )
    ) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static foldernameValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
      return null;
    } else {
      return { invalidFoldername: true };
    }
  }

  static filenameValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
      return null;
    } else {
      return { invalidFilename: true };
    }
  }

  static usernameValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
      return null;
    } else {
      return { invalidUsername: true };
    }
  }

  static analysisnameValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
      return null;
    } else {
      return { invalidAnalysisname: true };
    }
  }

  static algorithmnameValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
      return null;
    } else {
      return { invalidAlgorithmname: true };
    }
  }

  static versionValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
      return null;
    } else {
      return { invalidVersion: true };
    }
  }

  static learningalgorithmtypeValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
      return null;
    } else {
      return { invalidLearningalgorithmtype: true };
    }
  }

  static datasetnameValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
      return null;
    } else {
      return { invalidDatasetname: true };
    }
  }

  static keywordsValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    let isvalid = true;
    _.each(control.value, (item) => {
      if (item.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\s$)(?!\/$)(?!\.$)[/\w.-]$/)) {
        // nothing happens
      } else {
        isvalid = false;
      }
    });

    if (isvalid) {
      return null;
    } else {
      return { invalidKeywords: true };
    }
  }

  static layernameValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
        return null;
      } else {
        return { invalidLayername: true };
      }
    }
  }

  static stringparameterValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9]{0,49}(?!\s$)(?!\/$)(?!\.$)[/\w.-]$/)) {
        return null;
      } else {
        return { invalidStringparameter: true };
      }
    }
  }

  static charparameterValidator(control) {
    // {1,1}           - Assert string is between 1 and 1 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9]{1,1}((?!\s$)(?!\/$)(?!\.$)[/\w.-])$/)) {
        return null;
      } else {
        return { invalidCharparameter: true };
      }
    }
  }

  // #### AlgorithmParameters

  static parameterNameValidator(control) {
    // {1,50}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,49}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
        return null;
      } else {
        return { invalidParameterName: true };
      }
    }
  }

  static parameterDescriptionValidator(control) {
    // {1,50}           - Assert string is between 1 and 250 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,249}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/)) {
        return null;
      } else {
        return { invalidParameterDescription: true };
      }
    }
  }

  static integerparameterDefaultvalueValidator(control) {
    // {0,9}           - Assert integer is between 1 and 10 characters
    // only iteger allowed, positive AND negative!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|-?[1-9]{1}[0-9]{0,9})$/)) {
        return null;
      } else {
        return { invalidIntegerparameterDefaultvalue: true };
      }
    }
  }

  static integerparameterMaximumvalueValidator(control) {
    // {1,10}           - Assert integer is between 1 and 10 characters
    // only iteger allowed, positive AND negative!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|-?[1-9]{1}[0-9]{0,9})$/)) {
        return null;
      } else {
        return { invalidIntegerparameterMaximumvalue: true };
      }
    }
  }

  static integerparameterMinimumvalueValidator(control) {
    // {1,10}           - Assert integer is between 1 and 10 characters
    // only iteger allowed, positive AND negative!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|-?[1-9]{1}[0-9]{0,9})$/)) {
        return null;
      } else {
        return { invalidIntegerparameterMinimumvalue: true };
      }
    }
  }

  static integerparameterPrecisionValidator(control) {
    // {1,10}           - Assert integer is between 1 and 10 characters
    // only iteger allowed, ONLY positive!
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|[1-9]{1}[0-9]{0,9})$/)) {
        return null;
      } else {
        return { invalidIntegerparameterPrecision: true };
      }
    }
  }

  static floatparameterDefaultvalueValidator(control) {
    // {0,9}           - Assert float is between 1 and 10 characters
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|-?[0-9]{1}[0-9]{0,9}\.?[0-9]*)$/)) {
        return null;
      } else {
        return { invalidFloatparameterDefaultvalue: true };
      }
    }
  }

  static floatparameterMaximumvalueValidator(control) {
    // {1,10}           - Assert float is between 1 and 10 characters
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|-?[0-9]{1}[0-9]{0,9}\.?[0-9]*)$/)) {
        return null;
      } else {
        return { invalidFloatparameterMaximumvalue: true };
      }
    }
  }

  static floatparameterMinimumvalueValidator(control) {
    // {1,10}           - Assert float is between 1 and 10 characters
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|-?[0-9]{1}[0-9]{0,9}\.?[0-9]*)$/)) {
        return null;
      } else {
        return { invalidFloatparameterMinimumvalue: true };
      }
    }
  }

  static floatparameterPrecisionValidator(control) {
    // {1,10}           - Assert float is between 1 and 10 characters
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|[0-9]{1}[0-9]{0,9}\.?[0-9]*)$/)) {
        return null;
      } else {
        return { invalidFloatparameterPrecision: true };
      }
    }
  }

  static floatparameterDecimalplacesValidator(control) {
    // {1,2}           - Assert integer is between 1 and 2 characters
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|[1-9]{1}[0-9]{0,2})$/)) {
        return null;
      } else {
        return { invalidFloatparameterDecimalplaces: true };
      }
    }
  }

  static stringparameterMaximumlengthValidator(control) {
    // {1,4}           - Assert integer is between 1 and 4 characters
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|[1-9]{1}[0-9]{0,4})$/)) {
        return null;
      } else {
        return { invalidStringparameterMaximumlength: true };
      }
    }
  }

  static stringparameterMinimumlengthValidator(control) {
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|[1-9]{1}[0-9]{0,4})$/)) {
        return null;
      } else {
        return { invalidStringparameterMinimumlength: true };
      }
    }
  }

  static stringparameterDefaultvalueValidator(control) {
    // {1,1000}           - Assert string is between 1 and 50 characters
    // ^(?!\.)^(?!\s)   - Assert a string does NOT start with a dot or whitespace
    // [a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]    - Assert a string consisting of a-z, A-Z, 0-9, : ! ? @ # + () [] * . - _ or "blank"
    // ((?!\s$)[/\w.-])((?!\.$)[/\w.-])$ - Assert a stromg does NOT end with a dot or whitespace
    // all other specialchars are NOT allowed!
    if (control.value !== null || control.value !== undefined) {
      if ((control.value === '') || (control.value.match(/^(?!\.)^(?!\s)[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{0,1000}(?!\/$)(?!\s$)(?!\.$)[/\w.-]$/))) {
        return null;
      } else {
        return { invalidStringparameterDefaultvalue: true };
      }
    }
  }

  static characterparameterDefaultvalueValidator(control) {
    // only 1 character allowed
    if (control.value !== null || control.value !== undefined) {
      if ((control.value === '') ||  (control.value.match(/^[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{1}$/))) {
        return null;
      } else {
        return { invalidCharacterparameterDefaultvalue: true };
      }
    }
  }

  static selectionparameterDefaultvalueValidator(control) {
    // only 1 character allowed
    if (control.value !== null || control.value !== undefined) {
      if ((control.value === '') ||  (control.value.match(/^[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{1}$/))) {
        return null;
      } else {
        return { invalidSelectionparameterDefaultvalue: true };
      }
    }
  }

  static selectionparameterNumberofoptionsValidator(control) {
    // only integer allowed
    if (control.value !== null || control.value !== undefined) {
      if (control.value.match(/^(0|[1-9]{1}[0-9]{0,1})$/)) {
        return null;
      } else {
        return { invalidSelectionparameterDefaultvalue: true };
      }
    }
  }

  static selectionparameterOptionValidator(control) {
    // only 1 character allowed
    if (control.value !== null || control.value !== undefined) {
      if ((control.value === '') ||  (control.value.match(/^[a-zA-Z0-9:!?@#+()\[\]*\.\-\_\ ]{1}$/))) {
        return null;
      } else {
        return { invalidSelectionparameterDefaultvalue: true };
      }
    }
  }


  // #### Passwords

  static passwordValidator(control) {
    // {6,20}           - Assert password is between 6 and 20 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value) {
      if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,20}$/)) {
        return null;
      } else {
        return { invalidPassword: true };
      }
    }
  }

  static passwordMatchValidator(frm: FormGroup) {
    if (frm.controls.password.value === frm.controls.passwordconfirm.value) {
      frm.controls.passwordconfirm.setErrors(null);
      return;
    } else {
      frm.controls.passwordconfirm.setErrors({ invalidPasswordMatch: true });
      return;
    }
  }
}
