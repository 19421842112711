<div *ngIf="(dvc.modus | async) === 'dataset'" style="cursor: default; position:absolute; top:0; left:0;width:200px;min-height:170px; padding:20px 0px; background-color:rgba(255, 255, 255, 1.0);border-right:0px solid #ccc;border-bottom:0px solid #cccccc;">
  <div class="row justify-content-center">
    <div class="col-md-auto" style="color:#777777;cursor: pointer;" (tap)="dvc.loadPrevious()">
      <fa-icon [icon]="['far', 'chevron-left']" size="3x"></fa-icon>
    </div>
    <div class="col-md-4" style="padding:8px;font-size:1em;text-align:center;">
        <span style="font-size:1.1em;">{{dvc.trainingdataindex + 1}}</span><span style="font-size:1.1em;">/</span><span style="font-size:1.1em;">{{dvc.trainingdata.length}}</span>
    </div>
    <div class="col-md-auto" style="color:#777777;cursor: pointer;" (tap)="dvc.loadNext()">
      <fa-icon [icon]="['far', 'chevron-right']" size="3x"></fa-icon>
    </div>
  </div>
  <div class="row justify-content-center " style="margin-top:10px;">
    <div class="col-md-auto justify-content-center shorten-long-text" ngbTooltip="{{dvc.dataobject1.name}}" style="padding: 0px 30px;">
      {{dvc.dataobject1.name}}
    </div>
  </div>
  <div class="row justify-content-center">
    <div class="col-md-auto justify-content-center shorten-long-text" style="padding: 0px 30px;">
      {{dvc.dataobject1.width}} x {{dvc.dataobject1.height}} px
    </div>

  </div>
  <div class="row justify-content-center" style="font-size:0.8em; margin-top:15px;">
    <div class="col-md-auto">
      <button class="btn btn-primary" (tap)="dvc.saveLabel()">Save</button>
      <button class="btn btn-primary" (tap)="dvc.loadLabel()" style="margin-left:5px;">Load</button>
    </div>
  </div>
  <div class="row justify-content-center" style="font-size:0.8em; margin-top:15px;">
    <div class="col-md-auto">
      <button class="btn btn-sm btn-info" (tap)="labeltoolsService.undoLabel()" [disabled]="!dvc.undoavailable"><fa-icon [icon]="['far', 'undo']"></fa-icon> Undo</button>
      <button class="btn btn-sm btn-info" (tap)="labeltoolsService.redoLabel()" [disabled]="!dvc.redoavailable" style="margin-left:2px;"><fa-icon [icon]="['far', 'redo']"></fa-icon> Redo</button>
      <button class="btn btn-sm btn-info" (tap)="labeltoolsService.clickclearcanvas()" style="margin-left:2px;">Clear</button>
    </div>
  </div>
  <div class="row justify-content-center" style="margin-top:15px;">
    <span *ngIf="dvc.changesmade">
      <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon> unsaved changes
    </span>
  </div>
</div>

<div class="container" *ngIf="(dvc.modus | async) === 'analysisDEBUG'" style="cursor: default; position:absolute; top:0; left:0;width:200px;min-height:170px; background-color:rgba(255, 255, 255, 1.0);border-right:0px solid #ccc;border-bottom:0px solid #cccccc;">
  <div class="row" style="margin-top:20px">
    <div class="col-md-auto">
      <fa-icon [icon]="['far', 'chart-bar']"></fa-icon> &nbsp; <b>ObjectId: 0</b>
    </div>

  </div>
  <div class="row" style="font-size:0.8em; margin-top:15px;">
    <div class="col-md-auto">
      <table>
        <tr>
          <td>
            cx:
          </td>
          <td>
            120
          </td>
        </tr>
        <tr>
          <td>
            cy:
          </td>
          <td>
            1442
          </td>
        </tr>
        <tr>
          <td>
            area:
          </td>
          <td>
            213345
          </td>
        </tr>
        <tr>
          <td>
            perimeter:
          </td>
          <td>
            2342
          </td>
        </tr>
        <tr>
          <td>
            angle:
          </td>
          <td>
            32
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
