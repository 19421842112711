import { FilebrowserService } from './../../filebrowser/filebrowser.service';
import { FileselectorControllerService } from './../../shared/services/fileselector-controller.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-fileselector',
  templateUrl: './fileselector.component.html',
  styleUrls: ['./fileselector.component.scss']
})
export class FileselectorComponent implements OnInit {

  constructor(public fsc: FileselectorControllerService, public fbs: FilebrowserService) { }

  ngOnInit() {
    this.fbs.modus = 'fileselector';
    this.fbs.selected = [];
  }

}
