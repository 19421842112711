import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../shared/shared.module';

import { FilebrowserRoutingModule } from './filebrowser-routing.module';
import { FilebrowserComponent } from './filebrowser/filebrowser.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { MaincontentComponent } from './maincontent/maincontent.component';
import { FilelistComponent } from './filelist/filelist.component';
import { PreviewbarComponent } from './previewbar/previewbar.component';
import { FooterComponent } from './footer/footer.component';
import { FilecontextmenuComponent } from './filecontextmenu/filecontextmenu.component';
import { CommonmodalsComponent } from '../shared/components/commonmodals/commonmodals.component';
import { ModalRenameComponent } from './modal-rename/modal-rename.component';
import { ModalCopyComponent } from './modal-copy/modal-copy.component';
import { ModalMoveComponent } from './modal-move/modal-move.component';
import { ModalDeleteComponent } from './modal-delete/modal-delete.component';
import { ModalCreatefolderComponent } from './modal-createfolder/modal-createfolder.component';
import { ModalUploadComponent } from './modal-upload/modal-upload.component';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [FilebrowserComponent, BreadcrumbComponent, ToolbarComponent, MaincontentComponent, FilelistComponent, PreviewbarComponent, FooterComponent, FilecontextmenuComponent, ModalRenameComponent, ModalCopyComponent, ModalMoveComponent, ModalDeleteComponent, ModalCreatefolderComponent, ModalUploadComponent],
  imports: [
    CommonModule,
    FilebrowserRoutingModule,
    SharedModule
  ],
  exports: [
    FilebrowserComponent
  ],
  // tslint:disable-next-line: max-line-length
  entryComponents: [CommonmodalsComponent, ModalRenameComponent, ModalCopyComponent, ModalMoveComponent, ModalDeleteComponent, ModalCreatefolderComponent, ModalUploadComponent]
})
export class FilebrowserModule { }
