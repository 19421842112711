import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wol-modal-rename',
  templateUrl: './modal-rename.component.html',
  styleUrls: ['./modal-rename.component.scss']
})
export class ModalRenameComponent implements OnInit {

  @Input() title: string;
  @Input() type: string;
  @Input() oldFoldername: string;
  public renameform: FormGroup;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    if (this.renameform) { return; }

    this.renameform = this.fb.group({
      foldername: [this.oldFoldername, Validators.required]
    });
  }
}
