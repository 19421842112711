import { FiledownloaderControllerService } from './../../shared/services/filedownloader-controller.service';
import { FilebrowserService } from './../filebrowser.service';
import { NGXLogger } from 'ngx-logger';
import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { ContextMenuComponent, ContextMenuService } from 'ngx-contextmenu';

@Component({
  selector: 'wol-filecontextmenu',
  templateUrl: './filecontextmenu.component.html',
  styleUrls: ['./filecontextmenu.component.scss']
})
export class FilecontextmenuComponent implements OnInit {

  @ViewChild('basicmenu', {static: true}) public basicMenu: ContextMenuComponent;
  public item: any;

  constructor(
    private logger: NGXLogger,
    private contextMenuService: ContextMenuService,
    public fbs: FilebrowserService,
    public fdc: FiledownloaderControllerService
  ) { }

  ngOnInit() {
  }

  showMessage(message: any) {
    this.logger.debug(message);
  }

  public openContextMenu(event: any): void {
    setTimeout(() => {
      this.logger.debug(event);
      this.logger.debug(this.basicMenu);
      if (event.type === 'body') {
        this.item = event.content;
        this.contextMenuService.show.next({
          contextMenu: this.basicMenu,
          event: event.event,
          item: event.content,
        });
      }
    });
  }

}
