import { DataviewerControllerService } from 'src/app/shared/services/dataviewer-controller.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-layerlist',
  templateUrl: './layerlist.component.html',
  styleUrls: ['./layerlist.component.scss']
})
export class LayerlistComponent implements OnInit {

  public options = {
    onUpdate: (event: any) => {
      this.dvc.updatelayerorder(0);
    }
  };

  constructor(public dvc: DataviewerControllerService) { }

  ngOnInit() {
  }

}
