import { ToastmessageStoreService } from './../../shared/services/toastmessage-store.service';
import { LabeltoolsService } from './../labeltools.service';
import { MapService } from './../map.service';
import { NGXLogger } from 'ngx-logger';
import { DataviewerControllerService } from 'src/app/shared/services/dataviewer-controller.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonmodalsComponent } from 'src/app/shared/components/commonmodals/commonmodals.component';
import { ModalLayersettingsComponent } from '../modal-layersettings/modal-layersettings.component';
import { ColorPickerService } from 'ngx-color-picker';
import * as _ from 'lodash';

@Component({
  selector: 'wol-layerlist-item',
  templateUrl: './layerlist-item.component.html',
  styleUrls: ['./layerlist-item.component.scss']
})
export class LayerlistItemComponent implements OnInit {

  @Input() layer: any;
  @Input() index: number;

  public selectLayer() {
    this.dvc.selectLayer(this.index);
  }

  public showLayer() {
    this.dvc.showLayer(this.index);
  }

  public interpretAsLabel(checkboxvalue: boolean) {
    this.dvc.interpretAsLabel(this.index, checkboxvalue);
  }

  public confirmClearlayer() {
    this.logger.debug('Confirm clear Layer');
    const modalRef = this.modalService.open(CommonmodalsComponent, {ariaLabelledBy: 'modal-basic-title'});
    modalRef.componentInstance.type = 'delete';
    modalRef.componentInstance.title = 'Clear layer';
    modalRef.componentInstance.text = 'Are you sure you want to clear this layer?';
    modalRef.result.then((result) => {
      this.logger.debug(`closed: ${result}`);
      this.labeltoolsService.clearlayer(this.index);
    }, (reason) => {
      this.logger.error(`dismissed: ${reason}`);
    });
  }

  public openLayersettings() {
    this.logger.debug('openLayersettings');

    // timeout necessary to prevent error on click in combination with datatable!
    // const modalRef = this.modalService.open(ArticleRemoveModalComponent);
    // modalRef.componentInstance.name = 'World';
    setTimeout(() => {
      const modalRef = this.modalService.open(ModalLayersettingsComponent, {ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.type = 'settings';
      modalRef.componentInstance.title = 'Layer settings';
      modalRef.componentInstance.text = `Please modify the layer settings as needed and press the save button.<br>`;
      modalRef.componentInstance.layername = this.dvc.pages[0].layers[this.index].name;
      // tslint:disable-next-line: max-line-length
      modalRef.componentInstance.layercolor = '#' + this.dvc.decimalToHex(this.dvc.pages[0].layers[this.index].color_red) + this.dvc.decimalToHex(this.dvc.pages[0].layers[this.index].color_green) + this.dvc.decimalToHex(this.dvc.pages[0].layers[this.index].color_blue);
      modalRef.result.then((result) => {
        this.logger.debug(result);
        _.each(this.dvc.datasetclasses, (item) => {
          if (item.class_id === this.dvc.pages[0].layers[this.index].classId) {
            item.name = result.layername;
            item.color_red = parseInt(result.layercolor.substr(1, 2), 16);
            item.color_green = parseInt(result.layercolor.substr(3, 2), 16);
            item.color_blue = parseInt(result.layercolor.substr(5, 2), 16);
          }
        });
        this.dvc.sendSaveLayersettings(JSON.stringify(this.dvc.datasetclasses), this.dvc.datasetid)
        .subscribe(
          (res: any) => {
            this.logger.debug(res);
            this.dvc.pages[0].layers[this.index].name = result.layername;
            this.dvc.pages[0].layers[this.index].color_red = parseInt(result.layercolor.substr(1, 2), 16);
            this.dvc.pages[0].layers[this.index].color_green = parseInt(result.layercolor.substr(3, 2), 16);
            this.dvc.pages[0].layers[this.index].color_blue = parseInt(result.layercolor.substr(5, 2), 16);
            this.labeltoolsService.recolor(this.index);

            if (this.index === this.dvc.activelayerindex) {
              this.dvc.activelayercolor = result.layercolor;
            }
            this.toastService.show('Layersettings saved!', 'Layersettings successfully saved.');
          },
          (err) => {
            this.logger.debug(err);
            this.toastService.show('ERROR: Layersettings not saved!', 'Could not save layersettings.');
          }
        );
      }, (reason) => {
        this.logger.debug(`dismissed: ${reason}`);
      });
    });
  }

  // tslint:disable-next-line: max-line-length
  constructor(
    private modalService: NgbModal,
    public dvc: DataviewerControllerService,
    public labeltoolsService: LabeltoolsService,
    private logger: NGXLogger,
    private cpService: ColorPickerService,
    public toastService: ToastmessageStoreService
  ) { }

  ngOnInit() {
  }

}
