import { FilebrowserService } from 'src/app/filebrowser/filebrowser.service';
import { FileStoreService } from './file-store.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'lodash';
import { HttpClient, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { NGXLogger } from 'ngx-logger';

@Injectable({
  providedIn: 'root'
})
export class FileuploaderControllerService {

  public maxuploadslots = 1;

  public visibleSource = new BehaviorSubject<boolean>(false);
  public visible = this.visibleSource.asObservable();
  public extendedSource = new BehaviorSubject<boolean>(false);
  public extended = this.extendedSource.asObservable();
  public datasetfilesChangedSource = new BehaviorSubject<any>([]);
  public datsetfilesChanged = this.datasetfilesChangedSource.asObservable();
  public uploadqueue: any[] = [];

  public visibleUpdate(value: boolean) {
    this.visibleSource.next(value);
  }
  public extendedUpdate(value: boolean) {
    this.extendedSource.next(value);
  }
  public datasetfilesChangedUpdate(value: any) {
    this.datasetfilesChangedSource.next(value);
  }

  public close() {
    this.visibleUpdate(false);
  }

  public open() {
    this.visibleUpdate(true);
  }

  public extend() {
    this.extendedUpdate(true);
  }

  public minimize() {
    this.extendedUpdate(false);
  }

  public addFilesToUploadQueue(folderpath: string, filelist: any[]) {
    this.open();
    this.extend();
    _.each(filelist, (item, index, list) => {
      item.folderpath = folderpath;
      item.progresstext = 'queued';
    });
    this.uploadqueue = this.uploadqueue.concat(Array.prototype.slice.call(filelist));
    // this.logger.debug(this.uploadqueue);
    this.logger.debug('try to uplad files in queue');

    this.fs.refreshspaceinfo()
      .subscribe(
        (res: any) => {
          // this.logger.debug(res);
          this.fs.spaceinfo = res;

          for (let i = 0; i < this.uploadqueue.length; i++) {
            if (this.uploadqueue[i].progresstext === 'queued') {
              this.startUpload(this.uploadqueue[i], i);
            }
          }
        },
        (err) => {
          this.logger.debug(err);
        }
      );
  }

  public startUpload(file: any, index: number) {
    file.index = index;
    this.uploadqueue[index].progress = 0;
    this.uploadqueue[index].progresstext = '0%';

    // this.logger.debug(file);
    const upload = (fileitem: any) => {
      // this.logger.debug(this.uploadqueue[index].folderpath);

      let folderpath = '';

      if (this.uploadqueue[index].folderpath.charAt(0) === '/') {
        folderpath = this.uploadqueue[index].folderpath.substr(1);
      } else {
        folderpath = this.uploadqueue[index].folderpath;
      }

      const formData: FormData = new FormData();
      formData.append('file', this.uploadqueue[index]);
      formData.append('folderpath', folderpath);
      formData.append('context', this.uploadqueue[index].context);

      this.http.post<any[]>(
        `${environment.apiurl}/filestorage/upload`,
        formData,
        {reportProgress: true, observe: 'events'}
      ).subscribe(event => {
        // this.logger.debug(event);
        if (event.type === HttpEventType.UploadProgress) {
          // this.logger.debug('Upload Progress: ' + Math.round(event.loaded / event.total * 100));
          this.uploadqueue[index].progress = Math.round(100.0 * event.loaded / event.total);
          this.uploadqueue[index].progresstext = this.uploadqueue[index].progress + '%';
          if (this.uploadqueue[index].progress === '100') {
            this.uploadqueue[index].progresstext = 'processing';
          }
        } else if (event instanceof HttpResponse) {
          // this.logger.debug(this.uploadqueue[index].folderpath);
          this.logger.debug(event.body);

          if (event.body[0] === 'error') {
            this.logger.debug('got error');
            this.uploadqueue[index].progresstext = 'error';
            if (event.body[2] === 'dataset') {
              this.datasetfilesChangedUpdate([event.body[0], event.body[1], 'hdd']);
            }
          } else {
            if (event.body[2] === 'dataset') {
              this.uploadqueue[index].progresstext = 'finished';
              this.datasetfilesChangedUpdate([event.body[0], event.body[1], 'hdd']);
            } else {
              this.uploadqueue[index].progresstext = 'finished';

              if ((this.fs.filesChangedSource.value !== this.uploadqueue[index].folderpath) || (this.uploadqueue.length === index)){
                this.logger.debug('set FilesChanged!');
                this.fs.filesChangedUpdate(this.uploadqueue[index].folderpath);
              }
              // service.fileFinishedCounter += 1;
              // this.logger.debug('finished: ' + this.uploadqueue[index].name);
              const fileextension = this.uploadqueue[index].name.substr(this.uploadqueue[index].name.lastIndexOf('.') + 1).toLowerCase();
              let thumbnail = false;
              if (fileextension === 'jpg') {
                thumbnail = true;
              } else if (fileextension === 'jpeg') {
                thumbnail = true;
              } else if (fileextension === 'bmp') {
                thumbnail = true;
              } else if (fileextension === 'tif') {
                thumbnail = true;
              } else if (fileextension === 'tiff') {
                thumbnail = true;
              } else if (fileextension === 'gif') {
                thumbnail = true;
              } else if (fileextension === 'png') {
                thumbnail = true;
              } else if (fileextension === 'dib') {
                thumbnail = true;
              } else if (fileextension === 'avi') {
                thumbnail = true;
              } else if (fileextension === 'mpg') {
                thumbnail = true;
              } else if (fileextension === 'mpeg') {
                thumbnail = true;
              } else if (fileextension === 'wmv') {
                thumbnail = true;
              } else if (fileextension === 'mp4') {
                thumbnail = true;
              } else {
                thumbnail = false;
              }
            }
          }
          // tslint:disable-next-line: no-use-before-declare
          startnextfile();
        }
      });
    };

    const loopForSlot = (fileitem: any) => {
      setTimeout(() => {
        if (this.checkUploadSlot() > 0) {
          let startit = false;

          if (index > 0) {
            if (this.uploadqueue[index - 1].progress !== 0) {
              startit = true;
            }
          } else {
            startit = true;
          }

          if (startit === true) {
            // this.logger.debug('loopstart');
            this.uploadqueue[index].progress = 1;
            this.fs.spaceinfo((result) => {
              if (result === 'success') {
                upload(fileitem);
                // $files: an array of files selected, each file has name, size, and type.
                /*
                if ((this.fs.usedfilespace / 1024 / 1024) <= service.maxfilespace) {
                  this.startUpload(fileitem, index);
                } else {
                  this.logger.debug('filespace FULL');
                }
                */
              } else {
                this.logger.debug('NO SPACEINFO RECEIVED');
              }
            });
          } else {
            loopForSlot(fileitem);
          }
        } else {
          loopForSlot(fileitem);
        }
      }, 1000);
    };

    const startnextfile = () => {
      if (this.checkUploadSlot() > 0) {

        let startit = false;
        let foundnewfiletoprocess = false;

        _.each(this.uploadqueue, (element, loopindex, list) => {
          // tslint:disable-next-line: max-line-length
          if ((element.progresstext !== 'error') && (element.progresstext !== 'finished') && (element.progress === 0) && (!foundnewfiletoprocess)) {
            index = loopindex;
            foundnewfiletoprocess = true;
          }
        });

        if (foundnewfiletoprocess) {
          if (index > 0) {
            if (this.uploadqueue[index - 1].progress !== 0) {
              startit = true;
            }
          } else {
            startit = true;
          }
        }

        if (startit === true) {
          // this.logger.debug('direct start');
          this.uploadqueue[index].progress = 1;
          this.uploadqueue[index].progresstext = '0%';
          upload(file);
          /*
          service.spaceinfo((result) => {
            if (result === 'success') {
              // $files: an array of files selected, each file has name, size, and type.
              if ((service.usedfilespace / 1024 / 1024) <= service.maxfilespace) {
                startUpload(file);
              } else {
                this.logger.debug('filespace FULL');
              }
            } else {
              this.logger.debug('NO SPACEINFO RECEIVED');
            }
          });
          */
        }
      }
    };

    startnextfile();
  }

  public checkUploadSlot() {
    // this.logger.debug('checkuploadslot');
    let slotsavailable = this.maxuploadslots;

    _.each(this.uploadqueue, (element) => {
        if ((element.progresstext !== 'error') && (element.progresstext !== 'finished') && (element.progress !== 0)) {
            slotsavailable = slotsavailable - 1;
        }
    });

    return slotsavailable;
  }

  constructor(private http: HttpClient, public fs: FileStoreService, private logger: NGXLogger) { }
}
