<div id="dataviewer" *ngIf="dvc.visible | async">
  <div id="dataviewer_content" class="container">

    <wol-header [filename]="dvc.dataobject1.name"></wol-header>

    <div class="row" style="width:100%; height:100%; margin:0px;" (resized)="onResized($event)">
      <div [hidden]="((dvc.modus | async) !== 'analysis') && ((dvc.modus | async) !== 'dataset')" class="col-md-auto" style="max-height:100%; width:200px; padding:0px;" id="dataviewerleft" #dataviewerleft>
        <wol-sidebar-left></wol-sidebar-left>
      </div>
      <div
        class="col"
        id="maincanvas"
        #maincanvas
        (panstart)="onPanStart($event)"
        (panmove)="onPanMove($event)"
        (panend)="onPanEnd($event)"
        (press)="onPress($event)"
        (pressup)="onPressUp($event)"
        (keydown)="handleKeyboardEvent($event)"
        tabindex="0"
      >
      </div>
      <div [hidden]="false" class="col-md-auto" style="padding:0px;max-height:100%;" id="dataviewerright" #dataviewerright>
        <wol-sidebar-right></wol-sidebar-right>
      </div>


      <div *ngIf="dvc.loading | async" style="position:absolute; top: 30%; right: 50%; margin-right: -100px;width:200px;min-height:200px; padding:20px; text-align: center; background-color:rgba(255, 255, 255, 1.0);border:1px solid #ccc;">
        <wol-loader></wol-loader>
        <div style="font-size:0.8em;">
          Loading Data
        </div>
      </div>
      <div *ngIf="dvc.savingdata" style="position:absolute; top: 30%; right: 50%; margin-right: -100px;width:200px;min-height:200px; padding:20px; text-align: center; background-color:rgba(255, 255, 255, 1.0);border:1px solid #ccc;">
        <wol-loader></wol-loader>
        <div style="font-size:0.8em;">
          Saving Data
        </div>
      </div>

    </div>

    <wol-footer></wol-footer>

  </div>
</div>
