import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FilebrowserService } from '../filebrowser.service';

@Component({
  selector: 'wol-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  public toolbarform: FormGroup;

  constructor(private fb: FormBuilder, public fbs: FilebrowserService) { }

  ngOnInit() {
    this.initForm();
  }

  private initForm() {
    this.toolbarform = this.fb.group({
      searchstring: [''],
      listviewmodus: [{ value: 'smallthumbnails', onlySelf: true}, Validators.required]
    });
    this.toolbarform.controls.listviewmodus.setValue('smallthumbnails');

    this.fbs.listviewmodusUpdate(this.toolbarform.value.listviewmodus);
    this.fbs.searchstringUpdate(this.toolbarform.value.searchstring);

    this.toolbarform.valueChanges.subscribe(val => {
      this.fbs.listviewmodusUpdate(this.toolbarform.value.listviewmodus);
      this.fbs.searchstringUpdate(this.toolbarform.value.searchstring);
    });

    this.fbs.searchstring.subscribe({next: searchstring => {
      this.toolbarform.controls.searchstring.setValue(searchstring, { emitEvent: false });
    }}
    );
    this.fbs.listviewmodus.subscribe({next: listviewmodus => {
      this.toolbarform.controls.listviewmodus.setValue(listviewmodus, { emitEvent: false });
    }}
    );
  }


}
