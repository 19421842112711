import { JobsModule } from './../jobs/jobs.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatsviewerComponent } from './statsviewer/statsviewer.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';



@NgModule({
  declarations: [StatsviewerComponent, FooterComponent, HeaderComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    JobsModule
  ],
  exports: [
    StatsviewerComponent
  ]
})
export class StatsviewerModule { }
