import { FileselectorControllerService } from './../../shared/services/fileselector-controller.service';
import { FilebrowserService } from './../../filebrowser/filebrowser.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(public fsc: FileselectorControllerService) { }

  ngOnInit() {
  }

}
