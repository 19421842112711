<div id="fileuploader" class="container" *ngIf="fuc.visible | async">
  <div class="row fileuploader-header">
    <div class="col">
      <fa-icon [icon]="['far', 'upload']"></fa-icon> {{fuc.uploadqueue.length}} uploads
    </div>
    <div class="col-md-auto" style="float:right; color: #888888; padding-right:0px;">
      <fa-icon *ngIf="fuc.extended | async" [icon]="['far', 'chevron-down']" (click)="fuc.minimize()"></fa-icon>
      <fa-icon *ngIf="!(fuc.extended | async)" [icon]="['far', 'chevron-up']" (click)="fuc.extend()"></fa-icon>
      <fa-icon [icon]="['far', 'times']" (click)="fuc.close()" style="margin-left:10px;"></fa-icon>
    </div>
  </div>
  <div class="row" *ngIf="fuc.extended | async" style="overflow-y: auto;height:200px; background-color: #fff;">
    <div class="container" style="margin:5px; padding:0;">
      <table>
        <tr class="fileuploader-datarow" *ngFor="let fileitem of fuc.uploadqueue">
          <td style="width:25px;max-width:25px;">
            <fa-icon *ngIf="fileitem.context === 'files'" [icon]="['far', 'hdd']" style="margin-left:5px;"></fa-icon>
            <fa-icon *ngIf="fileitem.context === 'dataset'" [icon]="['far', 'database']" style="margin-left:5px;"></fa-icon>
            <fa-icon *ngIf="fileitem.context === 'analysis'" [icon]="['far', 'flask']" style="margin-left:5px;"></fa-icon>
          </td>
          <td style="width:275px;max-width:275px;">
            {{fileitem.name}}
          </td>
          <td style="width:213px;max-width:213px;">
            <span *ngIf="fileitem.progresstext === 'finished'">finished</span>
            <span *ngIf="fileitem.progresstext === 'error'">error</span>
            <ngb-progressbar *ngIf="((fileitem.progresstext !== 'finished') && (fileitem.progresstext !== 'error'))" type="primary" [value]="fileitem.progress" showValue="true" height="10px"></ngb-progressbar>
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>
