import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';

import { SidebarNavigationService } from './../../shared/services/sidebar-navigation.service';
import { SidebarNavitem } from './../../shared/interfaces/sidebar-navitem';
import { SidebarNavigation } from './../../shared/interfaces/sidebar-navigation';
import * as _ from 'lodash';

@Component({
  selector: 'wol-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public sidebarExists: boolean;
  public sidebarTitle: string;
  private snId: number;
  public sidebarNavigations: SidebarNavigation [];
  public sidebarNavitems: SidebarNavitem[];

  constructor(
    private router: Router,
    private snService: SidebarNavigationService
  ) {
    this.sidebarNavigations = this.snService.getAll();

    router.events.pipe(
      filter((event) => event instanceof NavigationEnd)
    ).subscribe((event) => {
      this.snId = _.findIndex(this.sidebarNavigations, function(item) {
        return item.id === event['urlAfterRedirects'].split('/')[1];
      });

      if (this.snId >= 0) {
        this.sidebarExists = true;
        this.sidebarTitle = this.sidebarNavigations[this.snId].title;
        this.sidebarNavitems = this.sidebarNavigations[this.snId].navitems;
      } else {
        this.sidebarExists = false;
        this.sidebarTitle = '';
        this.sidebarNavitems = [];
      }

    });
  }

  ngOnInit() {}

}
