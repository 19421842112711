import { ToastmessageStoreService } from './toastmessage-store.service';
import { NGXLogger } from 'ngx-logger';
import { MapService } from './../../dataviewer/map.service';
import { LabeltoolsService } from './../../dataviewer/labeltools.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import * as THREE from 'three';
import * as _ from 'lodash';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { retry, map, catchError } from 'rxjs/operators';
import { ErrorhandlerService } from './errorhandler.service';
import { AccountService } from './account.service';
import * as pako from 'pako';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonmodalsComponent } from '../components/commonmodals/commonmodals.component';

@Injectable({
  providedIn: 'root'
})
export class DataviewerControllerService {

  public visibleSource = new BehaviorSubject<boolean>(false);
  public visible = this.visibleSource.asObservable();
  public loadingSource = new BehaviorSubject<boolean>(true);
  public loading = this.loadingSource.asObservable();
  public zoomSource = new BehaviorSubject<number>(100.0);
  public zoom = this.zoomSource.asObservable();
  public zoomPrevious: 100.0;
  public initialzoom = 100.0;
  public rerenderSource = new BehaviorSubject<boolean>(false);
  public rerender = this.rerenderSource.asObservable();
  public triggerLoadlabelSource = new BehaviorSubject<boolean>(false);
  public triggerLoadlabel = this.triggerLoadlabelSource.asObservable();
  public triggerSetopacitySource = new BehaviorSubject<boolean>(false);
  public triggerSetopacity = this.triggerSetopacitySource.asObservable();

  // select modus: 'filestorage', 'analysis', 'dataset', 'trainedalgorithm
  public modusSource = new BehaviorSubject<string>('filestorage');
  public modus = this.modusSource.asObservable();

  public savingdata = false;
  public undoavailable = false;
  public redoavailable = false;
  public pages = [];
  public inputtexture: THREE.DataTexture;
  public inputtexturewithdata: any;
  public overlaytexture: any;
  public datasettype = 'segmentation';
  public datasetid = '';
  public trainingdataindex: any;
  public trainingdata: any[];
  public changesmade = false;
  public scene = new THREE.Scene();

  public inputwidth = 0;
  public inputheight = 0;

  public dataobject1: any = {};
  public dataobject2: any = {};
  public originallabelpath: string;
  public resultlabelpath: string;
  public datasetclasses: any;
  public configLabelcolors: any[] = [];
  public activelayerindex = 0;
  public activelayercolor: any;
  public boxdetectionmarker: any[] = [];

  public visibleUpdate(value: boolean) {
    this.visibleSource.next(value);
  }
  public loadingUpdate(value: boolean) {
    this.loadingSource.next(value);
  }
  public modusUpdate(value: string) {
    this.modusSource.next(value);
  }
  public rerenderUpdate(value: boolean) {
    this.rerenderSource.next(value);
  }
  public triggerLoadlabelUpdate(value: boolean) {
    this.triggerLoadlabelSource.next(value);
  }
  public triggerSetopacityUpdate(value: boolean) {
    this.triggerSetopacitySource.next(value);
  }

  public zoomUpdate(value: number) {
    if (value <= 5) {
      value = 5;
    }
    if (this.zoomSource.getValue() !== value) {
      this.logger.debug('zoomUpdate');
      this.zoomSource.next(value);
    }
  }

  public dataobject1Update(path: string, name?: string, filesize?: number) {
    if (name) {
      this.dataobject1.name = name;
    }
    if (filesize) {
      this.dataobject1.filesize = filesize;
    }
    if (path) {
      if (this.modusSource.getValue() === 'trainedalgorithm') {
        // tslint:disable-next-line: max-line-length
        this.dataobject1.path = environment.apiurl + '/' + path + '?_t=' + this.account.account.authtoken;
      } else if (this.modusSource.getValue() === 'dataset') {
        // tslint:disable-next-line: max-line-length
        this.dataobject1.path = environment.apiurl + '/' + path + '?_t=' + this.account.account.authtoken;
      } else {
        // tslint:disable-next-line: max-line-length
        this.dataobject1.path = environment.apiurl + '/ufs_webview/' + this.account.account._id + path + '?_t=' + this.account.account.authtoken;
      }
    }
  }

  public dataobject2Update(path: string, name?: string, filesize?: number) {
    if (name) {
      this.dataobject2.name = name;
    }
    if (filesize) {
      this.dataobject2.filesize = filesize;
    }
    this.logger.debug(path);
    if (path) {
      // tslint:disable-next-line: max-line-length
      this.dataobject2.path = environment.apiurl + '/ufs_webview/' + this.account.account._id + path + '?_t=' + this.account.account.authtoken;
    }
  }

  public open() {
    this.visibleUpdate(true);
  }

  public close() {
    this.checkunsavedlabelchanges((result) => {
      if (result === true) {
        this.visibleUpdate(false);
      } else {
        this.logger.debug('discard changes denied');
      }
    });
  }

  public decimalToHex = (d, padding?) => {
    let hex = Number(d).toString(16);
    padding = typeof (padding) === 'undefined' || padding === null ? padding = 2 : padding;

    while (hex.length < padding) {
      hex = '0' + hex;
    }
    return hex;
  }

  public newpage = () => {
    if (this.inputwidth && this.inputheight) {
      this.logger.debug('inputwidth: ' + this.inputwidth);
      this.logger.debug('inputheight: ' + this.inputheight);
      // tslint:disable-next-line: max-line-length
      this.pages.push({index: this.pages.length, layers: [], visible: true, geometry: new THREE.PlaneGeometry( this.inputwidth, this.inputheight )});
      this.logger.debug('created new page');
    } else {
      console.error('inputwidth or inputheight for geometry not defined');
    }
  }

  public newlayer = (pageindex, layer) => {

    layer.plane = new THREE.Mesh( this.pages[pageindex].geometry, new THREE.MeshBasicMaterial({
      map: layer.texture,
      opacity: layer.opacity,
      transparent: true,
      side: THREE.DoubleSide,
      depthWrite: false
    }));
    this.pages[pageindex].layers.push(layer);
    // set 'selected' if it is the first layer
    if (this.pages[pageindex].layers.length === 1) {
      this.selectLayer(0);
    }
    this.logger.debug(this.datasettype);
    // for boxdetection only one layer needed
    if (this.datasettype !== 'boxdetection') {
      if (this.pages[pageindex].layers[this.pages[pageindex].layers.length - 1].show) {
        this.scene.add(this.pages[pageindex].layers[this.pages[pageindex].layers.length - 1].plane);
      }
      // tslint:disable-next-line: max-line-length
      this.pages[pageindex].layers[this.pages[pageindex].layers.length - 1].plane.position.setZ(0 - (this.pages[pageindex].layers.length / 1000));
    } else {
      this.logger.debug('STOP1');
      this.logger.debug(this.pages[pageindex].layer);
      this.logger.debug(this.pages[pageindex].layers.length - 1);
      if (this.pages[pageindex].layers[this.pages[pageindex].layers.length - 1].label === false) {
        if (this.pages[pageindex].layers[this.pages[pageindex].layers.length - 1].show) {
          this.scene.add(this.pages[pageindex].layers[this.pages[pageindex].layers.length - 1].plane);
          this.pages[pageindex].layers[this.pages[pageindex].layers.length - 1].plane.position.setZ(-(1 / 1000));
        }
      }
    }
    this.logger.debug('created new layer');
  }

  public loadNext() {
    if (this.modusSource.value === 'dataset') {
      this.checkunsavedlabelchanges((result) => {
        if (result === true) {
          this.logger.debug('loadnext');
          if ((this.trainingdataindex + 1) < this.trainingdata.length) {
            this.trainingdataindex++;
            this.loadingUpdate(true);
            this.dataobject1Update(this.trainingdata[this.trainingdataindex].image, this.trainingdata[this.trainingdataindex].filename);
            this.visibleUpdate(true);
            this.changesmade = false;
          }
        } else {
          this.logger.debug('discard changes denied');
        }
      });
    }
  }

  public loadPrevious() {
    if (this.modusSource.value === 'dataset') {
      this.checkunsavedlabelchanges((result) => {
        if (result === true) {
          this.logger.debug('loadprevious');
          if (this.trainingdataindex > 0) {
            this.trainingdataindex--;
            this.loadingUpdate(true);
            this.dataobject1Update(this.trainingdata[this.trainingdataindex].image, this.trainingdata[this.trainingdataindex].filename);
            this.visibleUpdate(true);
            this.changesmade = false;
          }
        } else {
          this.logger.debug('discard changes denied');
        }
      });
    }
  }

  public checkunsavedlabelchanges(callback) {
    this.logger.debug(this.changesmade);
    if (this.changesmade) {
      const modalRef = this.modalService.open(CommonmodalsComponent, {ariaLabelledBy: 'modal-basic-title'});
      modalRef.componentInstance.type = 'ok';
      modalRef.componentInstance.title = 'Confirm';
      modalRef.componentInstance.text = 'Label Changed! Are you sure you want to discard your changes?';
      modalRef.result.then((result) => {
        this.logger.debug(`closed: ${result}`);
        callback(true);
      }, (reason) => {
        this.logger.debug(`dismissed: ${reason}`);
        callback(false);
      });
    } else {
      callback(true);
    }
  }

  public saveLabel() {
    if (this.modusSource.value === 'dataset') {
      this.logger.debug('savelabel');
      this.loadingUpdate(true);

      const generateMask = (callback) => {
        const originalW = this.inputtexture.image.width;
        const originalH = this.inputtexture.image.height;
        const imagearray = new Array();
        for (let y = 0; y < originalH; y++) {
          imagearray[y] = new Array(originalW);
          // loop through each column
          for (let x = 0; x < originalW; x++) {
            imagearray[y][x] = 0;
          }
        }

        _.each(this.pages[0].layers, (layer, index, list) => {
          if (layer.label) {
            this.logger.debug(layer.classId);

            for (let y = 0; y < originalH; y++) {
              // loop through each column
              for (let x = 0; x < originalW; x++) {
                // tslint:disable-next-line: max-line-length
                if (((layer.texture.image.data[(((originalW * (originalH - y - 1)) + x) * 4) + 0] < 255) || (layer.texture.image.data[(((originalW * (originalH - y - 1)) + x) * 4) + 1] < 255) || (layer.texture.image.data[(((originalW * (originalH - y - 1)) + x) * 4) + 2] < 255)) && (layer.texture.image.data[(((originalW * (originalH - y - 1)) + x) * 4) + 3] > 0)) {
                  imagearray[y][x] = layer.classId;
                }
              }
            }
          }
        });
        this.logger.debug(imagearray);
        callback(imagearray);
      };

      const callUpdate = (result) => {
        this.logger.debug(result);

        // var binaryString = pako.gzip(JSON.stringify(result));
        // var binaryString = pako.gzip('hello');
        // var temp_result = JSON.parse(JSON.stringify(result));
        const tempResult = result.slice();

        if (this.datasettype === 'boxdetection') {
          _.each(tempResult, (item, index) => {
            // data[index].x = DataviewerdataService.inputtexture.image.width-data[index].x-1;
            tempResult[index].ymin = this.inputtexture.image.height - tempResult[index].ymin - 1;
            tempResult[index].ymax = this.inputtexture.image.height - tempResult[index].ymax - 1;
          });
        }

        let binaryString = pako.deflate(JSON.stringify(tempResult), { to: 'string' });

        this.logger.debug('binaryString build');

        const datasetclasses = [];
        // tslint:disable-next-line: max-line-length
        datasetclasses.push({class_id: 0, name: 'background', opacity: 1.0, show: 1, radius: 10, linewidth: 10, color_red: 255, color_green: 255, color_blue: 255});

        _.each(this.pages[0].layers, (layer, index, list) => {
          if (layer.label === true) {
            this.logger.debug(['OPACITY:', layer.opacity]);
            // tslint:disable-next-line: max-line-length
            datasetclasses.push({class_id: layer.classId, name: layer.name, opacity: layer.opacity * 100, show: layer.show, radius: layer.radius, linewidth: layer.linewidth, color_red: layer.color_red, color_green: layer.color_green, color_blue: layer.color_blue});
          }
        });
        const tempDatasetclasses = JSON.stringify(datasetclasses);

        this.logger.debug(tempResult);
        this.logger.debug(tempDatasetclasses);

        this.sendSaveLabels(binaryString, tempDatasetclasses)
        .subscribe(
          (res: any) => {
            this.logger.debug(res);
            this.toastService.show('Labels saved!', 'Labels successfully saved.');
            this.datasetclasses = datasetclasses;
            // this.loadingUpdate(false);
            this.changesmade = false;
            result = null;
            binaryString = null;
            this.changesmade = false;
            this.loadLabel();
            return true;
          },
          (err) => {
            this.logger.debug(err);
            this.toastService.show('ERROR: Labels not saved!', 'Could not save labels.');
            this.logger.debug('saveLabelError');
            result = null;
            binaryString = null;
            return false;
          }
        );
      };

      if (this.datasettype === 'segmentation') {
        generateMask(callUpdate);
      } else if (this.datasettype === 'boxdetection') {
        let xmin: number;
        let xmax: number;
        let ymin: number;
        let ymax: number;
        const tempBoxdetectionmarker = [];
        _.each(this.boxdetectionmarker, (item, index, list) => {
          this.logger.debug(item);
          // tslint:disable-next-line: max-line-length
          if (Math.ceil(item.children[0].geometry.vertices[0].x + (this.inputtexture.image.width / 2)) <= Math.ceil(item.children[0].geometry.vertices[2].x + (this.inputtexture.image.width / 2))) {
            xmin = Math.ceil(item.children[0].geometry.vertices[0].x + (this.inputtexture.image.width / 2));
            xmax = Math.ceil(item.children[0].geometry.vertices[2].x + (this.inputtexture.image.width / 2));
          } else {
            xmin = Math.ceil(item.children[0].geometry.vertices[2].x + (this.inputtexture.image.width / 2));
            xmax = Math.ceil(item.children[0].geometry.vertices[0].x + (this.inputtexture.image.width / 2));
          }

          // tslint:disable-next-line: max-line-length
          if (Math.ceil(item.children[0].geometry.vertices[0].y + (this.inputtexture.image.height / 2)) <= Math.ceil(item.children[0].geometry.vertices[2].y + (this.inputtexture.image.height / 2))) {
            ymin = Math.ceil(item.children[0].geometry.vertices[0].y + (this.inputtexture.image.height / 2));
            ymax = Math.ceil(item.children[0].geometry.vertices[2].y + (this.inputtexture.image.height / 2));
          } else {
            ymin = Math.ceil(item.children[0].geometry.vertices[2].y + (this.inputtexture.image.height / 2));
            ymax = Math.ceil(item.children[0].geometry.vertices[0].y + (this.inputtexture.image.height / 2));
          }

          this.logger.debug(xmin + ' ' + xmax + ' ' + ymin + ' ' + ymax);

          tempBoxdetectionmarker.push({class_id: item.classId,
              xmin,
              xmax,
              ymin,
              ymax});
        });
        callUpdate(tempBoxdetectionmarker);
      }
    }
  }

  public loadLabel() {
    if (this.modusSource.value === 'dataset') {
      this.checkunsavedlabelchanges((result) => {
        if (result === true) {
          this.logger.debug('loadlabel');
          this.loadingUpdate(true);
          this.triggerLoadlabelUpdate(true);
          this.changesmade = false;
        } else {
          this.logger.debug('changes discard denied');
        }
      });
    }
  }

  public selectLayer = (layerindex) => {
    this.logger.debug('SELECT LAYER DVC: ' + layerindex);
    // set activelayerindex with respect to reversed order/indes in template ng-repeat
    this.activelayerindex = layerindex;

    _.each(this.pages[0].layers, (layer, index, list) => {
      layer.selected = false;
    });
    this.pages[0].layers[this.activelayerindex].selected = true;
    // tslint:disable-next-line: max-line-length
    this.activelayercolor = '#' + this.decimalToHex(this.pages[0].layers[this.activelayerindex].color_red) + this.decimalToHex(this.pages[0].layers[this.activelayerindex].color_green) + this.decimalToHex(this.pages[0].layers[this.activelayerindex].color_blue);
    this.logger.debug(this.activelayercolor);
    this.triggerSetopacityUpdate(true);
  }

  public showLayer = (selectedindex) => {
    _.each(this.pages[0].layers, (layer, index, list) => {
      if (index === selectedindex) {
        if (layer.show === 1) {
          layer.show = 0;
        } else {
          layer.show = 1;
        }
      }
    });

    if (this.datasettype === 'boxdetection') {

      _.each(this.boxdetectionmarker, (item, index, list) => {
        if (this.modusSource.getValue() === 'trainedalgorithm') {
          // tslint:disable-next-line: max-line-length
          if ((this.pages[0].layers[selectedindex].classId === item.classId) && (this.pages[0].layers[selectedindex].trained === item.trained)) {
            item.visible = !item.visible;
          }
        } else {
          this.logger.debug(item.classId);
          if (this.pages[0].layers[selectedindex].classId === item.classId) {
            item.visible = !item.visible;
          }
        }
      });

      _.each(this.boxdetectionmarker, (item, index, list) => {
        this.scene.remove( item );
        if (item.visible === true) {
          this.scene.add( item );
        }
      });
    }
    this.updatelayerorder(0);
  }

  public changeBoxOpacity = () => {
    _.each(this.boxdetectionmarker, (item, index, list) => {
      if (this.pages[0].layers[this.activelayerindex].classId === item.classId) {
        // this.logger.debug(item.children);
        item.children[1].material.opacity = this.pages[0].layers[this.activelayerindex].plane.material.opacity;
      }
    });

    _.each(this.boxdetectionmarker, (item, index, list) => {
      this.scene.remove( item );
      if (item.visible === true) {
        this.scene.add( item );
      }
    });
  }

  public updatelayerorder = (pageindex: string | number) => {
    _.each(this.pages[pageindex].layers, (layer, index, list) => {
      if ((this.datasettype !== 'boxdetection') || (layer.label === false)){
        this.scene.add(layer.plane);
        layer.plane.position.setZ(0 - (parseInt(index, 10)));
        if (!layer.show) {
          this.scene.remove(layer.plane);
        }
      }
    });
    this.rerenderUpdate(true);
  }

  public interpretAsLabel = (selectedindex: number, newvalue: boolean) => {
    this.loadingUpdate(true);

    _.each(this.pages[0].layers, (layer, index, list) => {
      if (layer.label === true) {
        layer.interpretaslabel = newvalue;
        if (layer.interpretaslabel === true) {
          layer.texture.image.fulldata = new Uint8Array(layer.texture.image.data);
          _.times(this.inputtexture.image.height, (y) => {
            _.times(this.inputtexture.image.width, (x) => {
              // tslint:disable-next-line: max-line-length
              if (((layer.texture.image.data[(((this.inputtexture.image.width * y) + x) * 4) + 0] < 225) || (layer.texture.image.data[(((this.inputtexture.image.width * y) + x) * 4) + 1] < 225) || (layer.texture.image.data[(((this.inputtexture.image.width * y) + x) * 4) + 2] < 225)) && (layer.texture.image.data[(((this.inputtexture.image.width * y) + x) * 4) + 3] > 0)) {
                // nothing
              } else {
                layer.texture.image.data[(((this.inputtexture.image.width * y) + x) * 4)] = 255;
                layer.texture.image.data[(((this.inputtexture.image.width * y) + x) * 4) + 1] = 255;
                layer.texture.image.data[(((this.inputtexture.image.width * y) + x) * 4) + 2] = 255;
                layer.texture.image.data[(((this.inputtexture.image.width * y) + x) * 4) + 3] = 0;
              }
            });
          });
          layer.texture.needsUpdate = true;
          this.rerenderUpdate(true);
          this.loadingUpdate(false);
        } else {
          layer.texture.image.data = new Uint8Array(layer.texture.image.fulldata);
          layer.texture.needsUpdate = true;
          this.rerenderUpdate(true);
          this.loadingUpdate(false);
        }
      }
    });
  }

  getTrainedalgorithmLabels(requestedlabel): Observable<any> {
    let labelpath = this.originallabelpath;

    if (requestedlabel === 'originallabel') {
      labelpath = this.originallabelpath;
    } else if (requestedlabel === 'resultlabel') {
      labelpath = this.resultlabelpath;
    }
    this.logger.debug(['get trainedalgorithm labels', labelpath, labelpath.split('wolution.ai/')[1]]);
    return this.http.post<any[]>(
      `${environment.apiurl}/trainedalgorithms/loadlabel`,
      {filepath: labelpath.split('wolution.ai/')[1]}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  getDatasetLabels(): Observable<any> {
    this.logger.debug('get dataset labels');
    return this.http.post<any[]>(
      `${environment.apiurl}/datasets/loadlabel`,
      {datasetid: this.datasetid, filename: this.trainingdata[this.trainingdataindex].filename, type: this.datasettype}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  getObjectInfo(position): Observable<any> {
    this.logger.debug('get object info');
    return this.http.post<any[]>(
      `${environment.apiurl}/analyses/objectinfo`,
      {position}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  sendSaveLabels(binaryString, tempDatasetclasses): Observable<any> {
    this.logger.debug('send save labels');
    return this.http.post<any[]>(
      `${environment.apiurl}/datasets/savelabel`,
      // tslint:disable-next-line: max-line-length
      {datasetid: this.datasetid, filename: this.trainingdata[this.trainingdataindex].filename, label: binaryString, datasetclasses: tempDatasetclasses}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  sendSaveLayersettings(layersettings, datasetid): Observable<any> {
    this.logger.debug('send save layersettings');
    return this.http.post<any[]>(
      `${environment.apiurl}/datasets/updateLayersettings/${datasetid}`,
      // tslint:disable-next-line: max-line-length
      {datasetclasses: layersettings}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  constructor(
    private logger: NGXLogger,
    private http: HttpClient,
    public account: AccountService,
    private modalService: NgbModal,
    private toastService: ToastmessageStoreService) { }
}
