<div class="container" style="position: -webkit-sticky; /* Safari */ position: sticky; top: 20px; margin-left:20px;margin-right:20px;">
  <div class="row">
    <button (click)="fbs.requestupload()" class="btn btn-primary btn-sm"><fa-icon [icon]="['fas', 'upload']"></fa-icon> Upload files</button>
    <button (click)="fbs.requestcreatefolder()" class="btn btn-primary btn-sm" style="margin-left:5px;"><fa-icon [icon]="['fas', 'plus']"></fa-icon> Create folder</button>
  </div>
  <div *ngIf="fbs.selected.length > 1" class="row" style="margin-top:20px;">
    <p>
      {{fbs.countSelectedFolders()}} folders, {{fbs.countSelectedFiles()}} files
    </p>
  </div>
  <div *ngIf="fbs.selected.length == 1" class="row" style="margin-top:20px; margin-bottom:20px;">
    <div *ngIf="fbs.selected[0].type != 'folder'" class="container" style="padding-left:0px;">
      <div class="row">
        <div class="col shorten-long-text" ngbTooltip="{{fbs.selected[0].name}}"><b>{{fbs.selected[0].name}}</b></div>
      </div>
      <div class="row" >
        <div class="col-md-4">Size:</div>
        <div class="col shorten-long-text">{{fbs.selected[0].size / 1024 | number: '1.0-0'}} kB</div>
      </div>
      <div class="row" *ngIf="fbs.selected[0].resolution">
        <div class="col-md-4">Resolution:</div>
        <div class="col shorten-long-text">{{fbs.selected[0].resolution}} px</div>
      </div>
      <div class="row" style="margin-bottom: 10px; margin-top: 10px;">
        <div class="col" style="width: 128px;">
          <wol-thumbnail [maxsize]="'128'" [path]="'ufs_128px'" [filename]="fbs.selected[0].path" (click)="openDataviewer()"></wol-thumbnail>
        </div>
      </div>
    </div>

    <div *ngIf="fbs.selected[0].type == 'folder'" class="container" style="padding-left:0px;">
      <div class="row">
        <div class="col shorten-long-text" ngbTooltip="{{fbs.selected[0].name}}"><b>{{fbs.selected[0].name}}</b></div>
      </div>
    </div>
  </div>

  <div *ngIf="fbs.selected.length > 0" class="row" style="margin-top:5px;">
    <button (click)="fdc.requestnewdownload(fbs.currentpath, fbs.selected)" class="btn btn-info btn-sm previewbar-actionbutton"><fa-icon [icon]="['fas', 'download']"></fa-icon> Download</button>
  </div>
  <div *ngIf="fbs.selected.length > 0" class="row" style="margin-top:5px;">
    <button (click)="fbs.requestdelete(fbs.selected)" class="btn btn-info btn-sm previewbar-actionbutton"><fa-icon [icon]="['fas', 'trash']"></fa-icon> Delete</button>
  </div>
  <div *ngIf="fbs.selected.length == 1" class="row" style="margin-top:5px;">
    <button (click)="fbs.requestrename(fbs.selected)" class="btn btn-info btn-sm previewbar-actionbutton"><fa-icon [icon]="['fas', 'pen-square']"></fa-icon> Rename</button>
  </div>
  <div *ngIf="fbs.selected.length > 0" class="row" style="margin-top:5px;">
    <button (click)="fbs.requestmove(fbs.selected)" class="btn btn-info btn-sm previewbar-actionbutton"><fa-icon [icon]="['fas', 'arrows']"></fa-icon> Move</button>
  </div>
  <div *ngIf="fbs.selected.length > 0" class="row" style="margin-top:5px;">
    <button (click)="fbs.requestcopy(fbs.selected)" class="btn btn-info btn-sm previewbar-actionbutton"><fa-icon [icon]="['fas', 'copy']"></fa-icon> Copy</button>
  </div>
</div>

