<div id="filedownloader" class="container" *ngIf="fdc.visible | async">
  <div class="row filedownloader-header">
    <div class="col">
      <fa-icon [icon]="['far', 'download']"></fa-icon> {{fdc.content.title}}
    </div>
  </div>
  <div class="row align-items-center" style="overflow-y: auto; background-color: #fff;">
    <div class="col-md-3">
      <div *ngIf="false; else loading"></div>
    </div>
    <div class="col-md-9">
      {{fdc.content.message}}
    </div>

  </div>
</div>

<ng-template #loading>
  <div class="d-flex justify-content-center" style="margin: 15px;">
    <div class="spinner-grow text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
