<button class="btn btn-secondary" (click)="showmenu = !showmenu" style="color: #ffffff;" #toggleButton>
  <div style="float:left;margin-right:4px;">
    <fa-layers [fixedWidth]="true" class="color_secondary" style="font-size:1.4em;pointer-events: none;">
      <fa-icon [icon]="['fas', 'circle']" [inverse]="true"></fa-icon>
      <fa-icon [icon]="['fas', 'user']" transform="shrink-6"></fa-icon>
    </fa-layers>
  </div>
  <div style="float: left;" *ngIf="accountService.account">
    <span *ngIf="((accountService.account.firstName) && (accountService.account.lastName))">
      {{accountService.account.firstName}} {{accountService.account.lastName}}
    </span>
    <span *ngIf="!((accountService.account.firstName) && (accountService.account.lastName))">
      {{accountService.account.username}}
    </span>
  </div>
</button>
<div *ngIf="showmenu" class="container" id="statusmenu" #statusmenu>
  <div class="row statusmenu_row" style="padding-left:10px;">
    <div class="col-md-3 color_secondary" style="text-align: center; font-size:75px;padding:0px;">
      <fa-layers [fixedWidth]="true">
        <fa-icon [icon]="['fas', 'circle']"></fa-icon>
        <fa-icon [inverse]="true" [icon]="['fas', 'user']" [inverse]="true" transform="shrink-6"></fa-icon>
      </fa-layers>
    </div>
    <div class="col-md-9" style="padding-top:10px;">
      <b>{{accountService.account?.displayName}}</b><br>
      Username: {{accountService.account.username}}<br>
      Email: {{accountService.account.email}}<br><br>
      <button class="btn btn-sm btn-primary" [routerLink]="['/account']"><fa-icon [icon]="['far', 'user']" style="margin-right:5px;"></fa-icon>Show Account</button>
      <button class="btn btn-sm btn-primary" [routerLink]="['/dashboard']" style="margin-left:10px;">Dashboard</button>
    </div>
  </div>
  <div class="row statusmenu_row nomargin">
    <div class="col-md-2 statusmenu_pt5"><fa-icon [icon]="['far', 'envelope']"></fa-icon></div>
    <div class="col-md-7 statusmenu_pt5">{{ns.ownNewNotificationsCount}} new notifications</div>
    <div class="col-md-3"><button class="btn btn-sm btn-outline-secondary" [routerLink]="['/account', 'notifications']">show</button></div>
  </div>
  <div class="row statusmenu_row nomargin">
    <div class="col-md-2 statusmenu_pt5"><fa-icon [icon]="['far', 'flask']"></fa-icon></div>
    <div class="col-md-7 statusmenu_pt5">Analyses</div>
    <div class="col-md-3"><button class="btn btn-sm btn-outline-secondary" [routerLink]="['/analyses', 'overview']">show</button></div>
  </div>
  <!--
  <div class="row statusmenu_row nomargin">
    <div class="col-md-2 statusmenu_pt5"><fa-icon [icon]="['far', 'wrench']"></fa-icon></div>
    <div class="col-md-7 statusmenu_pt5">Testboard</div>
    <div class="col-md-3"><button class="btn btn-sm btn-outline-secondary" [routerLink]="['/support', 'tutorials', 'workflows']">show</button></div>
  </div>
  -->
  <div class="row statusmenu_row nomargin">
    <div class="col-md-2 statusmenu_pt5"><fa-icon [icon]="['far', 'hdd']"></fa-icon></div>
    <div class="col-md-7 statusmenu_pt5">{{(accountService.account.useddiskspace / 1024 / 1024 ) | number: '1.2-2'}} / {{accountService.account.diskspace | number: '1.2-2'}} GB</div>
    <div class="col-md-3"><button class="btn btn-sm btn-outline-secondary" [routerLink]="['/data', 'overview']">show</button></div>
  </div>
  <div class="row statusmenu_row nomargin">
    <div class="col-md-2 statusmenu_pt5"><fa-icon [icon]="['far', 'money-bill']"></fa-icon></div>
    <div class="col-md-7 statusmenu_pt5">{{accountService.account.coins}} Coins</div>
    <div class="col-md-3"><button class="btn btn-sm btn-outline-secondary" [routerLink]="['/account', 'coins']">show</button></div>
  </div>
  <div class="row statusmenu_footer nomargin">
    <div class="col-md-7"></div>
    <div class="col-md-5">
      <button class="btn btn-primary" (click)="logout()"><fa-icon [icon]="['far', 'sign-out']"></fa-icon> Logout</button>
    </div>
  </div>
</div>

