import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wol-modal-move',
  templateUrl: './modal-move.component.html',
  styleUrls: ['./modal-move.component.scss']
})
export class ModalMoveComponent implements OnInit {

  @Input() title: string;
  @Input() type: string;

  public selectedfolder: any;

  public setSelectedFolder = (folder: any) => {
    this.selectedfolder = folder.data;
  }
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

}
