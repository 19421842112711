import { FileStoreService } from './../../shared/services/file-store.service';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from 'lodash';

@Component({
  selector: 'wol-modal-copy',
  templateUrl: './modal-copy.component.html',
  styleUrls: ['./modal-copy.component.scss']
})
export class ModalCopyComponent implements OnInit {

  @Input() title: string;
  @Input() type: string;

  public selectedfolder: any;

  public setSelectedFolder = (folder: any) => {
    this.selectedfolder = folder.data;
  }

  constructor(public activeModal: NgbActiveModal, public fs: FileStoreService) { }

  ngOnInit() {
  }

}
