<div id="sidebar_right" class="container d-flex flex-column" style="margin-right:0px; width:240px;min-height:300px; height: 100%; padding:0px; padding-bottom:0px; border-left:0px solid #ccc;border-bottom:0px solid #cccccc;">
  <div class="flex-shrink-0 nomargin" style="background-color:rgba(255, 255, 255, 1.0);">
    <div id="minimapbox" style="margin:19px;position:relative; border:1px solid #cccccc;">
      <div id="minimapcanvas" #minimapcanvas style="background-color:#ffffff; width:200px;position:relative;">
      <angular-cropper #minimapAreaselect
        [cropperOptions]="minimapService.cropperConfig"
        (crop)="minimapService.minimapareaselectChange($event)"
        (cropend)="minimapService.minimapareaselectCropend($event)"
        (cropstart)="minimapService.minimapareaselectCropstart($event)"
        (cropmove)="minimapService.minimapareaselectCropmove($event)"
        (pan)="onPan($event)"
        [imageUrl]="'assets/images/transparentpixel.png'"
        style="position:absolute;">
      </angular-cropper>
      </div>
    </div>
  </div>
  <div class="flex-shrink-0 nomargin" style="background-color:rgba(255, 255, 255, 1.0);">
    <div *ngIf="(dvc.modus | async) === 'filestorage'" style="font-size:0.8em; height:100%; width:100%;">
      <div class="container" style="padding-left:35px; padding-right:25px;">
        <div class="row">
          <div class="col-md-3" style="padding-left: 0;">Filename:</div>
          <div class="col shorten-long-text" ngbTooltip="{{dvc.dataobject1.name}}">{{dvc.dataobject1.name}}</div>
        </div>
        <div class="row">
          <div class="col-md-3" style="padding-left: 0;">Resolution:</div>
          <div class="col shorten-long-text">{{dvc.dataobject1.width}} x {{dvc.dataobject1.height}} Pixel</div>
        </div>
        <div class="row">
          <div class="col-md-3" style="padding-left: 0;">Filesize:</div>
          <div class="col shorten-long-text">{{dvc.dataobject1.filesize / 1024 | number: '1.0-0'}} kByte</div>
        </div>
      </div>
    </div>
    <div *ngIf="(dvc.modus | async) === 'analysis'" style="font-size:0.8em; width:100%;">
      <div class="container" style="padding-left:35px; padding-right:25px;margin-bottom:25px;">
        <div class="row">
          <b>Input</b>
        </div>
        <div class="row">
          <div class="col-md-3" style="padding-left: 0;">Filename:</div>
          <div class="col shorten-long-text" ngbTooltip="{{dvc.dataobject1.name}}">{{dvc.dataobject1.name}}</div>
        </div>
        <div class="row">
          <div class="col-md-3" style="padding-left: 0;">Resolution:</div>
          <div class="col shorten-long-text">{{dvc.dataobject1.width}} x {{dvc.dataobject1.height}} Pixel</div>
        </div>

        <div class="row" style="margin-top:10px;">
          <b>Output</b>
        </div>
        <div class="row">
          <div class="col-md-3" style="padding-left: 0;">Filename:</div>
          <div class="col shorten-long-text" ngbTooltip="{{dvc.dataobject2.name}}">{{dvc.dataobject2.name}}</div>
        </div>
        <div class="row">
          <div class="col-md-3" style="padding-left: 0;">Resolution:</div>
          <div class="col shorten-long-text">{{dvc.dataobject2.width}} x {{dvc.dataobject2.height}} Pixel</div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex-shrink-0 nomargin" *ngIf="(dvc.modus | async) === 'dataset'" style="background-color:rgba(255, 255, 255, 1.0); padding-left:15px;">
    <div *ngIf="dvc.datasettype==='segmentation'" style="padding: 3px 10px; padding-top:5px; width:80px; font-size:0.9em;" >
      <!--<button style="margin:2px;width:33px;" class="btn btn-default btn-sm" ng-model="dataviewerlabeltoolsService.drawmode" ng-click="undo()"><i class="fal fa-undo"></i></button>-->
    </div>
    <div *ngIf="dvc.datasettype==='segmentation'" class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="labeltoolsService.drawmode">
      <label ngbButtonLabel class="btn-sm btn-primary" style="margin:2px;width:33px;">
        <input ngbButton type="radio" [value]="'cursor'"><fa-icon [icon]="['far', 'mouse-pointer']"></fa-icon>
      </label>
      <label ngbButtonLabel class="btn-sm btn-primary" style="margin:2px;width:33px;">
        <input ngbButton type="radio" [value]="'eraser'"><fa-icon [icon]="['far', 'eraser']"></fa-icon>
      </label>
      <label ngbButtonLabel class="btn-sm btn-primary" style="margin:2px;width:33px;">
        <input ngbButton type="radio" [value]="'pencil'"><fa-icon [icon]="['far', 'pencil']"></fa-icon>
      </label>
      <label ngbButtonLabel class="btn-sm btn-primary" style="margin:2px;width:33px;">
        <input ngbButton type="radio" [value]="'magicwand'"><fa-icon [icon]="['far', 'magic']"></fa-icon>
      </label>
      <label ngbButtonLabel class="btn-sm btn-primary" style="margin:2px;width:33px;">
        <input ngbButton type="radio" [value]="'fillpaint'"><fa-icon [icon]="['far', 'paint-brush']"></fa-icon>
      </label>
    </div>
    <div *ngIf="dvc.datasettype==='boxdetection'" class="btn-group btn-group-toggle" ngbRadioGroup name="radioBasic" [(ngModel)]="labeltoolsService.drawmode">
      <label ngbButtonLabel class="btn-sm btn-primary" style="margin:2px;width:33px;">
        <input ngbButton type="radio" [value]="'editbox'"><fa-icon [icon]="['far', 'hand-pointer']"></fa-icon>
      </label>
      <label ngbButtonLabel class="btn-sm btn-primary" style="margin:2px;width:33px;">
        <input ngbButton type="radio" [value]="'addbox'"><fa-icon [icon]="['far', 'plus-circle']"></fa-icon>
      </label>
      <label ngbButtonLabel class="btn-sm btn-primary" style="margin:2px;width:33px;">
        <input ngbButton type="radio" [value]="'removebox'"><fa-icon [icon]="['far', 'minus-circle']"></fa-icon>
      </label>
    </div>
  </div>
  <div class="flex-shrink-0 nomargin" *ngIf="(dvc.modus | async) === 'dataset'" style="background-color:rgba(255, 255, 255, 1.0);">
    <div style="padding-left:10px; padding-top:10px; padding-bottom:10px; font-size:0.9em;">
      <div [hidden]="!((dvc.datasettype==='segmentation') && ((labeltoolsService.drawmode === 'eraser') || (labeltoolsService.drawmode === 'pencil')))">
        <form [formGroup]="linewidthForm">
          <div class="row" style="padding-right:15px;">
            <div class="col" style="font-size:0.9em;padding:5px; margin-left:20px; width:95px;">line width: {{linewidthForm.value.linewidthValue}} px</div>
            <div class="col-md-auto nomargin" style="padding-top: 5px;">
              <input formControlName="linewidthValue" style="width:90px; padding:0px; font-size:0.8em;" type="range" min="1"  max="25">
            </div>
          </div>
        </form>
      </div>
      <div [hidden]="!((dvc.datasettype==='segmentation') && ((labeltoolsService.drawmode === 'magicwand')))">
        <form [formGroup]="magicwandForm">
          <div class="row" style="padding-right:15px;">
            <div class="col" style="font-size:0.9em;padding:5px; margin-left:20px; width:95px;">tolerance: {{magicwandForm.value.magicwandToleranceValue}}</div>
            <div class="col-md-auto nomargin" style="padding-top: 5px;">
              <input formControlName="magicwandToleranceValue" style="width:90px; padding:0px; font-size:0.8em;" type="range" min="0"  max="255">
            </div>
          </div>
          <div class="row" style="padding-right:15px;">
            <div class="col" style="font-size:0.9em;padding:5px; margin-left:20px; width:90px;">contiguous</div>
            <div class="col-md-auto nomargin" style="padding-top: 5px;">
              <input formControlName="magicwandContiguousValue" type="checkbox">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="flex-shrink-0 nomargin" *ngIf="(dvc.modus | async) !== 'filestorage'" style="background-color:rgba(255, 255, 255, 1.0); border-top: 1px solid #cccccc">
    <div class="row" style="padding: 3px 10px; font-size:0.9em;">
      <div class="col" [hidden]="(dvc.modus | async) === 'analysis'" style="float: left; margin: 6px 4px;">
        <span #ignoredInput style="
          padding-right: 18px;
          border: 1px solid #ccc;
          padding-top: 3px;
          padding-bottom: 4px;
          margin-right: 5px;
          margin-left: 2px;
          display: inline;"
          [style.background]="dvc.activelayercolor"
          [(colorPicker)]="dvc.activelayercolor"
          [cpPosition]="'bottom-left'"
          [cpIgnoredElements]="[ignoredButton, ignoredInput]"
          [cpOutputFormat]="'hex'"
          [cpAlphaChannel]="'disabled'"
          [(cpToggle)]="toggleColorpicker"
          (colorPickerClose)="setColor('save')"
          (colorPickerChange)="setColor('preview')">
        </span>
        <button #ignoredButton type="button" (tap)="toggleColorpicker=!toggleColorpicker" class="btn-default btn-xs">color</button>
      </div>
      <!--<div class="col" [hidden]="(dvc.modus | async) === 'analysis'" style="padding-left:10px;">-->
      <div class="col" style="padding-left:10px;">
        <form [formGroup]="opacityForm">
          <div class="row">
            <div class="col" style="font-size:0.9em;padding:5px; margin-left:20px; width:90px;">opacity: {{opacityForm.value.opacityValue}} %</div>
            <div class="col-md-auto nomargin" style="padding-top: 5px;">
              <input formControlName="opacityValue" style="width:90px; padding:0px; font-size:0.8em;" type="range" min="0"  max="100">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <div class="flex-fill flex-grow-1 nomargin" *ngIf="(dvc.modus | async) !== 'filestorage'" style="overflow-y:auto; background-color:rgba(255, 255, 255, 0);">
    <wol-layerlist style="width:100%;"></wol-layerlist>
    <!--
    <div [sortablejs]="items" class="container">
      <div *ngFor="let item of items" class="row">{{ item }}</div>
    </div>
    -->

  </div>
  <div class="flex-shrink-0 nomargin" style="background-color:rgba(255, 255, 255, 1.0);">
    <span *ngIf="labeltoolsService.cursorposition.onLayer" style="font-size:0.8em; padding:15px; padding-left:20px">cursor-x: {{labeltoolsService.cursorposition.x}} | cursor-y: {{labeltoolsService.cursorposition.y}}</span>
    <span *ngIf="!labeltoolsService.cursorposition.onLayer" style="font-size:0.8em; padding:15px; padding-left:20px">&nbsp;</span>
  </div>

</div>
