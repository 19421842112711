import { FileselectorControllerService } from './../../shared/services/fileselector-controller.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(public fsc: FileselectorControllerService) { }

  ngOnInit() {
  }

}
