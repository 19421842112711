
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';


import { AppRoutingModule } from './app-routing.module';
import { TokenInterceptor } from './shared/interceptors/token-interceptor';
import { AppComponent } from './app.component';

// import feature modules
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';

export class MyHammerConfig extends HammerGestureConfig  {
  overrides = {
    swipe: {velocity: 0.4, threshold: 20},
    pan: {threshold: 1, pointers: 0},
    press: {time: 1, threshold: 5},
    tap: {intervall: 50, time: 350, threshold: 10}
  } as any;
}

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    CoreModule,
    SharedModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
