import { NGXLogger } from 'ngx-logger';
import { MinimapService } from './../minimap.service';
import { CommonModule } from '@angular/common';
import { SharedModule } from './../../shared/shared.module';
// import { MapService } from './../map.service';
import { DataviewerControllerService } from './../../shared/services/dataviewer-controller.service';
import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MapService } from '../map.service';

@Component({
  selector: 'wol-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public zoomForm: FormGroup;

  public setZoom = (zoom: number) => {
    if (zoom > 0) {
      if (zoom !== this.zoomForm.controls.zoomFactor.value) {
        this.zoomForm.controls.zoomFactor.setValue(zoom);
      }
    } else {
      this.minimapService.resetView();
    }
  }

  // tslint:disable-next-line: max-line-length
  constructor(
    public dvc: DataviewerControllerService,
    private fb: FormBuilder,
    public mapService: MapService,
    public minimapService: MinimapService,
    private logger: NGXLogger
  ) { }

  ngOnInit() {
    this.zoomForm = this.fb.group({
      zoomFactor: [100]
    });

    this.zoomForm.valueChanges.subscribe(() => {
      this.logger.debug('zoomSliderValueChanged');
      this.dvc.zoomUpdate(this.zoomForm.controls.zoomFactor.value);
    });

    this.dvc.zoom.subscribe({next: zoom => {
      this.setZoom(zoom);
    }}
    );
  }

}
