import { AccountService } from 'src/app/shared/services/account.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { retry, map, catchError } from 'rxjs/operators';
import { File } from './../interfaces/file';
import { FileRaw } from './../interfaces/file-raw';
import { FileFactory } from './../interfaces/file-factory';
import { ErrorhandlerService } from './errorhandler.service';
import { CommonmodalsComponent } from '../components/commonmodals/commonmodals.component';
import { ToastmessageStoreService } from './toastmessage-store.service';
import { NGXLogger } from 'ngx-logger';


@Injectable({
  providedIn: 'root'
})
export class FileStoreService {

  public spaceinfo: any;
  public lastrefresh = 0;
  public filesChangedSource = new BehaviorSubject<string>('');
  public filesChanged = this.filesChangedSource.asObservable();

  public filesChangedUpdate(value: string) {
    this.filesChangedSource.next(value);
  }

  // tslint:disable-next-line: max-line-length
  constructor(
    private http: HttpClient,
    private modalService: NgbModal,
    private toastService: ToastmessageStoreService,
    public accountService: AccountService,
    private logger: NGXLogger
  ) { }

  refreshspaceinfo(): Observable<any[]> {
    this.logger.debug('refreshing spaceinfo');

    return this.http.post<any[]>(
      `${environment.apiurl}/filestorage/spaceinfo`,
      { }
      ).pipe(
        retry(3),
        map(spaceinfo => spaceinfo
        ),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  getItemlistPage(path: string, pageinfo: any): Observable<any> {

    if (path.charAt(0) === '/') { path = path.substr(1); }
    this.logger.debug('refreshing files currentpath: ' + path);

    return this.http.post<any>(
      `${environment.apiurl}/filestorage/listitempage`,
      { listpath: path, pageinfo }
      ).pipe(
        retry(3),
        map(resultRaw => {
          resultRaw.itemdata.map(a => FileFactory.fromRaw(a));
          resultRaw.pageNumber = pageinfo.page;
          return resultRaw;
        }),
        map(result => {
          return result;
        }),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  listFolder(path: string): Observable<File[]> {
    this.lastrefresh = Date.now();
    this.logger.debug('refreshing files currentpath');

    if (path.charAt(0) === '/') { path = path.substr(1); }
    this.logger.debug(path);

    return this.http.post<FileRaw[]>(
      `${environment.apiurl}/filestorage`,
      { listpath: path }
      ).pipe(
        retry(3),
        map(filesRaw => filesRaw.map(a => FileFactory.fromRaw(a))
        ),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  folderTree(path: string): Observable<any> {
    this.lastrefresh = Date.now();
    this.logger.debug('refreshing foldertree');

    if (path.charAt(0) === '/') { path = path.substr(1); }
    this.logger.debug(path);

    return this.http.post<any[]>(
      `${environment.apiurl}/filestorage/tree`,
      { listpath: path }
      ).pipe(
        retry(3),
        map(foldertree => foldertree
        ),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  createFolder(folderobject: any): Observable<File[]> {
    if (folderobject.path.charAt(0) === '/') { folderobject.path = folderobject.path.substr(1); }
    this.logger.debug('creating new folder: ' + folderobject.path + folderobject.name);
    return this.http.post<FileRaw[]>(
      `${environment.apiurl}/filestorage/createfolder`,
      { folder: folderobject }
      ).pipe(
        retry(3),
        map(filesRaw => filesRaw.map(a => FileFactory.fromRaw(a))
        ),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  renameItem(credentials: any): Observable<any> {
    if (credentials.renamepath.charAt(0) === '/') {
      credentials.renamepath = credentials.renamepath.substr(1);
    }
    this.logger.debug('rename item: ' + credentials.renamepath + credentials.oldname);
    return this.http.post<any>(
      `${environment.apiurl}/filestorage/rename`,
      credentials
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  moveItems(selectedobjects: any[], target): Observable<any> {

    const credentials = {selectedobjects, target};

    return this.http.post<any>(
      `${environment.apiurl}/filestorage/move`,
      credentials
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  copyItems(selectedobjects: any[], target, forceFiletype?): Observable<any> {

    if (!forceFiletype) {
      forceFiletype = '';
    }
    const credentials = {selectedobjects, target, forceFiletype};

    this.logger.debug(credentials);

    return this.http.post<any>(
      `${environment.apiurl}/filestorage/copy`,
      credentials
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  deleteItems(selectedobjects: any[]): Observable<any> {

    return this.http.post<FileRaw[]>(
      `${environment.apiurl}/filestorage/deleteobjects`,
      { selectedobjects }
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }
}
