import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'wol-filebrowser',
  templateUrl: './filebrowser.component.html',
  styleUrls: ['./filebrowser.component.scss']
})
export class FilebrowserComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
