import { FilebrowserService } from './../filebrowser.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wol-maincontent',
  templateUrl: './maincontent.component.html',
  styleUrls: ['./maincontent.component.scss']
})
export class MaincontentComponent implements OnInit {

  constructor(public fbs: FilebrowserService) { }

  ngOnInit() {
  }

}
