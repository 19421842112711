import { NGXLogger } from 'ngx-logger';
import { FilebrowserService } from '../filebrowser.service';
import { Account } from './../../shared/interfaces/account';
import { Component, OnInit, ViewChild, TemplateRef, Input, OnDestroy } from '@angular/core';
import { FileStoreService } from './../../shared/services/file-store.service';
import { File } from './../../shared/interfaces/file';
import { FilecontextmenuComponent } from '../filecontextmenu/filecontextmenu.component';
import { ActivatedRoute } from '@angular/router';
import { DatatableComponent } from '@swimlane/ngx-datatable';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'wol-filelist',
  providers: [FilecontextmenuComponent],
  templateUrl: './filelist.component.html',
  styleUrls: ['./filelist.component.scss']
})
export class FilelistComponent implements OnDestroy, OnInit {
  public parameterPath: string;
  public filechangeDetection: Subscription;
  @ViewChild(DatatableComponent) filelisttable: DatatableComponent;
  @ViewChild('thumbnail', { static: true }) thumbnail: TemplateRef<any>;
  @ViewChild('size', { static: true }) size: TemplateRef<any>;
  @ViewChild('lastmodified', { static: true }) lastmodified: TemplateRef<any>;

  loadingdata = true;
  columns = [];

  public listviewmodus = 'smallthumbnails';

  public refresh() {
    this.fbs.page.pageNumber = 0;
    this.fbs.page.sortname = '';
    this.fbs.page.sortorder = '';
    this.fbs.page.searchstring = '';
    this.fs.filesChangedUpdate('');
    this.fbs.loadpage(this.fbs.page);
  }

  setPage(pageInfo) {
    // this.logger.debug(pageInfo);
    this.fbs.page.size = pageInfo.pageSize;
    const page = {
      pageNumber: pageInfo.offset,
      size: pageInfo.pageSize,
      sortname: this.fbs.page.sortname,
      sortorder: this.fbs.page.sortorder,
      searchstring: this.fbs.page.searchstring
    };
    this.fbs.page.pageNumber = pageInfo.offset;
    // this.logger.debug(['pageinfo', pageInfo ]);

    // don't load same data twice
    if (this.fbs.currentFilesCache[pageInfo.offset]) {
      return;
    } else {
      for (let i = 0; i < this.fbs.pagesPerRequest; i++) {
        this.fbs.currentFilesCache[pageInfo.offset + i] = true;
      }
    }

    // counter of pages loading
    this.fbs.isLoading++;
    this.fs.filesChangedUpdate('');
    this.fbs.loadpage(page);
  }

  setSort(sortInfo) {
    this.logger.debug(['sortinfo', sortInfo.sorts ]);
    this.fbs.page.pageNumber = 0;
    this.fbs.page.sortname = sortInfo.sorts[0].prop;
    this.fbs.page.sortorder = sortInfo.sorts[0].dir;

    // hotfix for swimlane table: table would be "empty" when vertical scroll is not reset
    document.getElementsByTagName('datatable-body')[0].scrollTop = 0;

    setTimeout(() => {
      this.fbs.selected = [];
      this.fbs.currentFilesCache = {};
      this.fbs.currentFiles = [];
      this.fbs.isLoading = 0;
      this.setPage({ offset: 0, pageSize: this.fbs.page.size });
    }, 50);
  }

  onSelect({ selected }) {
    this.logger.debug('Select Event', selected, this.fbs.selected);

    this.fbs.selected.splice(0, this.fbs.selected.length);
    this.fbs.selected.push(...selected);
  }

  onActivate(event) {
    if (event.type === 'click') {
      this.logger.debug('Activate Event: CLICK', event);
    } else if (event.type === 'dblclick') {
      this.logger.debug('Activate Event: DBLCLICK');
      this.logger.debug(event.row);
      if (event.row.type === 'folder') {
        // hotfix for swimlane table: table would be "empty" when vertical scroll is not reset
        document.getElementsByTagName('datatable-body')[0].scrollTop = 0;
        this.fbs.listviewmoduschanged = false;
        this.fbs.currentpath.push(event.row.name);

        setTimeout(() => {
          this.fbs.selected = [];
          this.fbs.currentFilesCache = {};
          this.fbs.currentFiles = [];
          this.fbs.isLoading = 0;
          this.fbs.searchstringUpdate('');
          this.setPage({ offset: 0, pageSize: this.fbs.page.size });
        }, 50);

        /*
        this.fs.listFolder(this.fbs.currentpath.join('/') + '/' + event.row.name + '/').subscribe(res => {
          this.fbs.currentpath.push(event.row.name);
          this.fbs.currentFiles = res;
          this.fbs.filterFilelist(this.fbs.searchstringSource.getValue());
          this.fbs.selected = [];
          this.fbs.searchstringUpdate('');
          this.fbs.loading = false;
        });
        */
      }
    } else { }
  }

  onTableContextMenu(event) {
    event.event.preventDefault();
    event.event.stopPropagation();
    this.filecontextMenu.openContextMenu(event);
  }

  // tslint:disable-next-line: max-line-length
  constructor(
    private logger: NGXLogger,
    private fs: FileStoreService,
    private filecontextMenu: FilecontextmenuComponent,
    public fbs: FilebrowserService,
    private route: ActivatedRoute
  ) {
    this.fbs.page.pageNumber = 0;
  }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.parameterPath = params.get('parameterpath');
      this.logger.debug('path: ' + this.parameterPath);
      let tempPath = '';
      this.fbs.currentpath = [];

      if (this.parameterPath !== null) {
        tempPath = this.parameterPath + '/';
        this.fbs.currentpath = this.parameterPath.split('/');
        this.logger.debug(this.fbs.currentpath);
      }

      document.getElementsByTagName('datatable-body')[0].scrollTop = 0;

      setTimeout(() => {
        this.fbs.selected = [];
        this.fbs.currentFilesCache = {};
        this.fbs.currentFiles = [];
        this.fbs.isLoading = 0;
        this.setPage({ offset: 0, pageSize: this.fbs.page.size });
      }, 50);

      // this.setPage({ offset: 0 });
      /*
      // check server for new list if data in store is older than 0 seconds
      if (this.fs.lastrefresh < (Date.now() - (0 * 1000))) {
        this.fbs.loading = true;
        this.fs.listFolder(tempPath).subscribe(res => {
          this.fbs.currentFiles = res;
          this.fbs.filterFilelist(this.fbs.searchstringSource.getValue());
          this.fbs.loading = false;
        }
        );
      }
      */
      this.filechangeDetection = this.fs.filesChanged.subscribe({next: filesChanged => {
        this.logger.debug(filesChanged + '   :   ' + this.fbs.currentpath.join('/') + '/');
        if (filesChanged === (this.fbs.currentpath.join('/') + '/')) {
          this.logger.debug('refresh filelist!');
          this.fbs.refreshCurrentFiles();
          setTimeout(() => {
            this.fs.filesChangedUpdate('');
          }, 2000);
        }
      }}
      );
    });

    this.columns = [
      { prop: 'type', name: '', width: 50, canAutoResize: false, cellTemplate: this.thumbnail},
      { prop: 'name', name: 'Name' },
      { prop: 'type', name: 'Type' },
      { prop: 'size', name: 'size', cellTemplate: this.size },
      { prop: 'mtime', name: 'last modified', cellTemplate: this.lastmodified }
    ];


    this.fbs.listviewmodus.subscribe({next: listviewmodus => {
      this.fbs.listviewmoduschanged = true;
      this.logger.debug(listviewmodus);
      this.listviewmodus = listviewmodus;
    }}
    );

    this.fbs.searchstring.subscribe({next: searchstring => {
      this.fbs.filterFilelist(searchstring);
    }}
    );

  }

  ngOnDestroy() {
    this.filechangeDetection.unsubscribe();
  }

}
