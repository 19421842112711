import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'wol-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  @Input() size: number;
  @Input() margin: number;


  constructor() { }

  ngOnInit() {
    if (!this.size) {
      this.size = 32;
    }
    if (!this.margin) {
      this.margin = 50;
    }
  }

}
