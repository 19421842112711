import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
  selector: 'wol-modal-createfolder',
  templateUrl: './modal-createfolder.component.html',
  styleUrls: ['./modal-createfolder.component.scss']
})
export class ModalCreatefolderComponent implements OnInit {

  public createform: FormGroup;

  @Input() type: string;
  @Input() title: string;

  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder) { }

  ngOnInit() {
    this.initForm();

    this.createform.get('foldername').valueChanges.subscribe(value => {
      const control = this.createform.get('foldername');
      control.markAsTouched();
      control.markAsDirty();
      control.updateValueAndValidity({emitEvent: false});
    });
  }

  private initForm() {
    if (this.createform) { return; }

    this.createform = this.fb.group({
      foldername: ['', ValidationService.foldernameValidator]
    });
  }

}
