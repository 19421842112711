import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Activity } from '../interfaces/activity';
import { ActivityFactory } from '../interfaces/activity-factory';
import { ActivityRaw } from '../interfaces/activity-raw';
import { ErrorhandlerService } from './errorhandler.service';

@Injectable({
  providedIn: 'root'
})
export class ActivitylogStoreService {
  public lastrefresh = 0;
  public allActivites: Activity[];

  constructor(
    private http: HttpClient,
    private logger: NGXLogger
  ) { }

  getAll(): Observable<Activity[]> {
    this.lastrefresh = Date.now();
    this.logger.debug('refreshing all activities');

    return this.http.get<ActivityRaw[]>(
      `${environment.apiurl}/activitylogs/list`
      ).pipe(
        retry(3),
        map(activitiesRaw => activitiesRaw.map(a => ActivityFactory.fromRaw(a))),
        map(activities => {
          this.allActivites = activities;
          return this.allActivites;
        }),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

  logActivity(user, identkey, message): Observable<boolean> {
    let logentry: any;

    if (user) {
      logentry = {time: Date.now(), user: user.username, identkey, message};
    } else {
      logentry = {time: Date.now(), identkey, message};
    }

    return this.http.post<any[]>(
      `${environment.apiurl}/activitylogs/log`,
      {logentry}
      ).pipe(
        retry(3),
        catchError(ErrorhandlerService.errorHandler)
      );
  }

}
