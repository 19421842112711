import { NGXLogger } from 'ngx-logger';
import { DataviewerControllerService } from 'src/app/shared/services/dataviewer-controller.service';
import { FiledownloaderControllerService } from './../../shared/services/filedownloader-controller.service';
import { FileuploaderControllerService } from './../../shared/services/fileuploader-controller.service';
import { ToastmessageStoreService } from './../../shared/services/toastmessage-store.service';
import { FileStoreService } from './../../shared/services/file-store.service';
import { FilebrowserService } from '../filebrowser.service';
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalCopyComponent } from '../modal-copy/modal-copy.component';
import { ModalUploadComponent } from '../modal-upload/modal-upload.component';
import { ModalMoveComponent } from '../modal-move/modal-move.component';
import { ModalRenameComponent } from '../modal-rename/modal-rename.component';
import { ModalDeleteComponent } from '../modal-delete/modal-delete.component';
import { ModalCreatefolderComponent } from '../modal-createfolder/modal-createfolder.component';
import * as _ from 'lodash';

@Component({
  selector: 'wol-previewbar',
  templateUrl: './previewbar.component.html',
  styleUrls: ['./previewbar.component.scss']
})
export class PreviewbarComponent implements OnInit {

  public openDataviewer() {
    this.logger.debug(['openDataviewer', this.fbs.selected[0]]);
    this.dvc.modusUpdate('filestorage');
    this.dvc.visibleUpdate(true);
    this.dvc.dataobject1Update('/' + this.fbs.selected[0].path, this.fbs.selected[0].name, this.fbs.selected[0].size);
  }

  // tslint:disable-next-line: max-line-length
  constructor(
    private logger: NGXLogger,
    public dvc: DataviewerControllerService,
    public fs: FileStoreService,
    public toastService: ToastmessageStoreService,
    public fuc: FileuploaderControllerService,
    public fdc: FiledownloaderControllerService,
    public fbs: FilebrowserService,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
  }

}
