import { FilebrowserModule } from './../filebrowser/filebrowser.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FileselectorComponent } from './fileselector/fileselector.component';
import { CommonmodalsComponent } from '../shared/components/commonmodals/commonmodals.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [FileselectorComponent, HeaderComponent, FooterComponent],
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
    FilebrowserModule
  ],
  exports: [
    FileselectorComponent
  ],
  entryComponents: [CommonmodalsComponent]
})
export class FileselectorModule { }
