<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div>
    <form [formGroup]="renameform" style="max-width:500px;">
      <div class="form-group">
        <label for="formGroupFoldernameInput">New foldername</label>
        <input formControlName="foldername" type="text" class="form-control" id="formGroupFoldernameInput">
        <wol-form-messages [control]="renameform.get('foldername')" controlName="foldername"></wol-form-messages>
      </div>
    </form>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('cancel click')">cancel</button>
  <button type="submit" class="btn btn-primary" [disabled]="!renameform.valid" ngbAutofocus (click)="activeModal.close(renameform)">{{type}}</button>
</div>
