import { NGXLogger } from 'ngx-logger';
import { WebglService } from './../webgl.service';
import { UtilitiesService } from './../../shared/services/utilities.service';
import { SidebarRightComponent } from './../sidebar-right/sidebar-right.component';
import { MinimapService } from './../minimap.service';
import { LabeltoolsService } from './../labeltools.service';
import { MapService } from './../map.service';
import * as THREE from 'three';
import * as _ from 'lodash';
import * as pako from 'pako';

import { Component, OnInit, HostListener, ViewChild, ElementRef, Renderer2, AfterViewInit, OnDestroy } from '@angular/core';
import { DataviewerControllerService } from './../../shared/services/dataviewer-controller.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { timeout } from 'q';
import { CropperComponent } from 'angular-cropperjs';
import { ResizedEvent } from 'angular-resize-event';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastmessageStoreService } from 'src/app/shared/services/toastmessage-store.service';
import { CommonmodalsComponent } from 'src/app/shared/components/commonmodals/commonmodals.component';
import { Observer } from 'rxjs';

@Component({
  selector: 'wol-dataviewer',
  templateUrl: './dataviewer.component.html',
  styleUrls: ['./dataviewer.component.scss']
})
export class DataviewerComponent implements AfterViewInit  {

  @ViewChild('maincanvas') mapcanvasContainer: ElementRef;
  @ViewChild('dataviewerleft') dataviewerLeftContainer: ElementRef;
  @ViewChild('dataviewerright') dataviewerRightContainer: ElementRef;
  @ViewChild(SidebarRightComponent) sidebarRight: SidebarRightComponent;
  public minimapcanvasContainer: ElementRef;
  public minimapAreaselect: CropperComponent;
  public initdone = false;
  private initimage: any;
  private zoomTemp: number;
  private img: any;
  private imgresultlabel: any;
  private cleanmapimagedata: any;
  private analysisjobresult: any;
  public zoomSubscription: any;


  @HostListener('wheel', ['$event'])
  onWheelScroll(event) {
    const eventpath = event.composedPath();
    this.logger.debug('scroll1: ' + eventpath[1].id + ' :1: ' + event.deltaY );
    if ((event.deltaY > 0) && (eventpath[1].id === 'maincanvas')) {
      // Scroll down
      // this.logger.debug('scroll down: ' + this.zoomTemp);
      this.dvc.zoomUpdate(this.zoomTemp - 5);
    } else if ((event.deltaY < 0) && (eventpath[1].id === 'maincanvas')) {
      // Scroll up
      // this.logger.debug('scroll up: ' + this.zoomTemp);
      this.dvc.zoomUpdate(this.zoomTemp + 5);
    }
  }

  onPress(event) {
    // console.log(event);
    // console.log(Math.round(event.changedPointers[0].offsetX) + ' : ' + Math.round(event.changedPointers[0].offsetY));
    // event offset sometimes not working in firefox, therefor cursorposition is used
    if (event.pointerType === 'mouse') {
      event.offsetX = Math.round(this.labeltoolsService.cursorposition.offsetX);
      event.offsetY = Math.round(this.labeltoolsService.cursorposition.offsetY);
    } else {
      event.offsetX = Math.round(event.changedPointers[0].offsetX);
      event.offsetY = Math.round(event.changedPointers[0].offsetY);
    }

    this.processDownEvent(event);
  }
  onPressUp(event) {
    // console.log(event.type);
    // console.log(Math.round(event.changedPointers[0].offsetX) + ' : ' + Math.round(event.changedPointers[0].offsetY));
    event.offsetX = Math.round(event.changedPointers[0].offsetX);
    event.offsetY = Math.round(event.changedPointers[0].offsetY);
    this.processUpEvent(event);
  }
  onPanStart(event) {
    // console.log(event);
    // console.log(Math.round(event.changedPointers[0].offsetX) + ' : ' + Math.round(event.changedPointers[0].offsetY));
    event.offsetX = Math.round(event.changedPointers[0].offsetX);
    event.offsetY = Math.round(event.changedPointers[0].offsetY);
    this.processDownEvent(event);
  }
  onPanMove(event) {
    // console.log(event);
    // console.log(Math.round(event.changedPointers[0].offsetX) + ' : ' + Math.round(event.changedPointers[0].offsetY));
    event.offsetX = Math.round(event.changedPointers[0].offsetX);
    event.offsetY = Math.round(event.changedPointers[0].offsetY);
    this.processMoveEvent(event, true);
  }
  onPanEnd(event) {
    // console.log(event.type);
    // console.log(Math.round(event.changedPointers[0].offsetX) + ' : ' + Math.round(event.changedPointers[0].offsetY));
    event.offsetX = Math.round(event.changedPointers[0].offsetX);
    event.offsetY = Math.round(event.changedPointers[0].offsetY);
    this.processUpEvent(event);
  }

  @HostListener('mousemove', ['$event'])
  onMouseMove(event) {
    this.processMoveEvent(event);
  }

  /*
  @HostListener('mouseup', ['$event'])
  onMouseup(event) {
    this.processUpEvent(event);
  }

  @HostListener('mousedown', ['$event'])
  onMousedown(event) {
    this.processDownEvent(event);
  }
  */

  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.code === 'ArrowDown') {
      this.logger.debug('down');
      this.mapService.camera.position.y -= 2;
      // tslint:disable-next-line: max-line-length
      this.minimapService.setCropBoxPosition(this.mapService.camera, this.mapcanvasContainer, this.minimapcanvasContainer, this.minimapAreaselect);
      this.webgl.render();
    } else if (event.code === 'ArrowUp') {
      this.logger.debug('up');
      this.mapService.camera.position.y += 2;
      // tslint:disable-next-line: max-line-length
      this.minimapService.setCropBoxPosition(this.mapService.camera, this.mapcanvasContainer, this.minimapcanvasContainer, this.minimapAreaselect);
      this.webgl.render();
    } else if (event.code === 'ArrowLeft') {
      this.logger.debug('left');
      this.mapService.camera.position.x -= 2;
      // tslint:disable-next-line: max-line-length
      this.minimapService.setCropBoxPosition(this.mapService.camera, this.mapcanvasContainer, this.minimapcanvasContainer, this.minimapAreaselect);
      this.webgl.render();
    } else if (event.code === 'ArrowRight') {
      this.logger.debug('right');
      this.mapService.camera.position.x += 2;
      // tslint:disable-next-line: max-line-length
      this.minimapService.setCropBoxPosition(this.mapService.camera, this.mapcanvasContainer, this.minimapcanvasContainer, this.minimapAreaselect);
      this.webgl.render();
    }
  }

  setCursorPosition(event) {
    const mouseposition = {x: 0, y: 0, offsetX: 0, offsetY: 0};
    mouseposition.offsetX = event.offsetX;
    mouseposition.offsetY = event.offsetY;
    mouseposition.x = ( event.offsetX / this.mapcanvasContainer.nativeElement.offsetWidth ) * 2 - 1;
    mouseposition.y = - ( event.offsetY / this.mapcanvasContainer.nativeElement.offsetHeight ) * 2 + 1;
    // this.logger.debug(mouseposition.x, mouseposition.y);
    // update the picking ray with the camera and mouse position
    this.mapService.raycaster.setFromCamera( mouseposition, this.mapService.camera );

    // calculate objects intersecting the picking ray
    const intersects = this.mapService.raycaster.intersectObjects( this.dvc.scene.children );

    // this.logger.debug(intersects);
    if (intersects.length > 0 ) {
      this.labeltoolsService.cursorposition.onLayer = true;
      let posx = Math.ceil(intersects[0].point.x);
      let posy = Math.ceil(intersects[0].point.y);

      // transform position from centered coordinates to left corner coordinates
      posx = Math.ceil(posx + (this.dvc.inputtexture.image.width / 2));
      posy = Math.ceil(posy + (this.dvc.inputtexture.image.height / 2));
      this.labeltoolsService.cursorposition.x = posx;
      this.labeltoolsService.cursorposition.y = this.dvc.inputtexture.image.height - posy;
      this.labeltoolsService.cursorposition.offsetX = mouseposition.offsetX;
      this.labeltoolsService.cursorposition.offsetY = mouseposition.offsetY;
    } else {
      this.labeltoolsService.cursorposition.onLayer = false;
      this.labeltoolsService.cursorposition.x = 0;
      this.labeltoolsService.cursorposition.y = 0;
      this.labeltoolsService.cursorposition.offsetX = 0;
      this.labeltoolsService.cursorposition.offsetY = 0;
    }
  }


  processMoveEvent(event, hammerjs?) {
    if (!hammerjs) {
      const eventpath = event.composedPath();
      if (eventpath[6].id === 'minimapcanvas') {
        this.minimapService.mouseHover = true;
      } else {
        this.minimapService.mouseHover = false;
      }
      if (eventpath[1].id === 'maincanvas') {
        this.setCursorPosition(event);
      }

    } else {
      if (event.target.offsetParent.id === 'maincanvas') {
        this.minimapService.mouseHover = false;
        this.setCursorPosition(event);

        if (this.dvc.modusSource.value === 'dataset') {
          // tslint:disable-next-line: max-line-length
          // if ((this.dvc.datasettype === 'boxdetection') && (this.dvc.pages[0].layers[this.dvc.activelayerindex].label)) {
          if (this.dvc.datasettype === 'boxdetection') {
            this.labeltoolsService.mousemove_boxdetection(event, this.mapcanvasContainer);
          }

          if (this.dvc.datasettype === 'segmentation') {
            this.labeltoolsService.drawmodeActive = true;
            if (this.labeltoolsService.drawmodeActive === true) {
              this.labeltoolsService.mousemove_segmentation(event, this.mapcanvasContainer);
            }
          }
        }

      } else {
        this.minimapService.mouseHover = false;
        this.labeltoolsService.cursorposition.onLayer = false;
        this.labeltoolsService.cursorposition.x = 0;
        this.labeltoolsService.cursorposition.y = 0;
      }
    }
  }

  processUpEvent(event) {
    if (event.target.offsetParent.id === 'maincanvas') {
      if (this.dvc.modusSource.value === 'dataset') {
        if (this.dvc.datasettype === 'boxdetection') {
          this.labeltoolsService.mouseup_boxdetection(event, this.mapcanvasContainer);
          this.webgl.render('force');
        } else if (this.dvc.datasettype === 'segmentation') {
          this.webgl.render('force');
        }
      }
      this.labeltoolsService.drawmodeActive = false;
    }
  }

  processDownEvent(event) {
    if (event.target.offsetParent.id === 'maincanvas') {
      if (this.dvc.modusSource.value === 'dataset') {
        if (this.dvc.datasettype === 'boxdetection') {
          this.labeltoolsService.moveboxactive = false;
          if (this.dvc.pages[0].layers[this.dvc.activelayerindex].label) {
            this.labeltoolsService.mousedown_boxdetection(event, this.mapcanvasContainer);
            this.webgl.render('force');
          }
        } else if (this.dvc.datasettype === 'segmentation') {
          this.labeltoolsService.mousedown_segmentation(event, this.mapcanvasContainer);
          this.webgl.render('force');
        }
      } else if (this.dvc.modusSource.value === 'analysis') {
        this.dvc.getObjectInfo({
          x: this.labeltoolsService.cursorposition.x,
          y: this.labeltoolsService.cursorposition.y
        }).subscribe(res => {
          this.logger.debug(res);
        });
      }
    }
  }

  public cleanup() {

    this.logger.debug('cleanup');
    this.logger.debug(this.dvc);

    _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
        this.dvc.scene.remove( item );
    });

    this.dvc.boxdetectionmarker = [];
    this.labeltoolsService.startboxdetectionmarker = [];
    this.labeltoolsService.currentboxdetectionindex = -1;
    try { this.logger.debug(this.dvc); } catch (e) { this.logger.debug(e); }
    try {
      this.logger.debug('try');
      _.each(this.dvc.pages[0].layers, (layer, index, list) => {
        try { this.dvc.scene.remove(layer.plane); } catch (e) { this.logger.debug(e); }
        try { layer.plane.material.map = null; } catch (e) { this.logger.debug(e); }
        try { layer.plane.material.dispose(); } catch (e) { this.logger.debug(e); }
        try { layer.texture.dispose(); } catch (e) { this.logger.debug(e); }
        try { layer.texture.map = null; } catch (e) { this.logger.debug(e); }
        try { layer.texture.dispose(); } catch (e) { this.logger.debug(e); }
      });
      if (this.imgresultlabel) {
        this.imgresultlabel.remove();
      }
      this.imgresultlabel = null;
      // this.temp_datasetclasses = null;
      // if (inputimage) {
      //   inputimage.remove();
      // }
      // inputimage = null;

      if (this.img) {
        this.img.remove();
      }
      this.img = null;

      this.cleanmapimagedata = null;
      this.initimage = null;
      this.labeltoolsService.undoredolabel = null;

      try { this.dvc.overlaytexture.dispose(); } catch (e) { this.logger.debug(e); }
      try { this.dvc.inputtexture.dispose(); } catch (e) { this.logger.debug(e); }

      // context = null;
      // if (canvas) {
      //   canvas.remove();
      // }
      // canvas = null;

      // inputimageloader = null;

      _.each(this.dvc.pages, (page, index, list) => {
        page.layers = [];
        page.geometry.dispose();
      });
      this.dvc.pages = [];

    } catch (e) {
      this.logger.debug(e);
    }
  }

  private loadlabel() {
    class WolGroup extends THREE.Group {
      classId: any;
      trained: any;
    }

    if (this.dvc.modusSource.getValue() === 'trainedalgorithm') {
      let canvas: any;
      // createcanvas for layerdrawing
      if (canvas) {
        canvas.remove();
      }
      canvas = document.createElement('canvas');
      const context = canvas.getContext('2d');

      canvas.width = this.dvc.inputtexture.image.width;
      canvas.height = this.dvc.inputtexture.image.height;

      // bild an der x-achse spiegeln um unterschiedliche Korrdinatesystem in 2dcanvas und webgl zu berücksichtigen
      context.translate(0, canvas.height);
      context.scale(1, -1);

      this.dvc.getTrainedalgorithmLabels('originallabel').subscribe(result => {
        if ((result !== 'nolabel') && (result !== 'notfound')) {
          const classidlist = [];
          let tempClass = 0;
          _.each(this.dvc.pages[0].layers, (layer, index, list) => {
            this.logger.debug(layer);
            if ((layer.label === true) && (layer.trained === false)) {
              layer.classId = parseInt(layer.classId, 10);
              tempClass += 1;
              classidlist.push(_.findLastIndex(this.dvc.pages[0].layers, {classId: tempClass}));
            }
          });
          this.logger.debug(classidlist);

          // LOAD ORIGINALLABEL
          if (this.dvc.datasettype === 'segmentation') {
            for (let y = 0; y < this.dvc.inputtexture.image.height; y++) {
              // loop through each column
              for (let x = 0; x < this.dvc.inputtexture.image.width; x++) {
                if (result[(this.dvc.inputtexture.image.height - y - 1)][x] > 0) {
                  // tslint:disable-next-line: max-line-length
                  this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_red;
                  // tslint:disable-next-line: max-line-length
                  this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_green;
                  // tslint:disable-next-line: max-line-length
                  this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_blue;
                  // tslint:disable-next-line: max-line-length
                  this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
                }
              }
            }
          } else if (this.dvc.datasettype === 'boxdetection') {
            this.cleanmapimagedata = new Uint8Array(this.dvc.inputtexture.image.height * this.dvc.inputtexture.image.width * 4);
            _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
              this.dvc.scene.remove(item);
            });
            this.dvc.boxdetectionmarker = [];

            _.each(result, (item, index) => {
              result[index].ymin = this.dvc.inputtexture.image.height - result[index].ymin - 1;
              result[index].ymax = this.dvc.inputtexture.image.height - result[index].ymax - 1;

              result[index].xmin = Math.ceil(result[index].xmin - (this.dvc.inputtexture.image.width / 2));
              result[index].xmax = Math.ceil(result[index].xmax - (this.dvc.inputtexture.image.width / 2));
              result[index].ymin = Math.ceil(result[index].ymin - (this.dvc.inputtexture.image.height / 2));
              result[index].ymax = Math.ceil(result[index].ymax - (this.dvc.inputtexture.image.height / 2));
              result[index].trained = false;

              if (!_.has(item, 'classId')) {
                result[index].classId = 1;
              }
            });
            _.each(result, (item, index) => {
              const boxShape = new THREE.Shape();

              boxShape.moveTo( item.xmin, item.ymin);
              boxShape.lineTo( item.xmax, item.ymin);
              boxShape.lineTo( item.xmax, item.ymax);
              boxShape.lineTo( item.xmin, item.ymax);
              boxShape.lineTo( item.xmin, item.ymin);

              let tempcolor = this.dvc.activelayercolor.substr(1);
              const geometry = new THREE.ShapeGeometry( boxShape );
              _.each(this.dvc.pages[0].layers, (layer) => {
                if ((layer.classId === item.classId) && (layer.trained === false)) {
                  // tslint:disable-next-line: max-line-length
                  tempcolor = this.dvc.decimalToHex(layer.color_red) + this.dvc.decimalToHex(layer.color_green) + this.dvc.decimalToHex(layer.color_blue);
                }
              });
              // tslint:disable-next-line: max-line-length
              const material = new THREE.MeshBasicMaterial( { color: parseInt('0x' + tempcolor, 16), wireframe: true, transparent: true, opacity: 1.0 } );
              const mesh = new THREE.Mesh( geometry, material ) ;
              mesh.name = 'boxwireframe';
              // tslint:disable-next-line: max-line-length
              const material2 = new THREE.MeshBasicMaterial( { color: parseInt('0x' + tempcolor, 16), transparent: true, opacity: 1.0 } );
              const mesh2 = new THREE.Mesh( geometry, material2 ) ;
              mesh2.name = 'box';
              const group = new WolGroup();
              group.add(mesh);
              group.add(mesh2);
              group.name = 'box_' + index;
              group.classId = item.classId;
              group.trained = item.trained;
              this.dvc.boxdetectionmarker.push(group);
            });
            _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
              this.dvc.scene.add( item );
            });
          }
          // load TRAINED RESULTS!
          if (this.dvc.datasettype === 'segmentation') {
            if (this.imgresultlabel) {
              this.imgresultlabel.remove();
            }
            this.imgresultlabel = new Image(this.dvc.inputtexture.image.width, this.dvc.inputtexture.image.height);
            this.imgresultlabel.crossOrigin = '';

            this.imgresultlabel.onload = () => { // image is loaded; sizes are available
              context.clearRect(0, 0, canvas.width, canvas.height);
              context.drawImage(this.imgresultlabel, 0, 0 );

              _.each(this.dvc.pages[0].layers, (layer, index, list) => {
                if ((layer.label === true) && (layer.trained === true)) {
                  layer.texture.image.data = new Uint8Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
                  this.recoloraslabel(index);
                  layer.texture.needsUpdate = true;
                }
              });
              this.dvc.loadingUpdate(false);
              _.each(this.dvc.pages[0].layers, (layer, index, list) => {
                layer.texture.needsUpdate = true;
              });
              return true;
            };

            this.imgresultlabel.onerror = (error) => {
              this.logger.debug(error);
              let retryCount = 0;

              if (retryCount < 10){
                setTimeout(() => {
                  this.imgresultlabel.src = this.dvc.resultlabelpath;
                  retryCount += 1;
                }, 1000);
              }
              this.imgresultlabel.src = this.dvc.resultlabelpath;
            };

            // this.imgresultlabel.src = encodeURIComponent(this.dvc.resultlabelpath);
            this.imgresultlabel.src = this.dvc.resultlabelpath;
          } else if (this.dvc.datasettype === 'boxdetection') {
            this.logger.debug('plot trained label: boxdetection');
            if (this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.png'))) {
              // tslint:disable-next-line: max-line-length
              this.dvc.resultlabelpath = this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.png')) + '_testlabel.json';
            } else if (this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.jpg'))) {
              // tslint:disable-next-line: max-line-length
              this.dvc.resultlabelpath = this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.jpg')) + '_testlabel.json';
            }

            // tslint:disable-next-line: no-shadowed-variable
            this.dvc.getTrainedalgorithmLabels('resultlabel').subscribe(result => {
              if ((result !== 'nolabel')  && (result !== 'notfound')) {
                // tslint:disable-next-line: no-shadowed-variable
                const classidlist = [];
                let temp2Class = 0;
                _.each(this.dvc.pages[0].layers, (layer, index) => {
                  if ((layer.label === true) && (layer.trained === false)) {
                    this.logger.debug(layer);
                    this.logger.debug(index);
                    layer.classId = parseInt(layer.classId, 10);
                    temp2Class += 1;
                    classidlist.push(_.findLastIndex(this.dvc.pages[0].layers, {classId: temp2Class}));
                  }
                });

                this.cleanmapimagedata = new Uint8Array(this.dvc.inputtexture.image.height * this.dvc.inputtexture.image.width * 4);

                _.each(this.dvc.boxdetectionmarker, (item) => {
                  this.dvc.scene.remove(item);
                });

                _.each(result, (item, index) => {
                  result[index].ymin = this.dvc.inputtexture.image.height - result[index].ymin - 1;
                  result[index].ymax = this.dvc.inputtexture.image.height - result[index].ymax - 1;

                  result[index].xmin = Math.ceil(result[index].xmin - (this.dvc.inputtexture.image.width / 2));
                  result[index].xmax = Math.ceil(result[index].xmax - (this.dvc.inputtexture.image.width / 2));
                  result[index].ymin = Math.ceil(result[index].ymin - (this.dvc.inputtexture.image.height / 2));
                  result[index].ymax = Math.ceil(result[index].ymax - (this.dvc.inputtexture.image.height / 2));

                  result[index].trained = true;

                  if (!_.has(item, 'classId')) {
                    result[index].classId = 1;
                  }
                });

                _.each(result, (item, index) => {
                  const boxShape = new THREE.Shape();

                  boxShape.moveTo( item.xmin, item.ymin);
                  boxShape.lineTo( item.xmax, item.ymin);
                  boxShape.lineTo( item.xmax, item.ymax);
                  boxShape.lineTo( item.xmin, item.ymax);
                  boxShape.lineTo( item.xmin, item.ymin);

                  let tempcolor = this.dvc.activelayercolor.substr(1);
                  const geometry = new THREE.ShapeGeometry( boxShape );
                  _.each(this.dvc.pages[0].layers, (layer) => {
                    if ((layer.classId === item.classId) && (layer.trained === true)) {
                      // tslint:disable-next-line: max-line-length
                      tempcolor = this.dvc.decimalToHex(layer.color_red) + this.dvc.decimalToHex(layer.color_green) + this.dvc.decimalToHex(layer.color_blue);
                    }
                  });
                  const material = new THREE.MeshBasicMaterial( { color: parseInt('0x' + tempcolor, 16), wireframe: true } );
                  const mesh = new THREE.Mesh( geometry, material ) ;
                  mesh.name = 'boxwireframe';
                  // tslint:disable-next-line: max-line-length
                  const material2 = new THREE.MeshBasicMaterial( { color: parseInt('0x' + tempcolor, 16), transparent: true, opacity: 1.0 } );
                  const mesh2 = new THREE.Mesh( geometry, material2 ) ;
                  mesh2.name = 'box';
                  const group = new WolGroup();
                  group.add(mesh);
                  group.add(mesh2);
                  group.name = 'box_' + index;
                  group.classId = item.classId;
                  group.trained = item.trained;
                  this.dvc.boxdetectionmarker.push(group);

                });
                _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
                  this.dvc.scene.add( item );
                });

                this.dvc.loadingUpdate(false);
                result = null;
                return false;
              } else {
                this.dvc.loadingUpdate(false);
                result = null;
                return false;
              }
            });

            this.dvc.loadingUpdate(false);
            _.each(this.dvc.pages[0].layers, (layer, index, list) => {
              layer.texture.needsUpdate = true;
            });
            result = null;
            return true;
          }
        } else {
          result = null;
          this.dvc.loadingUpdate(false);
        }
      });
    } else if (this.dvc.modusSource.getValue() === 'dataset') {
      this.dvc.loadingUpdate(false);
      this.labeltoolsService.clearcanvas();

      // tslint:disable-next-line: no-shadowed-variable
      this.dvc.getDatasetLabels().subscribe(result => {
        this.dvc.undoavailable = false;
        this.dvc.redoavailable = false;

        if (result !== 'nolabel') {
          const classidlist = [];

          _.each(this.dvc.pages[0].layers, (layer, index, list) => {
            this.logger.debug(layer);
            layer.classId = parseInt(layer.classId, 10);
            classidlist.push(_.findIndex(this.dvc.pages[0].layers, {classId: (index + 1)}));
          });
          this.logger.debug(this.dvc.datasettype);
          if (this.dvc.datasettype === 'segmentation') {
            _.times(this.dvc.inputtexture.image.height, (y) => {
              _.times(this.dvc.inputtexture.image.width, (x) => {
                if (result[(this.dvc.inputtexture.image.height - y - 1)][x] > 0) {
                  // tslint:disable-next-line: max-line-length
                  this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_red;
                  // tslint:disable-next-line: max-line-length
                  this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_green;
                  // tslint:disable-next-line: max-line-length
                  this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].color_blue;
                  // tslint:disable-next-line: max-line-length
                  this.dvc.pages[0].layers[classidlist[(result[(this.dvc.inputtexture.image.height - y - 1)][x] - 1)]].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
                }
              });
            });
          } else if (this.dvc.datasettype === 'boxdetection') {
            // tslint:disable-next-line: max-line-length
            const cleanmapimagedata = new Uint8Array(this.dvc.inputtexture.image.height * this.dvc.inputtexture.image.width * 4);

            _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
              this.dvc.scene.remove(item);
            });
            this.dvc.boxdetectionmarker = [];

            _.each(result, (item, index) => {
              result[index].ymin = this.dvc.inputtexture.image.height - result[index].ymin - 1;
              result[index].ymax = this.dvc.inputtexture.image.height - result[index].ymax - 1;

              result[index].xmin = Math.ceil(result[index].xmin - (this.dvc.inputtexture.image.width / 2));
              result[index].xmax = Math.ceil(result[index].xmax - (this.dvc.inputtexture.image.width / 2));
              result[index].ymin = Math.ceil(result[index].ymin - (this.dvc.inputtexture.image.height / 2));
              result[index].ymax = Math.ceil(result[index].ymax - (this.dvc.inputtexture.image.height / 2));

              if (!_.has(item, 'class_id')) {
                result[index].classId = 1;
              } else {
                result[index].classId = item.class_id;
              }
            });

            this.logger.debug('RESULTRESULT');
            this.logger.debug(result);

            _.each(result, (item, index, list) => {
              const boxShape = new THREE.Shape();

              boxShape.moveTo( item.xmin, item.ymin);
              boxShape.lineTo( item.xmax, item.ymin);
              boxShape.lineTo( item.xmax, item.ymax);
              boxShape.lineTo( item.xmin, item.ymax);
              boxShape.lineTo( item.xmin, item.ymin);

              let tempcolor = this.dvc.activelayercolor.substr(1);
              let tempopacity = 1.0;
              let tempvisible = true;

              const geometry = new THREE.ShapeGeometry( boxShape );
              _.each(this.dvc.pages[0].layers, (layer) => {
                if (layer.classId === item.class_id) {
                  // tslint:disable-next-line: max-line-length
                  tempcolor = this.dvc.decimalToHex(layer.color_red) + this.dvc.decimalToHex(layer.color_green) + this.dvc.decimalToHex(layer.color_blue);
                  tempopacity = layer.opacity;
                  if (layer.show === 0) {
                    tempvisible = false;
                  } else {
                    tempvisible = true;
                  }
                }
              });
              // tslint:disable-next-line: max-line-length
              const material = new THREE.MeshBasicMaterial( { color: parseInt('0x' + tempcolor, 16), wireframe: true, transparent: true, opacity: 1.0 } );
              const mesh = new THREE.Mesh( geometry, material ) ;
              mesh.name = 'boxwireframe';
              // tslint:disable-next-line: max-line-length
              // const material2 = new THREE.MeshBasicMaterial( { color: parseInt('0x' + tempcolor, 16), transparent: true, opacity: this.dvc.pages[0].layers[this.dvc.activelayerindex].plane.material.opacity } );
              // tslint:disable-next-line: max-line-length
              const material2 = new THREE.MeshBasicMaterial( { color: parseInt('0x' + tempcolor, 16), transparent: true, opacity: tempopacity } );
              const mesh2 = new THREE.Mesh( geometry, material2 ) ;
              mesh2.name = 'box';

              const group = new WolGroup();
              group.add(mesh);
              group.add(mesh2);
              group.name = 'box_' + index;
              group.classId = item.classId;
              group.visible = tempvisible;
              this.dvc.boxdetectionmarker.push(group);

            });
            _.each(this.dvc.boxdetectionmarker, (item, index, list) => {
              // this.logger.debug(item);
              if (item.visible === true) {
                this.dvc.scene.add( item );
                this.webgl.render('force');
              }
            });

          }

          _.each(this.dvc.pages[0].layers, (layer, index, list) => {
            layer.texture.needsUpdate = true;
          });
          result = null;
          this.dvc.loadingUpdate(false);
        } else {
          result = null;
          this.dvc.loadingUpdate(false);
        }

        return true;
      });
    }
  }

  private recoloraslabel(index) {
    this.logger.debug('recolor');
    if (this.dvc.pages[0].layers[index].label) {
      for (let y = 0; y < this.dvc.inputtexture.image.height; y++) {
        // loop through each column
        for (let x = 0; x < this.dvc.inputtexture.image.width; x++) {
          // tslint:disable-next-line: max-line-length
          if (((this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 0] === this.dvc.pages[0].layers[index].color_red) && (this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] === this.dvc.pages[0].layers[index].color_green) && (this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] === this.dvc.pages[0].layers[index].color_blue)) && (this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] > 0)) {
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = this.dvc.pages[0].layers[index].color_red;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = this.dvc.pages[0].layers[index].color_green;
            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = this.dvc.pages[0].layers[index].color_blue;
            this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 255;
          } else {
            this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4)] = 255;
            this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 1] = 255;
            this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 2] = 255;
            this.dvc.pages[0].layers[index].texture.image.data[(((this.dvc.inputtexture.image.width * y) + x) * 4) + 3] = 0;
          }
        }
      }
      // DataviewerdataService.pages[0].layers[1].texture.needsUpdate = true;
      this.dvc.pages[0].layers[index].texture.needsUpdate = true;
    }
  }

  private initimagefunction = () => {
    return () => {
      const inputimage = new Image();
      inputimage.onload = () => {
        this.logger.debug('inputimage loaded');

        // create canva element which is NOT attached to the DOM
        let canvas = document.createElement('canvas');
        canvas.width = inputimage.width;
        canvas.height = inputimage.height;
        let context = canvas.getContext('2d');
        // bild an der x-achse spiegeln um unterschiedliche Korrdinatesystem in 2dcanvas und webgl zu berücksichtigen
        context.translate(0, canvas.height);
        context.scale(1, -1);

        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(inputimage, 0, 0);

        // tslint:disable-next-line: max-line-length
        this.dvc.inputtexture = new THREE.DataTexture(new Uint8Array(context.getImageData(0, 0, inputimage.width, inputimage.height).data.buffer), inputimage.width, inputimage.height, THREE.RGBAFormat);
        this.dvc.inputtexture.type = THREE.UnsignedByteType;
        this.dvc.inputtexture.minFilter = THREE.NearestFilter;
        this.dvc.inputtexture.magFilter = THREE.NearestFilter;
        this.dvc.inputtexture.needsUpdate = true; // required

        // do something with the texture
        this.dvc.inputwidth = this.dvc.inputtexture.image.width;
        this.dvc.inputheight = this.dvc.inputtexture.image.height;
        this.dvc.dataobject1.width = this.dvc.inputtexture.image.width;
        this.dvc.dataobject1.height = this.dvc.inputtexture.image.height;
        this.dvc.dataobject2.width = this.dvc.inputtexture.image.width;
        this.dvc.dataobject2.height = this.dvc.inputtexture.image.height;
        this.dvc.newpage();
        try {
          this.minimapService.resetView();
        } catch (error) {
          this.logger.debug('could not call minimap resetview');
          this.logger.debug(error);
        }

        // load texture on plane
        this.dvc.inputtexture.minFilter = THREE.NearestFilter;
        this.dvc.inputtexture.magFilter = THREE.NearestFilter;

        // createcanvas for layerdrawing
        if (canvas) {
          canvas.remove();
        }
        canvas = document.createElement('canvas');
        context = canvas.getContext('2d');

        canvas.width = this.dvc.inputtexture.image.width;
        canvas.height = this.dvc.inputtexture.image.height;
        // tslint:disable-next-line: max-line-length
        this.minimapService.setCropBoxPosition(this.mapService.camera, this.mapcanvasContainer, this.minimapcanvasContainer, this.minimapAreaselect);

        // bild an der x-achse spiegeln um unterschiedliche Korrdinatesystem in 2dcanvas und webgl zu berücksichtigen
        context.translate(0, canvas.height);
        context.scale(1, -1);

        if (this.img) {
          this.img.remove();
        }
        this.img = new Image(this.dvc.inputtexture.image.width, this.dvc.inputtexture.image.height);

        const initsinglelayer = (classdata) => {
          if (this.dvc.datasettype !== 'segmentation') {
              context.drawImage(this.img, 0, 0 );
          }

          // tslint:disable-next-line: max-line-length
          this.dvc.overlaytexture = new THREE.DataTexture( new Uint8Array(context.getImageData(0, 0, this.dvc.inputtexture.image.width, this.dvc.inputtexture.image.height).data.buffer), this.dvc.inputtexture.image.width, this.dvc.inputtexture.image.height, THREE.RGBAFormat );

          this.dvc.overlaytexture.type = THREE.UnsignedByteType;
          this.dvc.overlaytexture.minFilter = THREE.NearestFilter;
          this.dvc.overlaytexture.magFilter = THREE.NearestFilter;
          this.dvc.overlaytexture.needsUpdate = true; // required

          // handle old "class_id" instead of "classId"
          let tempClassId = 0;
          if (classdata.classId) {
            tempClassId = classdata.classId;
          } else {
            tempClassId = classdata.class_id;
          }

          const layerdata = {
            name: classdata.name,
            classId: tempClassId,
            label: true,
            color_red: 0,
            color_green: 255,
            color_blue: 0,
            opacity: 1.0,
            show: 1,
            radius: 20,
            linewidth: 10,
            visible: true,
            locked: false,
            selected: false,
            texture: this.dvc.overlaytexture
          };

          if (classdata.opacity >= 0) {
            layerdata.opacity  = classdata.opacity / 100;
          }
          if (classdata.show >= 0) {
            layerdata.show  = parseInt(classdata.show, 10);
          }
          if (classdata.radius >= 0) {
            layerdata.radius  = parseInt(classdata.radius, 10);
          }
          if (classdata.linewidth >= 0) {
            layerdata.linewidth  = parseInt(classdata.linewidth, 10);
          }

          if ((classdata.color_red >= 0) && (classdata.color_green >= 0) && (classdata.color_blue >= 0)) {
            layerdata.color_red  = parseInt(classdata.color_red, 10);
            layerdata.color_green  = parseInt(classdata.color_green, 10);
            layerdata.color_blue  = parseInt(classdata.color_blue, 10);
          } else {
            _.each(this.dvc.configLabelcolors, (labelcolor, index, list) => {
              if (labelcolor.id === layerdata.classId) {
                layerdata.color_red  = parseInt(labelcolor.red, 10);
                layerdata.color_green  = parseInt(labelcolor.green, 10);
                layerdata.color_blue  = parseInt(labelcolor.blue, 10);
              }
            });
          }

          // tslint:disable-next-line: max-line-length
          layerdata.texture.image.data = new Uint8Array(layerdata.texture.image.data.length);

          this.dvc.newlayer(0, layerdata);

          if (this.dvc.modusSource.getValue() === 'analysis') {
            this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].interpretaslabel = false;
          }
          if (this.dvc.modusSource.getValue() === 'trainedalgorithm') {
            let numberLabelclasses = 0;
            this.logger.debug(classdata);
            this.logger.debug(this.dvc.datasetclasses);

            _.each(this.dvc.datasetclasses, (classitem, index, list) => {
              if (classitem.classId){
                if (classitem.classId !== 0) {
                  numberLabelclasses += 1;
                }
              } else {
                if (classitem.class_id !== 0) {
                  numberLabelclasses += 1;
                }
              }
            });

            this.logger.debug(numberLabelclasses);
            this.logger.debug(this.dvc.pages[0].layers);

            if (numberLabelclasses < this.dvc.pages[0].layers.length) {
              this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].trained = false;
              if ((this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_red + 80) <= 255) {
                // tslint:disable-next-line: max-line-length
                this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_red = this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_red + 80;
              } else {
                this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_red = 255;
              }

              if ((this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_green + 80) <= 255) {
                // tslint:disable-next-line: max-line-length
                this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_green = this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_green + 80;
              } else {
                this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_green = 255;
              }

              if ((this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_blue + 80) <= 255) {
                // tslint:disable-next-line: max-line-length
                this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_blue = this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_blue + 80;
              } else {
                this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].color_blue = 255;
              }
            } else {
              this.dvc.pages[0].layers[this.dvc.pages[0].layers.length - 1].trained = true;
            }
          }
        };


        if (this.dvc.modusSource.getValue() === 'dataset') {
          _.each(this.dvc.datasetclasses, (classdata, index, list) => {
            this.logger.debug(classdata);
            if (classdata.classId){
              if (classdata.classId !== 0) {
                initsinglelayer(classdata);
              }
            } else {
              if (classdata.class_id !== 0) {
                initsinglelayer(classdata);
              }
            }
          });
          this.loadlabel();
        } else if (this.dvc.modusSource.getValue() === 'trainedalgorithm') {

          let tempClassdata: any;
          if (typeof this.dvc.datasetclasses === 'string') {
            tempClassdata = JSON.parse(this.dvc.datasetclasses);
          } else {
            tempClassdata = JSON.parse(JSON.stringify(this.dvc.datasetclasses));
          }

          _.each(tempClassdata, (classdata, index, list) => {
            classdata.name = '[trained]' + classdata.name;
            if (classdata.classId){
              if (classdata.classId !== 0) {
                initsinglelayer(classdata);
              }
            } else {
              if (classdata.class_id !== 0) {
                initsinglelayer(classdata);
              }
            }
          });

          let tempClassdata2: any;
          if (typeof this.dvc.datasetclasses === 'string') {
            tempClassdata2 = JSON.parse(this.dvc.datasetclasses);
          } else {
            tempClassdata2 = JSON.parse(JSON.stringify(this.dvc.datasetclasses));
          }

          _.each(tempClassdata2, (classdata, index, list) => {
            classdata.name = '[label]' + classdata.name;
            if (classdata.class_id !== 0) {
              initsinglelayer(classdata);
            }
          });

          this.loadlabel();

        } else if (this.dvc.modusSource.getValue() === 'analysis') {
          initsinglelayer({name: 'Result Image', classId: 1, label: false});

          if (this.analysisjobresult) {
            this.analysisjobresult.remove();
          }

          this.analysisjobresult = new Image(this.dvc.inputtexture.image.width, this.dvc.inputtexture.image.height);
          this.analysisjobresult.crossOrigin = '';

          // tslint:disable-next-line: prefer-const max-line-length
          let analysisinput = {filename: this.dvc.dataobject1.name, width: this.dvc.inputtexture.image.width, height: this.dvc.inputtexture.image.height};
          // tslint:disable-next-line: prefer-const max-line-length
          let analysisoutput = {filename: this.dvc.dataobject2.name, width: this.analysisjobresult.width, height: this.analysisjobresult.height};

          this.analysisjobresult.onload = () => { // image is loaded; sizes are available

            analysisoutput.width = this.analysisjobresult.naturalWidth;
            analysisoutput.height = this.analysisjobresult.naturalHeight;

            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(this.analysisjobresult, 0, 0 );
            this.analysisjobresult.remove();

            // tslint:disable-next-line: max-line-length
            this.dvc.pages[0].layers[0].texture.image.data = new Uint8Array(context.getImageData(0, 0, canvas.width, canvas.height).data.buffer);
            this.dvc.pages[0].layers[0].texture.needsUpdate = true;

            this.dvc.loadingUpdate(false);
            return true;
          };
          let retryCount = 0;
          this.analysisjobresult.onerror = (error) => {
            this.logger.debug(error);
            if (retryCount < 10){
              setTimeout(() => {
                this.analysisjobresult.src = this.dvc.dataobject2.path;
                retryCount += 1;
              });
            } else {
              this.dvc.loadingUpdate(false);
            }
          };
          this.logger.debug(encodeURIComponent(this.dvc.dataobject2.path));
          this.analysisjobresult.src = this.dvc.dataobject2.path;
        } else {
          this.dvc.loadingUpdate(false);
        }

        // set active layer
        // tslint:disable-next-line: max-line-length
        this.dvc.newlayer(0, {name: 'Original Image', label: false, interpretaslabel: false, color_red: 255, color_green: 255, color_blue: 255, opacity: 1.0, show: 1, locked: false, selected: false, texture: this.dvc.inputtexture});
        setTimeout(() => { this.logger.debug('timeout done'); this.webgl.render(); }, 1000);
        this.webgl.render();
      };
      inputimage.onerror = (error) => {
        let retryCount = 0;

        if (retryCount < 10){
          setTimeout(() => {
            inputimage.src = this.dvc.dataobject1.path;
            retryCount += 1;
          });
        }
      };
      inputimage.crossOrigin = '';
      this.logger.debug(this.dvc.dataobject1.path);
      inputimage.src = this.dvc.dataobject1.path;

      const inputimageloader = new THREE.TextureLoader();
      this.logger.debug(this.dvc.dataobject1.path);
      inputimageloader.load(
        // resource URL
        this.dvc.dataobject1.path,
        // Function when resource is loaded
        ( inputtexture ) => { this.logger.debug('inputimageloader-loaded'); },
        // Function called when download progresses
        ( xhr ) => {
          this.logger.debug( (xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        // Function called when download errors
        ( xhr ) => {
          this.logger.debug( 'An error happened inputimageloader: ' + this.dvc.dataobject1.path);
        }
      );
      this.webgl.render();
    };
  }

  public onResized(event: ResizedEvent) {
    this.logger.debug('RESIZE');
    const newHeight = event.newHeight;
    // tslint:disable-next-line: max-line-length
    const newWidth = event.newWidth - this.dataviewerLeftContainer.nativeElement.clientWidth - this.dataviewerRightContainer.nativeElement.clientWidth;
    this.mapService.camera.aspect = newWidth / newHeight;
    this.mapService.camera.updateProjectionMatrix();
    this.mapService.canvasWidth = newWidth;
    this.mapService.canvasHeight = newHeight;
    this.webgl.mapRenderer.setSize(
      newWidth,
      newHeight
    );
    this.webgl.render();
    // tslint:disable-next-line: max-line-length
    this.minimapService.setCropBoxPosition(this.mapService.camera, this.mapcanvasContainer, this.minimapcanvasContainer, this.minimapAreaselect);
    this.logger.debug(this.mapService.camera);
  }

  constructor(
    public utilities: UtilitiesService,
    public account: AccountService,
    public dvc: DataviewerControllerService,
    private webgl: WebglService,
    private ngRenderer: Renderer2,
    public mapService: MapService,
    public minimapService: MinimapService,
    public labeltoolsService: LabeltoolsService,
    private modalService: NgbModal,
    private toastService: ToastmessageStoreService,
    private logger: NGXLogger
    ) { }

  ngAfterViewInit(): void {
    this.logger.debug('VIEWINIT!');
    this.dvc.visible.subscribe({next: visible => {
      this.logger.debug('dataviewer visible: ' + visible);

      if (!visible) {
        this.logger.debug('closed');
        this.cleanup();
        this.dvc.loadingUpdate(false);
      } else {
        try {
          this.cleanup();
        } catch (error) {
          this.logger.debug(error);
        }
        this.dvc.loadingUpdate(true);
        setTimeout( () => {
          this.minimapcanvasContainer = this.sidebarRight.minimapcanvasContainer;
          this.minimapAreaselect = this.sidebarRight.minimapareaselect;
          this.initdone = true;

          if (this.dvc.modusSource.getValue() === 'trainedalgorithm') {
            // define resultlabel path using original datapath
            if (this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.png'))) {
              // tslint:disable-next-line: max-line-length
              this.dvc.resultlabelpath = this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.png')) + '_testlabel.png' + '?_t=' + this.account.account.authtoken;
              // tslint:disable-next-line: max-line-length
              this.dvc.originallabelpath = this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.png')) + '_userlabel.json';
            } else if (this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.jpg'))) {
              // tslint:disable-next-line: max-line-length
              this.dvc.resultlabelpath = this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.jpg')) + '_testlabel.jpg' + '?_t=' + this.account.account.authtoken;
              // tslint:disable-next-line: max-line-length
              this.dvc.originallabelpath = this.dvc.dataobject1.path.substr(0, this.dvc.dataobject1.path.lastIndexOf('_original.jpg')) + '_userlabel.json';
            }

          }

          this.logger.debug('open');
          this.mapService.init(this.mapcanvasContainer, this.minimapcanvasContainer, this.ngRenderer);
          this.minimapService.init(this.mapcanvasContainer, this.minimapcanvasContainer, this.minimapAreaselect, this.ngRenderer);
          this.webgl.init(this.mapcanvasContainer, this.minimapcanvasContainer, this.ngRenderer);

          this.initimage = this.initimagefunction();
          this.initimage();
          this.minimapService.resetView();
          this.mapcanvasContainer.nativeElement.focus(); // necessary to activate keydown detection
        });
      }
    }}
    );

    this.zoomSubscription = this.dvc.zoom.subscribe({next: zoom => {
      this.zoomTemp = zoom;
      if (this.initdone) {
        this.logger.debug('setZoom');
        this.mapService.setZoom(zoom, this.mapcanvasContainer);
        // tslint:disable-next-line: max-line-length
        this.minimapService.setCropBoxPosition(this.mapService.camera, this.mapcanvasContainer, this.minimapcanvasContainer, this.minimapAreaselect);
        this.minimapService.canvasHeight = this.minimapcanvasContainer.nativeElement.clientHeight;
        this.minimapService.canvasWidth = this.minimapcanvasContainer.nativeElement.clientWidth;
        this.webgl.render();
      }
    }}
    );

    this.dvc.triggerLoadlabel.subscribe({next: loadlabel => {
      if (loadlabel === true) {
        this.logger.debug('loadlabel');
        this.loadlabel();
      }
    }}
    );
  }

}
