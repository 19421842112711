import { AccounttypeStoreService } from './../../shared/services/accounttype-store.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AccountService } from 'src/app/shared/services/account.service';

@Component({
  selector: 'wol-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  model = {
    left: true,
    middle: false,
    right: false
  };

  constructor(
    private logger: NGXLogger,
    private as: AuthService,
    public accountService: AccountService,
    public accounttypeStoreService: AccounttypeStoreService
  ) { }

  ngOnInit() {
  }

}
